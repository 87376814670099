import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Competencia from './index'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Evaluación de Competencias Básicas',
        path: '/competencia',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CompetenciaPage = () => (
    <Routes>



        <Route
            path=':solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Evaluación de Competencias Básicas</PageTitle>
                    <Competencia />
                </>
            }
        />

        <Route index element={<Navigate to='/competencia/:solicitudId' />} />

    </Routes>
)

export default CompetenciaPage
