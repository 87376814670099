import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IUser } from './model';
import Swal from 'sweetalert2';
import UserListBody from './UserListBody';
import UserListSearch from './UserListSearch';
import Pagination from 'react-bootstrap/Pagination';
import { deleteUser, fetchUsers } from './api';
import { useNavigate } from 'react-router-dom';

const UserList: React.FC = () => {
  let navigate = useNavigate();
  const intl = useIntl();
  const { userId } = useParams(); 
  const [data, setData] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [resetting, setResetting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // Variable para almacenar el temporizador de búsqueda
  let searchTimer: NodeJS.Timeout;

  const fetchData = async (page: number, limit: number, searchValue: string) => {
    setIsLoading(true);
    setError('');
    setIsSearching(true);

    try {
      const { users, totalPages } = await fetchUsers(page, limit, searchValue);
      setData(users);
      setTotalPages(totalPages);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, limit, searchValue);
  }, [currentPage, limit]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(parseInt(event.target.value));
    setCurrentPage(1);
  };

  const handleDelete = async (userId: number) => {
    try {
      Swal.fire({
        title: '¿Está seguro de que desea eliminar este usuario?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteUser(Number(userId));
          Swal.fire({
            title: 'Eliminado con éxito',
            icon: 'success',
          });
          fetchData(currentPage, limit, searchValue);
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al eliminar el usuario.',
        icon: 'error',
      });
    }
  };

  const handleEdit = (userId: number) => {
    navigate(`/users/edit/${userId}`);
  };

  const handleSearch = () => {
    // Realizar la búsqueda solo si hay un valor de búsqueda
    if (searchValue.trim() !== '') {
      // Si ya hay un temporizador en curso, lo limpiamos
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
  
      // Creamos un nuevo temporizador para la búsqueda
      searchTimer = setTimeout(() => {
        fetchData(currentPage, limit, searchValue);
      }, 10); // Esperamos 500 milisegundos antes de realizar la búsqueda
    }
  };
  
  const handleReset = () => {
    setResetting(true);
    setSearchValue('');

  };

  useEffect(() => {
    if (resetting) {
      fetchData(currentPage, limit, '');
      setResetting(false);
    }
  }, [resetting]);

  const memoizedData = useMemo(() => data, [data]);

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title m-0">Agregar usuario</h3>
        <div>
          <Link to={'/users/create'} className='btn btn-primary add-button rounded-pill'>
            Agregar usuario
          </Link>
        </div>
      </div>
      <div className='card-body py-3'>
        <UserListSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <div className='table-responsive mb-3'>
          <table className='table table-striped' id='table'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th className='min-w-50px'>ID</th>
                <th className='min-w-100px'>Nombre</th>
                <th className='min-w-200px'>Email</th>
                <th className='min-w-150px'>Rol</th>
                <th>Acción</th>
              </tr>
            </thead>
            <UserListBody
              isLoading={isLoading || isSearching}
              data={memoizedData}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          </table>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
          <div>
            <select value={limit} onChange={handleLimitChange} className='form-select form-select-sm'>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
