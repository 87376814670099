export enum Calificacion {
  Aprobado = "Aprobado",
  NoAprobado = "No aprobado",
  EnProceso = "En proceso",
  SinResultado = "Sin resultado",
}

export interface CompetenciasBasicas {
  fecha_de_evaluacion?: Date; // Obligatorio, anterior o igual a hoy
  calificacion: Calificacion; // Obligatorio
  resultado: string; // Obligatorio
  calificacion_obtenida?: number; // Condicionalmente obligatorio
  fecha_de_reevaluacion?: Date; // Opcional
  observaciones?: string; // Opcional
  id_instancia_responsable?: number; // Opcional
  id_instancia_evaluadora: number; // Obligatorio
}

export const competenciasBasicasInitial: CompetenciasBasicas = {
  calificacion: Calificacion.NoAprobado, // Assign a valid value from the Calificacion enum
  resultado: '', // Vacío inicialmente, esperando entrada
  id_instancia_evaluadora: 0, // Asumiendo un valor inicial, ajusta según sea necesario
}
