import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import { SancionModel } from "./model";
import { getSancion } from "./api";
import { useAuth } from "../auth";
import { useParams } from "react-router-dom";
import CreateSancion from "./create";
import EditSancion from "./edit";
import DestroySancion from "./destroy";
/*import CreateEstimulo from "./create";
import EditEstimulo from "./edit";
import DestroyEstimulo from "./destroy";
/*import CreateFormacionContinua from "./create";
import EditFormacionContinua from "./edit";
import DestroyFormacionContinua from "./destroy";*/

const Sanciones: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedSancion, setSelectedSancion] = useState<SancionModel | null>(null);
    const [sanciones, setData] = useState<SancionModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {currentUser} = useAuth();
    const { solicitudId } = useParams<{ solicitudId: string }>(); // Obtenemos el solicitudId de los parámetros


    //Para mandar llamar el listado de sanciones
    useEffect(() => {
        const fetchSanciones = async () => {
          setIsLoading(true);
          setError('');
          try {
            const  {sanciones}  = await getSancion(solicitudId);
            setData(sanciones);
            setIsLoading(false);
          } catch (error) {
            if (error instanceof Error) {
              setError(error.message); 
            } else {
              setError('Ocurrio un error inesperado'); 
            }
            setIsLoading(false);
          }
        };
    
        fetchSanciones();
        
      },[]);
      
    //Para poder cambiar el estatus en memoria de los cambios
  const crearSancion = (nuevaSancion: SancionModel) => {
    setData(sanciones => [...sanciones, nuevaSancion]);
  };
  
 

  const actualizarSancion = (nuevaSancion: SancionModel) => {
    setData(sanciones => {
        const sancionExistenteIndex = sanciones.findIndex(sancion => sancion.id === nuevaSancion.id);

        if (sancionExistenteIndex !== -1) {
            const nuevasSanciones = [...sanciones];
            nuevasSanciones[sancionExistenteIndex] = nuevaSancion;
            return nuevasSanciones;
        } else {
            return [...sanciones, nuevaSancion];
        }
    });
};
const eliminarSancion = (sancionEliminar: SancionModel) => {
  setData(sanciones => {
      const nuevasSanciones = sanciones.filter(sancion => sancion.id !== sancionEliminar.id);
      return nuevasSanciones;
  });
};
  if (isLoading) {
    return <div>Por favor espere</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }


    const handleShowCreateModal = () => {
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleShowEditModal = (sancion:SancionModel) => {
        setSelectedSancion(sancion);
        setShowEditModal(true);
    };
    const handleShowDestroyModal = (sancion:SancionModel) => {
        setSelectedSancion(sancion);
        setShowDestroyModal(true);
    };
    const handleCloseModal = () => {
        setShowEditModal(false);
        setShowDestroyModal(false);
      };

    return (
        
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title m-0">Sanciones</h3>
                    <div>
                        <button className="btn btn-info" onClick={() => handleShowCreateModal()}>Nuevo <BsPlusCircle /></button>
                            <CreateSancion solicitud={solicitudId} show={showCreateModal} handleClose={handleCloseCreateModal} data={sanciones} agregarNuevaSancion={crearSancion}  />
                    </div>
            </div>
            <div className="card-body">
                <div className="row">
                {sanciones.map((sancion) => (
                    <div className="col-md-4 mt-2">
                    <div className="card shadow-lg">
                        <div className="card-header bg-primary text-white align-items-center">
                        {sancion.tipo_sancion.nombre} 
                        </div>
                        <div className="card-body align-items-center">
                            <p className="text-info text-center">{sancion.instancia_sancion.nombre}</p>
                            <p className="text-danger text-center">Causa: {sancion.causa}</p>
                            <p className="text-bold text-center">Duración: {sancion.duracion}  {sancion.cantidad_duracion} </p>
                            <p className="text-bold text-center">Fecha de determinación: {sancion.fecha_determinacion} </p>
                            <p className="text-bold text-center">Periodo: {sancion.fecha_inicio} - {sancion.fecha_fin} </p>
                            <p className="text-bold text-center">Recurso en Revisión: {sancion.recurso_revision == 0 ? 'No' : 'Si'}</p>
                   

                            <button className="btn btn-warning m-2" onClick={() => handleShowEditModal(sancion)}><BsPencil/></button>
                          <button className="btn btn-danger m-2" onClick={() => handleShowDestroyModal(sancion)}><BsTrash /></button>
                
                        </div>
                    </div>
                    </div>
                    ))}
                     <EditSancion sancion={selectedSancion} show={showEditModal} handleClose={handleCloseModal} modificarSancion={actualizarSancion} solicitud={solicitudId}/>
                    
                    <DestroySancion sancion={selectedSancion} show={showDestroyModal} handleClose={handleCloseModal} eliminarSancion={eliminarSancion}/>
                    
                </div>
                
            </div>
        </div>
    
            
      );
};

export default Sanciones;
    