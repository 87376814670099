import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Sanciones from './index'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Sanciones',
        path: '/sanciones',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const SancionesPage = () => (
    <Routes>



        <Route
            path=':solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Sanciones</PageTitle>
                    <Sanciones />
                </>
            }
        />

        <Route index element={<Navigate to='/sanciones/:solicitudId' />} />

    </Routes>
)

export default SancionesPage
