import axios from 'axios';

export const getInstanciasEvaluadoras = async (currentUser: string) => {
    try {
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/instanciasEvaluadoras?usuario=${currentUser}`;
        const response = await axios.get(queryString);
        return response.data; // Devuelve el array de instancias
    } catch (error) {
        console.error("Error al cargar las instancias:", error);
        throw error;
    }
}

export const getInstanciasCapacitadoras = async (currentUser: string) => {
    try {
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/instanciasCapacitadoras?usuario=${currentUser}`;
        const response = await axios.get(queryString);
        return response.data; // Devuelve el array de instancias
    } catch (error) {
        console.error("Error al cargar las instancias:", error);
        throw error;
    }
}

export const getInstanciasResponsables = async (currentUser: string) => {
    try {
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/instanciasResponsables?usuario=${currentUser}`;
        const response = await axios.get(queryString);
        return response.data; // Devuelve el array de instancias
    } catch (error) {
        console.error("Error al cargar las instancias:", error);
        throw error;
    }
}

export const createControlConfianza = async (formData: FormData): Promise<any> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DEV_URL}/evaluacion-confianzas`, formData);
        return response.data;
    } catch (error) {
        console.error("Error al crear control de confianza:", error);
        throw error;
    }
}

export const updateControlConfianza = async (formData: FormData, id: number): Promise<any> => {
    try {
        let object: { [key: string]: any } = {};
        formData.forEach((value, key) => {
            if (!Object.prototype.hasOwnProperty.call(object, key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        
        const jsonPayload = JSON.stringify(object);

        const response = await axios.put(`${process.env.REACT_APP_API_DEV_URL}/evaluacion-confianzas/${id}`, jsonPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error al actualizar control de confianza:", error);
        throw error;
    }
};

export const createFormacionInicial = async (formData: FormData): Promise<any> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DEV_URL}/formaciones-iniciales`, formData);
        return response.data;
    } catch (error) {
        console.error("Error al crear formación inicial:", error);
        throw error;
    }
}

export const updateFormacionInicial = async (formData: FormData, id: number): Promise<any> => {
    try {
        let object: { [key: string]: any } = {};
        formData.forEach((value, key) => {
            if (!Object.prototype.hasOwnProperty.call(object, key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        
        const jsonPayload = JSON.stringify(object);

        const response = await axios.put(`${process.env.REACT_APP_API_DEV_URL}/formaciones-iniciales/${id}`, jsonPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error al actualizar formación inicial:", error);
        throw error;
    }
};

export const createEvaluacionDesempeno = async (formData: FormData): Promise<any> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DEV_URL}/evaluacion-desempenos`, formData);
        return response.data;
    } catch (error) {
        console.error("Error al crear evaluación de desempeño:", error);
        throw error;
    }
}

//updateEvaluacionDesempeno
export const updateEvaluacionDesempeno = async (formData: FormData, id: number): Promise<any> => {
    try {
        let object: { [key: string]: any } = {};
        formData.forEach((value, key) => {
            if (!Object.prototype.hasOwnProperty.call(object, key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        
        const jsonPayload = JSON.stringify(object);

        const response = await axios.put(`${process.env.REACT_APP_API_DEV_URL}/evaluacion-desempenos/${id}`, jsonPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error al actualizar evaluación de desempeño:", error);
        throw error;
    }
};

//createCompetenciasBasicas
export const createCompetenciasBasicas = async (formData: FormData): Promise<any> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DEV_URL}/competencias-basicas`, formData);
        return response.data;
    } catch (error) {
        console.error("Error al crear competencias básicas:", error);
        throw error;
    }
}

//updateCompetenciasBasicas
export const updateCompetenciasBasicas = async (formData: FormData, id: number): Promise<any> => {
    try {
        let object: { [key: string]: any } = {};
        formData.forEach((value, key) => {
            if (!Object.prototype.hasOwnProperty.call(object, key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        
        const jsonPayload = JSON.stringify(object);

        const response = await axios.put(`${process.env.REACT_APP_API_DEV_URL}/competencias-basicas/${id}`, jsonPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error al actualizar competencias básicas:", error);
        throw error;
    }
};

//createNombramiento
export const createNombramiento = async (formData: FormData): Promise<any> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DEV_URL}/nombramientos`, formData);
        return response.data;
    } catch (error) {
        console.error("Error al crear nombramiento:", error);
        throw error;
    }
}

//updateNombramiento
export const updateNombramiento = async (formData: FormData, id: number): Promise<any> => {
    try {
        let object: { [key: string]: any } = {};
        formData.forEach((value, key) => {
            if (!Object.prototype.hasOwnProperty.call(object, key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        
        const jsonPayload = JSON.stringify(object);

        const response = await axios.put(`${process.env.REACT_APP_API_DEV_URL}/nombramientos/${id}`, jsonPayload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error("Error al actualizar nombramiento:", error);
        throw error;
    }
};

//get getMunicipios
export const getMunicipios = async (id_user: number) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_DEV_URL}/municipios/${id_user}`);
        return response.data;
    } catch (error) {
        console.error("Error al cargar los municipios:", error);
        throw error;
    }
}