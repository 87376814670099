import axios, { AxiosResponse } from "axios";
import { Instancia, InstanciaFormValues } from "./model";

export const getInstancias = async (): Promise<{ instancia: Instancia[] }> => {
    try {
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/instancias`;
        const response = await axios.get(queryString);
        const instancia = response.data;
        return { instancia };
    } catch (error) {
        throw error;
    }
}

export const createInstancia = async (formData: FormData): Promise<Instancia> => {
    try {
        const response: AxiosResponse<{ message: string; instancia: Instancia }> = await axios.post(
            `${process.env.REACT_APP_API_DEV_URL}/instancias`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        if (response.status !== 201) {
            throw new Error('Error creando instancia');
        }
        return response.data.instancia;
    } catch (error) {
        throw error;
    }
};

export const updateInstancia = async (instanciaId: number, formData: FormData): Promise<Instancia> => {
    try {
        formData.append("_method", "PUT");
        const response: AxiosResponse<{ message: string; instancia: Instancia }> = await axios.post(
            `${process.env.REACT_APP_API_DEV_URL}/instancias/${instanciaId}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        if (response.status !== 200) {
            throw new Error('Error actualizando instancia');
        }
        return response.data.instancia;
    } catch (error) {
        throw error;
    }
};

export const deleteInstancia = async (instanciaId: number): Promise<Instancia> => {
    try {
        const response: AxiosResponse<{ message: string; instancia: Instancia }> = await axios.delete(
            `${process.env.REACT_APP_API_DEV_URL}/instancias/${instanciaId}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
        if (response.status !== 204) {
            throw new Error('Error eliminando instancia');
        }
        return response.data.instancia; 
    } catch (error) {
        throw error;
    }
};
