import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Instancia, InstanciaFormValues } from './model';
import { createInstancia } from './apiInstancias';
import Swal from 'sweetalert2';
import { useAuth } from '../auth';

interface CreateInstanciasModalProps {
  show: boolean;
  handleClose: () => void;
  data?: Instancia[];
  agregarNuevaInstancia: (instancia: Instancia) => void;
}

const tipoOpciones = [
    { value: 'Instancia Evaluadora', label: 'Instancia Evaluadora' },
    { value: 'Instancia Capacitadora', label: 'Instancia Capacitadora' },
    { value: 'Instancia Sancionadora', label: 'Instancia Sancionadora' },
    { value: 'Instancia Responsable', label: 'Instancia Responsable' },
  ];

const CreateInstancia: React.FC<CreateInstanciasModalProps> = ({ show, handleClose, data, agregarNuevaInstancia }) => {  
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const instanciaSchema = Yup.object().shape({
    nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
    tipo: Yup.string().required('El tipo es requerido'), // Ver donde esta la validacion validación adecuada para el tipo
  });

  const formik = useFormik<InstanciaFormValues>({
    initialValues: {
      nombre: '',
      tipo: '',
    },
    validationSchema: instanciaSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        formData.append('tipo', values.tipo);
        if(currentUser){
            formData.append('user_id', currentUser.id.toString());
        }
        const nuevaInstancia = await createInstancia(formData);

        Swal.fire({
          title: 'Guardado',
          text: 'Instancia creada exitosamente',
          icon: 'success',
        });

        agregarNuevaInstancia(nuevaInstancia);
        handleClose();
        navigate('/catalogos/index');
      } catch (error) {
        console.error('Error creando una instancia:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creando una instancia',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className='bg-primary'>
        <Modal.Title className='text-white'>Nueva Instancia</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="mb-3 col-md-12 mt-3">
              <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
              <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
              {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
            </div>
            <div className="mb-3 col-md-12">
              <label htmlFor="tipo" className="form-label">Tipo</label>
                    <select
                        className="form-control"
                        id="tipo"
                        {...formik.getFieldProps('tipo')}>
                    <option value="">Selecciona un tipo</option>
                        {tipoOpciones.map((opcion) => (
                            <option key={opcion.value} value={opcion.value}>
                                {opcion.label}
                            </option>
                        ))}
                    </select>
              {formik.touched.tipo && formik.errors.tipo && <div className="text-danger">{formik.errors.tipo}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <button type="submit" className="btn btn-success ml-3" disabled={loading}>
            {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateInstancia;
