import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import { useState } from "react";
import Swal from "sweetalert2";
import { createExamen, updateExamen } from "../api";
interface ExamenToxicologicoProps {
  solicitudId: number | null;
}
const ExamenToxicologico: React.FC<ExamenToxicologicoProps> = ({ solicitudId}) => {

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
  const [examenValue, setExamen] = useState<number | undefined>(undefined);
  const [updating, setUpdating] = useState('d-none');
  const formik = useFormikContext();
  const handleCreateExamenToxicologico = async () => {
      const { values } = formik; // Obtener los valores actuales del formulario
      const errors = await formik.validateForm(); // Validar el formulario
      if (Object.keys(errors).length === 0) {
        // No hay errores de validación, ejecutar la acción
      setLoading(true);
        console.log('Valores actuales del formulario:', values);
        // Asegurar que values sea de tipo FormikValues
        const formValues = values as FormikValues;
  
        // Crear un nuevo objeto FormData
        const formData = new FormData();
        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitudId+'');
  
        try {
          const examenToxicologico = await createExamen(formData);
          Swal.fire({
              title: 'Guardado',
              text: 'Examen toxicologico registrado exitosamente',
              icon: 'success',
          });
          setSaving('d-none');
          setUpdating('card-footer d-flex justify-content-end py-6 px-9');
          if(examenToxicologico){
            setExamen(examenToxicologico.id);
          }
          console.log(examenToxicologico);
          
      } catch (error) {
          console.error('Error al crear examen toxicologico:', error);
          Swal.fire({
              title: 'Error',
              text: 'Error al crear examen toxicologico',
              icon: 'error',
          });
      } finally {
          setLoading(false);
      }
      } else {
        // Mostrar los errores de validación
        Swal.fire({
          html: `
          <div class="spinner-container" style="overflow: hidden;">
            <i class="fas fa-spinner fa-spin"></i>
            <span>Se encontraron diversos errores</span> <ol>
            ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
            </ol></div>
          `,
          showConfirmButton: true,
          allowOutsideClick: true,
          
  
        });
      }
      //setLoading(false);
    };
  
    const handleUpdateExamenToxicologico = async () => {
      const { values } = formik; // Obtener los valores actuales del formulario
      const errors = await formik.validateForm(); // Validar el formulario
      if (Object.keys(errors).length === 0) {
        // No hay errores de validación, ejecutar la acción
        setLoading(true);
        const formValues = values as FormikValues;
  
        // Crear un nuevo objeto FormData
        const formData = new FormData();
        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitudId+'');
  
        try {
          const examenToxicologico = await updateExamen(examenValue!,formData);
          Swal.fire({
              title: 'Actualizado',
              text: 'Examen toxicologico actualizada exitosamente',
              icon: 'success',
          });
          setSaving('d-none');
          setUpdating('card-footer d-flex justify-content-end py-6 px-9');
          if(examenToxicologico){
            setExamen(examenToxicologico.id);
          }
          console.log(examenToxicologico);
          
      } catch (error) {
          console.error('Error al actualizar examen toxicologico:', error);
          Swal.fire({
              title: 'Error',
              text: 'Error al actualizar examen toxicologico',
              icon: 'error',
          });
      } finally {
          setLoading(false);
      }
      } else {
        // Mostrar los errores de validación
        Swal.fire({
          html: `
          <div class="spinner-container" style="overflow: hidden;">
            <i class="fas fa-spinner fa-spin"></i>
            <span>Se encontraron diversos errores</span> <ol>
            ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
            </ol></div>
          `,
          showConfirmButton: true,
          allowOutsideClick: true,
          
        });
      }
      //setLoading(false);
    };
  
    return (
        <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Examen Toxicológico</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
            <div className='row mb-6'>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de examen</label>
                  <Field
                    name='fecha_examen'
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_examen' />
                    </div>
                </div>
                <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Sustancias</label>
                  <Field
                    name='sustancias'
                    type='text'
                    as='textarea'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='sustancias' />
                    </div>
                </div>
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Sintesis</label>
                  <Field
                    name='sintesis'
                    type='text'
                    as='textarea'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='sintesis' />
                    </div>
                </div>

              
                <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Resultado</label>
                  <Field
                    as='select'
                    name='resultado'
                    className='form-control form-control-lg form-control-solid'
                    > 
                    <option value="">Seleccione una opción</option>
                    <option value="Recomendable">Recomendable</option>
                    <option value="Recomendable con reservas">Recomendable con reservas</option>
                    <option value="No recomendable">No recomendable</option>

                    </Field>
                  
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='resultado' />
                    </div>
                </div>
                <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de emisión de resultado</label>
                  <Field
                    name='fecha_emision_resultado'
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_emision_resultado' />
                    </div>
                </div>
                
                <div className='col-lg-12 d-none'>
              <label className='form-label required fw-bold fs-6'>Examen Toxicologico</label>
                  <Field
                    name='id'
                    type='text'
                    value={examenValue}
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='id' />
                    </div>
                </div>



             
             
              </div>   
        </div>
            
        <div className={saving}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleCreateExamenToxicologico}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className={updating}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleUpdateExamenToxicologico}>
              {!loading && 'Actualizar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>
    );
}

export {ExamenToxicologico}
