import React, {FC,useState} from 'react'
import {Field, ErrorMessage, useFormikContext} from 'formik'
import Swal, { SweetAlertOptions } from 'sweetalert2'



const Step1: React.FC = () => {
const [loading, setLoading] = useState(false)
const formik = useFormikContext();


const [swalProps, setSwalProps] = useState({});

  

const handleStep1 = async () => {
    const { values } = formik; // Obtener los valores actuales del formulario
    const errors = await formik.validateForm(); // Validar el formulario
    if (Object.keys(errors).length === 0) {
      // No hay errores de validación, ejecutar la acción
    setLoading(true);
      console.log('Valores actuales del formulario:', values);
      // Enviamos ahora si los que ocupamos enviar
    } else {
      // Mostrar los errores de validación
      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Se encontraron diversos errores</span>
          ${Object.values(errors)[0]}
          ${Object.values(errors)[1]}
          ${Object.values(errors)[2]}
          ${Object.values(errors)[3]}
        </div>
        `,
        showConfirmButton: true,
        allowOutsideClick: true,
        

      });
    }
    //setLoading(false);
  };

  interface CustomSweetAlertOptions{
    onOpen?: () => void
  }
  
  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Puesta a disposición</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
            <div className='row mb-6'>

              <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de la puesta a disposición</label>
                  <Field
                    name='date'
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='date' />
                    </div>
                </div>

              <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Hora de la puesta a disposición</label>

                  <Field
                    name='time'
                    type='time'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='time' />
                    </div>
                </div>

              <div className='col-lg-6'>
            <label className='form-label required fw-bold fs-6'>Ingrese el número de detenidos</label>
                  <Field
                    name='arrests'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    min='0'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='arrests' />
                    </div>
            </div>

              <div className='col-lg-6'>
            <label className='form-label required fw-bold fs-6'>Ingrese el número de vehiculos</label>
                  <Field
                    name='vehicles'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    min='0'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='vehicles' />
                    </div>
            </div>
              </div>   
        </div>
            
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>

    
  )
}

export {Step1}