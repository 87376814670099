import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Instancia } from './model';
import Swal from 'sweetalert2';
import { deleteInstancia } from './apiInstancias';

interface DestroyInstanciasModalProps {
    instancia: Instancia | null; 
    show: boolean;
    handleClose: () => void;
    eliminarInstancia: (instancia: Instancia) => void; 
}

const DestroyInstancia: React.FC<DestroyInstanciasModalProps> = ({ show, handleClose, instancia, eliminarInstancia }) => { 
  const [loading, setLoading] = useState(false);

  const handleDelete = async (instanciaId: number) => {
    setLoading(true);

    try {
        await deleteInstancia(instanciaId); 
        Swal.fire({
            title: 'Eliminado',
            text: 'Instancia eliminada exitosamente',
            icon: 'warning',
        });
        
        eliminarInstancia(instancia!); 
        handleClose();

    } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error eliminando la instancia',
          icon: 'error',
        });
    } finally {
        setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton className='bg-danger'>
        <Modal.Title className='text-white'>Eliminar Instancia {instancia?.nombre}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Estás seguro de eliminar la instancia?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <button type="button" disabled={loading} className='btn btn-danger' onClick={() => handleDelete(instancia!.id)}>Eliminar</button>
      </Modal.Footer>
    </Modal>
  );
};

export default DestroyInstancia;
