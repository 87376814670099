export enum TipoEvaluacion {
  Desempeno = 'Evaluación del desempeño',
  Academico = 'Evaluación del desempeño académico',
}

export enum ResultadoEvaluacion {
  Aprobado = 'Aprobado',
  NoAprobado = 'No aprobado',
  EnProceso = 'En proceso',
  SinResultado = 'Sin resultado',
}

export interface EvaluacionDesempeno {
  id: number;
  tipo?: TipoEvaluacion;
  fechaEvaluacion?: Date;
  resultado?: ResultadoEvaluacion;
  calificacionObtenida?: number; 
  vigencia?: Date;
  observaciones?: string;
  idSolicitud: number; 
  idInstanciaEvaluadora: number; 
  createdAt?: Date; 
  updatedAt?: Date; 
}

export {};
