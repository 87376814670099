import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import NominaList from './SolicitudesList'
import {NominaEdit} from './SolicitudesEdit'

const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Listado Nominal',
        path: '/nomina',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ActivosPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Listado Nominal</PageTitle>
                    <NominaList />
                </>
            }
        />

        <Route
            path='edit/:solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Editar</PageTitle>
                    <NominaEdit />
                </>
            }
        />

        <Route index element={<Navigate to='/nomina/index' />} />

    </Routes>
)

export default ActivosPage