import React from 'react';

interface SolicitudesListSearchProps {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
  handleReset: () => void;
  edad: string;
  setEdad: React.Dispatch<React.SetStateAction<string>>;
  servicioMilitar: string;
  setServicioMilitar: React.Dispatch<React.SetStateAction<string>>;
  escolaridad: string;
  setEscolaridad: React.Dispatch<React.SetStateAction<string>>;
  licencia: string;
  setLicencia: React.Dispatch<React.SetStateAction<string>>;
  sexo: string;
  setSexo: React.Dispatch<React.SetStateAction<string>>;
  estadoCivil: string;
  setEstadoCivil: React.Dispatch<React.SetStateAction<string>>;
}

const SolicitudesListSearch: React.FC<SolicitudesListSearchProps> = ({
  searchValue,
  setSearchValue,
  handleSearch,
  handleReset,

  edad,
  setEdad,
  servicioMilitar,
  setServicioMilitar,
  escolaridad,
  setEscolaridad,
  licencia,
  setLicencia,
  sexo,
  setSexo,
  estadoCivil,
  setEstadoCivil,
}) => {
  return (
    <>
      <div className="row mb-4">
        {/* Búsqueda por nombre, primer apellido o segundo apellido */}
        <div className="col-lg-4 mb-lg-0 mb-6">
          <label className="form-label">Buscar:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre, primer apellido o segundo apellido"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        {/* Filtro por Edad */}
        <div className="col-lg-2 mb-lg-0 mb-6">
          <label className="form-label">Edad:</label>
          <input
            type="number"
            className="form-control"
            placeholder="Edad"
            value={edad}
            onChange={(e) => setEdad(e.target.value)}
          />
        </div>
        {/* Filtro por Servicio Militar */}
        <div className="col-lg-2 mb-lg-0 mb-6">
          <label className="form-label">Servicio Militar:</label>
          <select
            className="form-select"
            value={servicioMilitar}
            onChange={(e) => setServicioMilitar(e.target.value)}
          >
            <option value="">Selecciona</option>
            <option value="si">Sí</option>
            <option value="no">No</option>
          </select>
        </div>
        {/* Filtro por Escolaridad */}
        <div className="col-lg-4 mb-lg-0 mb-6">
          <label className="form-label">Escolaridad:</label>
          <select
            className="form-select"
            value={escolaridad}
            onChange={(e) => setEscolaridad(e.target.value)}
          >
            <option value="">Selecciona</option>
            <option value="1">Sin escolaridad</option>
            <option value="2">Primaria completa</option>
            <option value="3">Primaria incompleta</option>
            <option value="4">Secundaria completa</option>
            <option value="5">Secundaria incompleta</option>
            <option value="6">Bachillerato completo</option>
            <option value="7">Bachillerato incompleto</option>
            <option value="8">Carrera técnica completa</option>
            <option value="9">Carrera técnica incompleta</option>
            <option value="10">Licenciatura completa</option>
            <option value="11">Licenciatura incompleta</option>
            <option value="12">Maestría completa</option>
            <option value="13">Maestría incompleta</option>
            <option value="14">Doctorado completo</option>
            <option value="15">Doctorado incompleto</option>
          </select>
        </div>
        {/* Filtro por Licencia de Conducir */}
        <div className="col-lg-2 mb-lg-0 mb-6">
          <label className="form-label">Licencia de Conducir:</label>
          <select
            className="form-select"
            value={licencia}
            onChange={(e) => setLicencia(e.target.value)}
          >
            <option value="">Selecciona</option>
            <option value="1">Sí</option>
            <option value="0">No</option>
          </select>
        </div>
        {/* Filtro por Sexo */}
        <div className="col-lg-3 mb-lg-0 mb-6">
          <label className="form-label">Sexo:</label>
          <select
            className="form-select"
            value={sexo}
            onChange={(e) => setSexo(e.target.value)}
          >
            <option value="">Selecciona</option>
            <option value="masculino">Masculino</option>
            <option value="femenino">Femenino</option>
          </select>
        </div>
        {/* Filtro por Estado Civil */}
        <div className="col-lg-3">
          <label className="form-label">Estado Civil:</label>
          <select
            className="form-select"
            value={estadoCivil}
            onChange={(e) => setEstadoCivil(e.target.value)}
          >
            <option value="">Selecciona</option>
            <option value="Soltero/a">Soltero</option>
            <option value="casado">Casado</option>
            <option value="divorciado">Divorciado</option>
            <option value="viudo">Viudo</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-end mt-lg-0 mt-6">
          <button className="btn btn-primary me-3" onClick={handleSearch}>
            Buscar
          </button>
          <button className="btn btn-secondary" onClick={handleReset}>
            Resetear
          </button>
        </div>
      </div>
    </>

  );
};

export default SolicitudesListSearch;
