import { Navigate, Routes, Route } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Index } from './index';
import { Create } from './create';

const nombramientosBreadCrumbs: Array<PageLink> = [
    {
        title: 'Nombramientos',
        path: '/nombramientos',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];

const NombramientosPage = () => (
    <Routes>
        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={nombramientosBreadCrumbs}>Nombramiento y Certificación</PageTitle>
                    <Index />
                </>
            }
        />
        <Route
            path='create/:solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={nombramientosBreadCrumbs}>Nombramiento y Certificación</PageTitle>
                    <Create />
                </>
            }
        />

        <Route index element={<Navigate to='/nombramientos/index' />} />
    </Routes>
);

export default NombramientosPage;
