import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaUserEdit  } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { MdOutlineWorkHistory } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { GiWhiteBook } from "react-icons/gi";
import { FaPencil } from "react-icons/fa6";

import Swal from 'sweetalert2';
import { ISolicitud } from '../solicitudes/model';

interface SolicitudesListBodyProps {
  data: ISolicitud[];
  isLoading: boolean;
  handleDetail: (solicitudId: number) => void; // Agrega handleDetail como prop
  onRemoveSolicitud: (solicitudId: number) => void; // Nueva prop
  handle: (solicitudId: number) => void;
}
interface StatusMessages {
  [key: number]: string;
}

  const SolicitudesListBody: React.FC<SolicitudesListBodyProps> = ({ data, isLoading, handleDetail, onRemoveSolicitud, handle}) => {

  return (
    <>

      <tbody>
        {isLoading ? ( // Mostrar mensaje de carga mientras se realiza la búsqueda
          <tr>
            <td colSpan={5}>Cargando...</td>
          </tr>
        ) : (
          data.map((solicitud) => (
            <tr key={solicitud.id}>
              <td>{solicitud.nombre} {solicitud.primer_apellido} {solicitud.segundo_apellido}</td>
              <td>{solicitud?.municipio_corporacion?.nombre}</td>
              <td>{solicitud?.nombramientos_certificaciones?.cuip}</td>
              <td>{solicitud?.nombramientos_certificaciones?.cup}</td>
              <td>{solicitud?.nombramientos_certificaciones?.comision}</td>
              <td>{solicitud?.nombramientos_certificaciones?.cargo}</td>
              <td>{solicitud?.nombramientos_certificaciones?.no_empleado}</td>
              <td>{solicitud?.email}</td>
              <td>{solicitud?.curp}</td>
              <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Evaluación de Competencias Básicas. 
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handle(solicitud.id)}
                    >
                      <GoChecklist  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

export default SolicitudesListBody;
