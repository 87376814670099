import axios, { AxiosResponse } from "axios";
import { Correccion } from "./model";

export const getCorrecciones = async (): 
Promise<{ correccion: Correccion[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/tipo-correccions`;
        const response = await axios.get(queryString);
        const correccion = response.data;
        return  {correccion};
    }catch (error) {
        throw error;
    }
}

  export const createCorreccion = async (formData: FormData): Promise<Correccion> => {
    try {
      const response: AxiosResponse<{ message: string; tipoCorreccion: Correccion }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/tipo-correccions`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando correccion');
      }
      return response.data.tipoCorreccion;
    } catch (error) {
      throw error;
    }
  };

  export const updateCorreccion = async ( correcionId:number,formData: FormData): Promise<Correccion> => {
    try {
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; tipoCorreccion: Correccion }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/tipo-correccions/${correcionId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 200) {
        throw new Error('Error actualizando correccion');
      }
      return response.data.tipoCorreccion;
    } catch (error) {
      throw error;
    }
  };   

  export const deleteCorreccion = async (correccionId:number): Promise<Correccion> => {
    try {
        const response: AxiosResponse<{ message: string; tipoCorreccion: Correccion }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/tipo-correccions/${correccionId}`,
        {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        }
        );
        if (response.status !== 204) {
        throw new Error('Error eliminando correccion');
        }
        return response.data.tipoCorreccion;
    } catch (error) {
        throw error;
    }
  };