
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SancionModel, SancionFormValues } from './model';
import * as Yup from 'yup';
import { useAuth } from '../auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateSancion } from './api';
import { Curso, Instancia, Sancion } from '../catalogos/model';
import { getCursos } from '../catalogos/apiCursos';
import { getInstancias } from '../catalogos/apiInstancias';
import { getSanciones } from '../catalogos/apiSanciones';

interface EditSancionModalProps {
    show: boolean;
    handleClose: () => void;
    sancion:SancionModel | null;
    modificarSancion : any;
    solicitud:any;
}

const EditSancion: React.FC<EditSancionModalProps> = ({ show, handleClose,sancion,modificarSancion,solicitud }) => {  
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    //Para los select de catalogos
    const [dataInstancia, setDataInstancia] = useState<Instancia[]>([]);
    const [dataSancion, setDataSancion] = useState<Sancion[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');
    

    const sancionSchema = Yup.object().shape({
        causa: Yup.string().max(250,'No se permiten mas de 250 caracteres').required('Es necesario ingresar la causa'),
    id_tipo_sancion: Yup.number().typeError('El tipo de sanción no es valido').integer('El tipo de sanción no es valido').required('Es necesario ingresar un tipo de sanción').test('is-number', 'El tipo de sanción no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
      id_instancia_sancion: Yup.number().typeError('La instancia de sanción no es valido').integer('La instancia de sanción no es valido').required('Es necesario ingresar una instancia de sanción').test('is-number', 'La instancia de sanción no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
      recurso_revision: Yup.number().typeError('El recurso de revisión no es valido').integer('El recurso de revisión no es valido').required('Es necesario ingresar el recurso de revisión').test('is-number', 'El recurso de revisión no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
      duracion: Yup.number().typeError('La duración debe ser un número').integer('La duración debe ser un numero entero').max(365,'La duración debe ser menor a 365').min(1,'La duración debe ser mayor a 0').required('Es necesario ingresar la duración').test('is-number', 'La duración debe ser un número', value => {
        if (!value) return true; // Si no hay valor, la validación pasa
        return !isNaN(value); // Si es un número, la validación pasa
      }),
      cantidad_duracion: Yup.string().required('Es necesario ingresar el tipo de cantidad de duracion'),
      fecha_inicio: Yup.date().required('Es necesario ingresar la fecha de inicio'),
      fecha_fin: Yup.date().required('Es necesario ingresar la fecha de finalización'),
      fecha_determinacion: Yup.date().required('Es necesario ingresar la fecha de determinación'),
  });
  
  //console.log(curso);

  let formik = useFormik<SancionFormValues>({
    initialValues: {
        causa: '',
    },
    validationSchema: sancionSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevaSancion = await updateSancion(sancion?.id || 1,formData);
        console.log(nuevaSancion);
        Swal.fire({
          title: 'Actualizado',
          text: 'Sanción actualizada exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de sanciones
        modificarSancion(nuevaSancion);
        //Cerramos el modal
        handleClose();
        navigate('/sanciones/'+ solicitud);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error actualizando una sanción',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
    const fetchSanciones = async () => {
        setIsLoading(true);
        setError('');
        try {
          const  {sanciones}  = await getSanciones();
          setDataSancion(sanciones);
          setIsLoading(false);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message); 
          } else {
            setError('Ocurrio un error inesperado'); 
          }
          setIsLoading(false);
        }
      };
  
    const fetchInstancias = async () => {
        setLoading(true);
        setError('');
        try {
          const  {instancia}  = await getInstancias();
          setDataInstancia(instancia);
          setLoading(false);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message); 
          } else {
            setError('Ocurrio un error inesperado'); 
          }
          setLoading(false);
        }
      };
      fetchSanciones();
      fetchInstancias();
    
  },[]);
  useEffect(() => {
    if (sancion) {
        // Actualizar los valores del formulario cuando cambie la Sanción
        formik.setValues({
            causa: sancion.causa,
            duracion: sancion.duracion,
            cantidad_duracion: sancion.cantidad_duracion,
            id_instancia_sancion: sancion.id_instancia_sancion,
            id_tipo_sancion: sancion.id_tipo_sancion,
            fecha_fin: sancion.fecha_fin,
            fecha_inicio: sancion.fecha_inicio,
            recurso_revision: sancion.recurso_revision,
            fecha_determinacion: sancion.fecha_determinacion,
        });
    }
}, [sancion]); 

  
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Sanción</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="id_instancia_sancion" className="form-label">Instancia Sancionadora</label>
                <select className="form-control" id="id_instancia_sancion" {...formik.getFieldProps('id_instancia_sancion')}>
                <option value="">Por favor seleccione una opción</option>
                {dataInstancia.map((instancia) => (
                    <option value={instancia.id}>{instancia.nombre}</option>
                ))}

                </select>
                {formik.touched.id_instancia_sancion && formik.errors.id_instancia_sancion && <div className="text-danger">{formik.errors.id_instancia_sancion}</div>}
            </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="id_tipo_sancion" className="form-label">Tipo de Sanción</label>
                <select className="form-control" id="id_tipo_sancion" {...formik.getFieldProps('id_tipo_sancion')}>
                <option value="">Por favor seleccione una opción</option>
                {dataSancion.map((sancion) => (
                    <option value={sancion.id}>{sancion.nombre}</option>
                ))}

                </select>
                {formik.touched.id_tipo_sancion && formik.errors.id_tipo_sancion && <div className="text-danger">{formik.errors.id_tipo_sancion}</div>}
            </div>
            <div className="mb-3 col-md-4 mt-3">
                <label htmlFor="causa" className="form-label">Causa</label>
                <input type="text" className="form-control" id="causa" {...formik.getFieldProps('causa')} />
                {formik.touched.causa && formik.errors.causa && <div className="text-danger">{formik.errors.causa}</div>}
                </div>
            <div className="mb-3 col-md-4 mt-3">
                <label htmlFor="duracion" className="form-label">Duración</label>
                <input type="number" className="form-control" id="duracion" {...formik.getFieldProps('duracion')} />
                {formik.touched.duracion && formik.errors.duracion && <div className="text-danger">{formik.errors.duracion}</div>}
                </div>
                <div className="mb-3 col-md-4 mt-3">
                <label htmlFor="cantidad_duracion" className="form-label">---</label>
                <select className="form-control" id="cantidad_duracion" {...formik.getFieldProps('cantidad_duracion')}>
                <option value="">Por favor seleccione una opción</option>
                    <option value="Horas">Horas</option>
                    <option value="Dias">Dias</option>
                    <option value="Semanas">Semanas</option>
                    <option value="Meses">Meses</option>
                    <option value="Años">Años</option>
                </select>
                {formik.touched.cantidad_duracion && formik.errors.cantidad_duracion && <div className="text-danger">{formik.errors.cantidad_duracion}</div>}
            </div>
            <div className="mb-3 col-md-12 mt-3">
                <label htmlFor="recurso_revision" className="form-label">Recurso Revisión</label>
                <select className="form-control" id="recurso_revision" {...formik.getFieldProps('recurso_revision')}>
                <option value="">Por favor seleccione una opción</option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                </select>
                {formik.touched.recurso_revision && formik.errors.recurso_revision && <div className="text-danger">{formik.errors.recurso_revision}</div>}
             </div>
            <div className="mb-3 col-md-4 mt-3">
                <label htmlFor="fecha_inicio" className="form-label">Fecha de Inicio</label>
                <input type="date" className="form-control" id="fecha_inicio" {...formik.getFieldProps('fecha_inicio')} />
                {formik.touched.fecha_inicio && formik.errors.fecha_inicio && <div className="text-danger">{formik.errors.fecha_inicio}</div>}
            </div>
                <div className="mb-3 col-md-4 mt-3">
                <label htmlFor="fecha_fin" className="form-label">Fecha de Finalización</label>
                <input type="date" className="form-control" id="fecha_fin" {...formik.getFieldProps('fecha_fin')} />
                {formik.touched.fecha_fin && formik.errors.fecha_fin && <div className="text-danger">{formik.errors.fecha_fin}</div>}
                </div>
                <div className="mb-3 col-md-4 mt-3">
                <label htmlFor="fecha_determinacion" className="form-label">Fecha de Determinación</label>
                <input type="date" className="form-control" id="fecha_determinacion" {...formik.getFieldProps('fecha_determinacion')} />
                {formik.touched.fecha_determinacion && formik.errors.fecha_determinacion && <div className="text-danger">{formik.errors.fecha_determinacion}</div>}
                </div>
        
            </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>


        </Modal>
    );
};

export default EditSancion;