
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MotivoEstimulo, MotivoEstimuloFormValues } from './model';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { createMotivoEstimulo } from './apiEstimulos';


interface CreateEstimulosModalProps {
    show: boolean;
    handleClose: () => void;
    agregarNuevoMotivoEstimulo: any;
    data:MotivoEstimulo[];
}

const CreateEstimulo: React.FC<CreateEstimulosModalProps> = ({ show, handleClose,agregarNuevoMotivoEstimulo, data}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {currentUser} = useAuth();



  const sancionSchema = Yup.object().shape({
    nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
  });
  const formik = useFormik<MotivoEstimuloFormValues>({
    initialValues: {
      nombre: '',
    },
    validationSchema: sancionSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        if(currentUser){
          formData.append('user_id', currentUser.id.toString());
        }
         const nuevoMotivoEstimulo = await createMotivoEstimulo(formData);
        
        Swal.fire({
          title: 'Guardado',
          text: 'Motivo de estimulo creado exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de estimulos
        agregarNuevoMotivoEstimulo(nuevoMotivoEstimulo);
        //Cerramos el modal
        handleClose();
        navigate('/catalogos/index');
      } catch (error) {
        console.error('Error creando un motivo de estimulo:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creando un motivo de estimulo',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Nuevo Estimulo</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
                <div className="mb-3 col-md-12 mt-3">
                <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
                <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
                {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
                </div>
                
            </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                </button>


            </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateEstimulo;