
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormacionContinuaModel, FormacionContinuaFormValues } from './model';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import Swal from 'sweetalert2';
import { createFormacionContinua } from './api';
import { Curso, Instancia } from '../catalogos/model';
import { getCursos } from '../catalogos/apiCursos';
import { getInstancias } from '../catalogos/apiInstancias';


interface CreateFormacionesContinuasModalProps {
    show: boolean;
    handleClose: () => void;
    agregarNuevaFormacionContinua: any;
    solicitud: any;
    data:FormacionContinuaModel[];
}

const CreateFormacionContinua: React.FC<CreateFormacionesContinuasModalProps> = ({ show, handleClose,agregarNuevaFormacionContinua, solicitud}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {currentUser} = useAuth();
    //Para los select de catalogos
    const [dataCursos, setDataCursos] = useState<Curso[]>([]);
    const [dataInstancia, setDataInstancia] = useState<Instancia[]>([]);
    const [error, setError] = useState('');
    //const [isLoading, setLoading] = useState(false);




 //Para mandar llamar el listado de cursos
 useEffect(() => {
    const fetchCursos = async () => {
      setLoading(true);
      setError('');
      try {
        const  {cursos}  = await getCursos(currentUser);
        setDataCursos(cursos);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message); 
        } else {
          setError('Ocurrio un error inesperado'); 
        }
        setLoading(false);
      }
    };
    const fetchInstancias = async () => {
        setLoading(true);
        setError('');
        try {
          const  {instancia}  = await getInstancias();
          setDataInstancia(instancia);
          setLoading(false);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message); 
          } else {
            setError('Ocurrio un error inesperado'); 
          }
          setLoading(false);
        }
      };

    fetchCursos();
    fetchInstancias();
    
  },[]);
  const formacionContinuaSchema = Yup.object().shape({
    importancia: Yup.string().required('Es necesario ingresar el tipo de importancia'),
    duracion: Yup.number().typeError('La duración debe ser un número').integer('La duración debe ser un numero entero').max(365,'La duración debe ser menor a 365').min(1,'La duración debe ser mayor a 0').required('Es necesario ingresar la duración').test('is-number', 'La duración debe ser un número', value => {
        if (!value) return true; // Si no hay valor, la validación pasa
        return !isNaN(value); // Si es un número, la validación pasa
      }),
    id_curso: Yup.number().typeError('El curso no es valido').integer('El curso no es valido').required('Es necesario ingresar un curso').test('is-number', 'El curso no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
    id_instancia_capacitadora: Yup.number().typeError('La instancia capacitadora no es valida').integer('La instancia capacitadora no es valida').required('Es necesario ingresar una instancia capacitadora').test('is-number', 'La instancia capacitadora no es valida', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
    cantidad_duracion: Yup.string().required('Es necesario ingresar el tipo de cantidad de duracion'),
    fecha_inicio: Yup.date().required('Es necesario ingresar la fecha de inicio'),
    fecha_fin: Yup.date().required('Es necesario ingresar la fecha de finalización'),
  });
  const formik = useFormik<FormacionContinuaFormValues>({
    initialValues: {
      importancia: '',
      cantidad_duracion: '',
    },
    validationSchema: formacionContinuaSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevaFormacionContinua = await createFormacionContinua(formData);
        
        Swal.fire({
          title: 'Guardado',
          text: 'Formación Continua creada exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de estimulos
        agregarNuevaFormacionContinua(nuevaFormacionContinua);
        //Cerramos el modal
        handleClose();
        navigate('/formacion-continua/'+ solicitud);
      } catch (error) {
        console.error('Error creando una Formación Continua:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creando una Formación Continua',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
    return (
        <Modal show={show} onHide={handleClose} className='modal-lg'>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Nueva Formación Continua</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="id_curso" className="form-label">Curso</label>
                <select className="form-control" id="id_curso" {...formik.getFieldProps('id_curso')}>
                <option value="">Por favor seleccione una opción</option>
                {dataCursos.map((curso) => (
                    <option value={curso.id}>{curso.nombre}</option>
                ))}

                </select>
                {formik.touched.id_curso && formik.errors.id_curso && <div className="text-danger">{formik.errors.id_curso}</div>}
                </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="id_instancia_capacitadora" className="form-label">Instancia Capacitadora</label>
                <select className="form-control" id="id_instancia_capacitadora" {...formik.getFieldProps('id_instancia_capacitadora')}>
                <option value="">Por favor seleccione una opción</option>
                {dataInstancia.map((instancia) => (
                    <option value={instancia.id}>{instancia.nombre}</option>
                ))}

                </select>
                {formik.touched.id_instancia_capacitadora && formik.errors.id_instancia_capacitadora && <div className="text-danger">{formik.errors.id_instancia_capacitadora}</div>}
            </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="duracion" className="form-label">Duración</label>
                <input type="number" className="form-control" id="duracion" {...formik.getFieldProps('duracion')} />
                {formik.touched.duracion && formik.errors.duracion && <div className="text-danger">{formik.errors.duracion}</div>}
                </div>
                <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="cantidad_duracion" className="form-label">---</label>
                <select className="form-control" id="cantidad_duracion" {...formik.getFieldProps('cantidad_duracion')}>
                <option value="">Por favor seleccione una opción</option>
                    <option value="Horas">Horas</option>
                    <option value="Dias">Dias</option>
                    <option value="Semanas">Semanas</option>
                    <option value="Meses">Meses</option>
                    <option value="Años">Años</option>
                </select>
                {formik.touched.cantidad_duracion && formik.errors.cantidad_duracion && <div className="text-danger">{formik.errors.cantidad_duracion}</div>}
            </div>
            <div className="mb-3 col-md-12 mt-3">
                <label htmlFor="importancia" className="form-label">Importancia</label>
                <select className="form-control" id="importancia" {...formik.getFieldProps('importancia')}>
                <option value="">Por favor seleccione una opción</option>
                    <option value="Alto">Alto</option>
                    <option value="Medio">Medio</option>
                    <option value="Bajo">Bajo</option>
                </select>
                {formik.touched.importancia && formik.errors.importancia && <div className="text-danger">{formik.errors.importancia}</div>}
             </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="fecha_inicio" className="form-label">Fecha de inicio</label>
                <input type="date" className="form-control" id="fecha_inicio" {...formik.getFieldProps('fecha_inicio')} />
                {formik.touched.fecha_inicio && formik.errors.fecha_inicio && <div className="text-danger">{formik.errors.fecha_inicio}</div>}
            </div>
                <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="fecha_fin" className="form-label">Fecha de finalización</label>
                <input type="date" className="form-control" id="fecha_fin" {...formik.getFieldProps('fecha_fin')} />
                {formik.touched.fecha_fin && formik.errors.fecha_fin && <div className="text-danger">{formik.errors.fecha_fin}</div>}
                </div>
        
            </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                </button>


            </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateFormacionContinua;