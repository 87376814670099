
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormacionInicialFormValues, FormacionInicialModel} from './model';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import { FormikValues, useFormik, Field, ErrorMessage, FormikProvider  } from 'formik';
import Swal from 'sweetalert2';
import { createFormacionInicial2, getInstanciasCapacitadoras, getInstanciasResponsables } from '../selecciones/api';
import { TipoFormacion, ResultadoFormacion, DuracionFormacion, EstatusEvaluacionFormacion, FormacionInicial } from '../selecciones/modelFormacionInicial';
import { Instancia } from '../catalogos/model';

interface CreateModalProps {
    show: boolean;
    handleClose: () => void;
    agregarNuevoModelo: any;
    solicitud: any;
    data:FormacionInicialModel[];
}

const Create: React.FC<CreateModalProps> = ({ show, handleClose,agregarNuevoModelo, solicitud}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {currentUser} = useAuth();
    const [instancias, setInstancias] = useState([]);// Instancias capacitadoras
    const [instanciasr, setInstanciasr] = useState([]);// Instancias responsables  
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      cargarInstanciasCapacitadoras();
      cargarInstanciasResponsables();
  }, [currentUser]);

  const cargarInstanciasCapacitadoras = async () => {
      setLoading(true);
      try {
          if (currentUser) {
              const data = await getInstanciasCapacitadoras(currentUser.id.toString());
              setInstancias(data);
          }
      } catch (error) {
          Swal.fire("Error", "No se pudieron cargar las instancias capacitadoras.", "error");
      } finally {
          setLoading(false);
      }
  };

  const cargarInstanciasResponsables = async () => {
      setLoading(true);
      try {
          if (currentUser) {
              const data = await getInstanciasResponsables(currentUser.id.toString());
              setInstanciasr(data);
          }
      } catch (error) {
          Swal.fire("Error", "No se pudieron cargar las instancias responsables.", "error");
      } finally {
          setLoading(false);
      }
  }

  const modelSchema = 
  Yup.object({
    tipo: Yup.string().max(255).required('Por favor ingrese el tipo de formación'),
    resultado: Yup.string().max(255).required('Por favor ingrese el resultado de la formación'),
    fecha_inicio: Yup.date().required('Por favor ingrese la fecha de inicio'),
    fecha_fin: Yup.date().required('Por favor ingrese la fecha de fin').min(
        Yup.ref('fecha_inicio'),
        'La fecha de fin debe ser posterior a la fecha de inicio'
      ),
    duracion: Yup.number().min(1, 'Debe ser mayor a 1').max(100000, 'Debe ser menor a 100,000').required('Por favor ingrese la duración'),
    cantidad_duracion: Yup.string().max(255).required('Por favor ingrese la cantidad de duración'),
    calificacion_obtenida: Yup.number().when('resultado', {
        is: 'Aprobado',
        then: Yup.number().required('Por favor ingrese la calificación obtenida')
    }).min(0, 'La calificación debe ser mayor a 0').max(10, 'La calificación debe ser menor a 10'),
    estatus_evaluacion: Yup.string().max(255).required('Por favor ingrese el estatus de la evaluación'),
    motivo_incompleta: Yup.string().when('estatus_evaluacion', {
        is: 'Incompleta',
        then: Yup.string().required('Por favor ingrese el motivo incompleto')
    }),
    observaciones: Yup.string().max(255).required('Por favor ingrese las observaciones'),
    id_instancia_capacitadora: Yup.number().required('Por favor ingrese la instancia capacitadora'),
    id_instancia_responsable: Yup.number().required('Por favor ingrese la instancia responsable'),
  });

  const formik = useFormik<FormacionInicialFormValues>({
    initialValues: {
    },
    validationSchema: modelSchema,
    onSubmit: async (values) => {
      console.log("No inicia");
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevoModelo = await createFormacionInicial2(formData);
        
        Swal.fire({
          title: 'Guardado',
          text: 'Creado exitosamente',
          icon: 'success',
        });
        
        agregarNuevoModelo(nuevoModelo);
        //Cerramos el modal
        handleClose();
        navigate('/formacion-inicial/'+ solicitud);
      } catch (error) {
        console.error('Error al crear:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al crear',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
    return (
        <Modal show={show} onHide={handleClose} className='modal-lg'>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Nueva Formacíon Inicial</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <FormikProvider value={formik}>
            <div className="row">
                              {/* Tipo */}
                              <div className="col-lg-12">
                    <label htmlFor="tipo" className="form-label required fw-bold fs-6">Tipo de formación</label>
                    <Field as="select" name="tipo" className="form-select">
                        <option value="">Seleccione un tipo</option>
                        {Object.entries(TipoFormacion).map(([key, value]) => (
                            <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="tipo" component="div" className="text-danger" />
                </div>

                {/* Resultado */}
                <div className="col-lg-12">
                    <label htmlFor="resultado" className="form-label required fw-bold fs-6">Resultado de la formación</label>
                    <Field as="select" name="resultado" className="form-select">
                        <option value="">Seleccione un resultado</option>
                        {Object.entries(ResultadoFormacion).map(([key, value]) => (
                            <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="resultado" component="div" className="text-danger" />
                </div>

                {/* Fecha de Inicio */}
                <div className="col-lg-12">
                    <label htmlFor="fecha_inicio" className="form-label required fw-bold fs-6">Fecha de inicio</label>
                    <Field type="date" name="fecha_inicio" className="form-control" />
                    <ErrorMessage name="fecha_inicio" component="div" className="text-danger" />
                </div>

                {/* Fecha de Fin */}
                <div className="col-lg-12">
                    <label htmlFor="fecha_fin" className="form-label required fw-bold fs-6">Fecha de fin</label>
                    <Field type="date" name="fecha_fin" className="form-control" />
                    <ErrorMessage name="fecha_fin" component="div" className="text-danger" />
                </div>

                {/* Duración */}
                <div className="col-lg-12">
                    <label htmlFor="duracion" className="form-label required fw-bold fs-6">Duración</label>
                    <Field type="number" name="duracion" className="form-control" />
                    <ErrorMessage name="duracion" component="div" className="text-danger" />
                </div>

                {/* Cantidad Duración */}
                <div className="col-lg-12">
                    <label htmlFor="cantidad_duracion" className="form-label required fw-bold fs-6">Cantidad de duración</label>
                    <Field as="select" name="cantidad_duracion" className="form-select">
                        <option value="">Seleccione la unidad de duración</option>
                        {Object.entries(DuracionFormacion).map(([key, value]) => (
                            <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="cantidad_duracion" component="div" className="text-danger" />
                </div>

                {/* Calificación Obtenida */}
                <div className="col-lg-12">
                    <label htmlFor="calificacion_obtenida" className="form-label required fw-bold fs-6">Calificación obtenida</label>
                    <Field type="number" name="calificacion_obtenida" className="form-control" />
                    <ErrorMessage name="calificacion_obtenida" component="div" className="text-danger" />
                </div>

                {/* Estatus Evaluación */}
                <div className="col-lg-12">
                    <label htmlFor="estatus_evaluacion" className="form-label required fw-bold fs-6">Estatus de la evaluación</label>
                    <Field as="select" name="estatus_evaluacion" className="form-select">
                        <option value="">Seleccione el estatus</option>
                        {Object.entries(EstatusEvaluacionFormacion).map(([key, value]) => (
                        <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="estatus_evaluacion" component="div" className="text-danger" />
                </div>
                            {/* Motivo Incompleta */}
            <div className="col-lg-12">
                <label htmlFor="motivo_incompleta" className="form-label required fw-bold fs-6">Motivo si está incompleta</label>
                <Field type="text" name="motivo_incompleta" className="form-control" />
                <ErrorMessage name="motivo_incompleta" component="div" className="text-danger" />
            </div>

            {/* Observaciones */}
            <div className="col-lg-12">
                <label htmlFor="observaciones" className="form-label required fw-bold fs-6">Observaciones</label>
                <Field type="text" name="observaciones" className="form-control" />
                <ErrorMessage name="observaciones" component="div" className="text-danger" />
            </div>

            {/* Instancia Capacitadora */}
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Instancia Capacitadora</label>
                <Field
                    name='id_instancia_capacitadora'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value=''>Selecciona una instancia capacitadora</option> {/* Opción por defecto */}
                    {instancias.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='id_instancia_capacitadora' />
                </div>
            </div>

    

            {/* Instancia Responsable */}
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Instancia Responsable</label>
                <Field
                    name='id_instancia_responsable'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value=''>Selecciona una instancia responsable</option> {/* Opción por defecto */}
                    {instanciasr.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='id_instancia_responsable' />
                </div>
            </div>

            </div>
            </FormikProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                </button>


            </Modal.Footer>
            </form>
        </Modal>
    );
};

export default Create;