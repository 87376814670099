import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import SolicitudesListActivos from './SolicitudesList'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Elementos Activos',
        path: '/activos',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ActivosPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Elementos Activos</PageTitle>
                    <SolicitudesListActivos />
                </>
            }
        />

        <Route index element={<Navigate to='/activos/index' />} />

    </Routes>
)

export default ActivosPage