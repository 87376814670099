import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import { EstimuloModel } from "./model";
import { getEstimulo } from "./api";
import { useAuth } from "../auth";
import { useParams } from "react-router-dom";
import CreateEstimulo from "./create";
import EditEstimulo from "./edit";
import DestroyEstimulo from "./destroy";
/*import CreateFormacionContinua from "./create";
import EditFormacionContinua from "./edit";
import DestroyFormacionContinua from "./destroy";*/

const Estimulos: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedEstimulo, setSelectedEstimulo] = useState<EstimuloModel | null>(null);
    const [estimulos, setData] = useState<EstimuloModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {currentUser} = useAuth();
    const { solicitudId } = useParams<{ solicitudId: string }>(); // Obtenemos el solicitudId de los parámetros


    //Para mandar llamar el listado de estimulos
    useEffect(() => {
        const fetchEstimulos = async () => {
          setIsLoading(true);
          setError('');
          try {
            const  {estimulos}  = await getEstimulo(solicitudId);
            setData(estimulos);
            setIsLoading(false);
          } catch (error) {
            if (error instanceof Error) {
              setError(error.message); 
            } else {
              setError('Ocurrio un error inesperado'); 
            }
            setIsLoading(false);
          }
        };
    
        fetchEstimulos();
        
      },[]);
      
    //Para poder cambiar el estatus en memoria de los cambios
  const crearEstimulo = (nuevoEstimulo: EstimuloModel) => {
    setData(estimulos => [...estimulos, nuevoEstimulo]);
  };
  
 

  const actualizarEstimulo = (nuevoEstimulo: EstimuloModel) => {
    setData(estimulos => {
        const estimuloExistenteIndex = estimulos.findIndex(curso => curso.id === nuevoEstimulo.id);

        if (estimuloExistenteIndex !== -1) {
            // Si el curso existe, actualiza su información
            const nuevosEstimulos = [...estimulos];
            nuevosEstimulos[estimuloExistenteIndex] = nuevoEstimulo;
            return nuevosEstimulos;
        } else {
            // Si el curso no existe, agrégalo a la lista
            return [...estimulos, nuevoEstimulo];
        }
    });
};

const eliminarEstimulo = (estimuloEliminar: EstimuloModel) => {
  setData(estimulos => {
      const nuevosEstimulos = estimulos.filter(estimulo => estimulo.id !== estimuloEliminar.id);
      return nuevosEstimulos;
  });
};

  if (isLoading) {
    return <div>Por favor espere</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }


    const handleShowCreateModal = () => {
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleShowEditModal = (estimulo:EstimuloModel) => {
        setSelectedEstimulo(estimulo);
        setShowEditModal(true);
    };
    const handleShowDestroyModal = (estimulo:EstimuloModel) => {
        setSelectedEstimulo(estimulo);
        setShowDestroyModal(true);
    };
    const handleCloseModal = () => {
        setShowEditModal(false);
        setShowDestroyModal(false);
      };

    return (
        
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title m-0">Estimulos</h3>
                    <div>
                        <button className="btn btn-info" onClick={() => handleShowCreateModal()}>Nuevo <BsPlusCircle /></button>
                           <CreateEstimulo solicitud={solicitudId} show={showCreateModal} handleClose={handleCloseCreateModal} data={estimulos} agregarNuevoEstimulo={crearEstimulo}  />
                    </div>
            </div>
            <div className="card-body">
                <div className="row">
                {estimulos.map((estimulo) => (
                    <div className="col-md-4 mt-2">
                    <div className="card shadow-lg">
                        <div className="card-header bg-primary text-white align-items-center">
                        {estimulo.motivo_estimulo?.nombre}
                        </div>
                        <div className="card-body align-items-center">
                            <p className="text-info text-center">{estimulo.estimulo}</p>
                            <p className="text-bold text-center">Duración: {estimulo.fecha} </p>
                    

                            <button className="btn btn-warning m-2" onClick={() => handleShowEditModal(estimulo)}><BsPencil/></button>
                         <button className="btn btn-danger m-2" onClick={() => handleShowDestroyModal(estimulo)}><BsTrash /></button>
                
                        </div>
                    </div>
                    </div>
                    ))}
                    <EditEstimulo estimulo={selectedEstimulo} show={showEditModal} handleClose={handleCloseModal} modificarEstimulo={actualizarEstimulo} solicitud={solicitudId}/>
                    
                    <DestroyEstimulo estimulo={selectedEstimulo} show={showDestroyModal} handleClose={handleCloseModal} eliminarEstimulo={eliminarEstimulo}/>
                    
                </div>
                
            </div>
        </div>
    
            
      );
};

export default Estimulos;
    