import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ModelList from './SolicitudesList'

const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Por Vencer Evaluación de Desempeño',
        path: '/por-vencer-desempeno',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const PorVencerDesempenoPage = () => (
    <Routes>
        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Por Vencer Evaluación de Desempeño</PageTitle>
                    <ModelList />
                </>
            }
        />

        <Route index element={<Navigate to='/por-vencer-desempeno/index' />} />

    </Routes>
)

export default PorVencerDesempenoPage