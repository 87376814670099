
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Curso } from './model';
import Swal from 'sweetalert2';
import { deleteCurso } from './apiCursos';
//import { IAnnouncement } from './model';

interface DestroyCursosModalProps {
    curso:Curso | null;
    show: boolean;
    handleClose: () => void;
    eliminarCurso: any;

   // announcement: IAnnouncement | null;
}

const DestroyCurso: React.FC<DestroyCursosModalProps> = ({ show, handleClose,curso,eliminarCurso }) => { 
  const [loading, setLoading] = useState(false);

  const handleDelete = async (cursoId: number) => {
    setLoading(true);

    try{
        //Lo eliminamos de la base de datos
        const cursoActual = await deleteCurso(cursoId);
        Swal.fire({
            title: 'Eliminado',
            text: 'Curso eliminado exitosamente',
            icon: 'warning',
          });
        //Lo eliminamos de la vista
        eliminarCurso(cursoActual);
        //Cerramos el modal
        handleClose();

    }catch(error) {
        Swal.fire({
          title: 'Error',
          text: 'Error eliminando un curso',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
  }
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-danger'>
                <Modal.Title className='text-white'>Eliminar Curso {curso?.nombre}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Estas seguro de eliminar el curso?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="button" disabled={loading} className='btn btn-danger' onClick={() => handleDelete(curso!.id)}>Eliminar</button>

            </Modal.Footer>

        </Modal>
    );
};

export default DestroyCurso;