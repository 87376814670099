import React, { useEffect, useState } from 'react';
import { IAnnouncement } from './model';
import AnnouncementModal from './AnnouncementModal';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsEye, BsFiles, BsPencil, BsTrash } from 'react-icons/bs';

import { useNavigate } from 'react-router-dom';

interface AnnouncementListBodyProps {
  data: IAnnouncement[];
  handleDelete: (announcementId: number) => void;
  handleEdit: (announcementId: number) => void; // Agregar el prop de handleEdit
}

const AnnouncementListBody: React.FC<AnnouncementListBodyProps> = ({ data, handleDelete, handleEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<IAnnouncement | null>(null);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (announcement: IAnnouncement) => {
    setSelectedAnnouncement(announcement);
    setShowModal(true);
  };

  const handleViewRequests = (convocatoriaId: number) => {
    navigate(`/solicitudes/home/${convocatoriaId}`);
  };


  return (
    <>
      <tbody>
        {data.map((announcement) => (
          <tr key={announcement.id}>
            <td>{announcement.id}</td>
            <td>{announcement.name}</td>
            <td>{announcement.description}</td>
            <td>{new Date(announcement.start_date).toLocaleDateString()}</td>
            <td>{new Date(announcement.end_date).toLocaleDateString()}</td>
            <td>{announcement.location}</td>
            <td>{announcement.contact_reference}</td>
            <td>
              <ButtonGroup aria-label="Actions">
                <Button variant="info" onClick={() => handleShowModal(announcement)}><BsEye /></Button>
                <Button variant="warning" onClick={() => handleEdit(announcement.id)}><BsPencil /></Button>
                <Button variant="danger" onClick={() => handleDelete(announcement.id)}><BsTrash /></Button>

                <Button variant="primary" onClick={() => handleViewRequests(announcement.id)}>
                  <BsFiles />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
      <AnnouncementModal show={showModal} handleClose={handleCloseModal} announcement={selectedAnnouncement} />
    </>
  );
};

export default AnnouncementListBody;
