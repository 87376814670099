import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ISolicitud } from './model';
import { getSolicitud } from './api';
import { useNavigate } from 'react-router-dom';


const SolicitudesDetail: React.FC = () => {
  const intl = useIntl();
  const { solicitudId } = useParams<{ solicitudId: string }>(); // Obtenemos el solicitudId de los parámetros
  const [solicitud, setSolicitud] = useState<ISolicitud | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL || '';
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSolicitud = async () => {
      setIsLoading(true);
      setError('');

      try {
        const parsedSolicitudId = Number(solicitudId); // Convertimos el solicitudId a número
        if (isNaN(parsedSolicitudId)) {
          throw new Error('Invalid solicitud ID');
        }

        const fetchedSolicitud = await getSolicitud(parsedSolicitudId);

        console.log(fetchedSolicitud)
        setSolicitud(fetchedSolicitud);
      } catch (error: any) {
        setError(error.message || 'An unexpected error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSolicitud();
  }, [solicitudId]); // Ejecutamos el efecto cada vez que cambia el solicitudId

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!solicitud) {
    return <div>No se encontró la solicitud</div>;
  }

  // Renderizar los detalles de la solicitud aquí
  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between align-items-center" > {/* Ajuste en la altura del header */}
        <div className="d-flex align-items-center" style={{ width: '50%' }}>
          <h3 className="card-title">Detalles de la Solicitud</h3>
        </div>
        <div className="d-flex justify-content-end" style={{ width: '50%' }}>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => navigate(-1)}>
            <i className="fa fa-arrow-left"></i>    Regresar
          </button>
        </div>
      </div>

      <div className="card-body">
        {/* Información del solicitante */}
        <h4 className="mb-3">Información del Solicitante</h4>
        <div className="d-flex justify-content-start align-items-center mb-3">
          <img src={`${imageBaseUrl}${solicitud.fotografia}` || 'default_profile.png'} alt="Foto del perfil" className="img-thumbnail me-3" style={{ width: '100px', height: '100px' }} />
          <div>
            <p className="fw-bold m-0">Nombre: {solicitud.nombre} {solicitud.primer_apellido} {solicitud.segundo_apellido}</p>
            <p className="fw-bold m-0">Email: {solicitud.email}</p>
            <p className="fw-bold m-0">Teléfono: {solicitud.telefono}</p>
            {/* Agrega más detalles personales según sea necesario */}
          </div>
        </div>

        {/* Detalles de la Solicitud */}
        <h4 className="mb-3">Detalles de la Solicitud</h4>
        <div className="accordion" id="accordionDetails">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Información Básica
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetails">
              <div className="accordion-body">
                <div className="row">

                  <div className="col-md-4 mb-3">
                    <p><strong>Folio:</strong> <br />{solicitud.folio}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Fecha de Solicitud:</strong><br />{solicitud.fecha_solicitud}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Sueldo Mensual Deseado:</strong> <br />${solicitud.sueldo_mensual && solicitud.sueldo_mensual.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Datos Personales
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetails">
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <p><strong>Teléfono:</strong> <br />{solicitud.telefono}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Teléfono Móvil:</strong> <br />{solicitud.celular}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Correo Electrónico:</strong> <br />{solicitud.email}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Domicilio:</strong> <br />{solicitud.domicilio}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Colonia:</strong> <br />{solicitud.colonia}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Codigo Postal:</strong> <br />{solicitud.codigo_postal}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Estado:</strong> <br />{solicitud.idEstado}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Municipio:</strong> <br />{solicitud.municipio_residencia?.nombre}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Pais de Nacimiento:</strong> <br />{solicitud.pais_nacimiento}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Estado de Nacimiento:</strong> <br />{solicitud.idEstadoNacimiento}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Municipio de Nacimiento:</strong> <br />{solicitud.municipio_nacimiento?.nombre}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Localidad de Nacimiento:</strong> <br />{solicitud.localidadNacimiento}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Fecha de Nacimiento:</strong> <br />{solicitud.fecha_nacimiento}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Nacionalidad:</strong> <br />{solicitud.nacionalidad?.pais}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Edad:</strong> <br />{solicitud.edad}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Peso:</strong> <br />{solicitud.peso}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Altura (cm):</strong> <br />{solicitud.estatura}cm</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Nivel de Estudio:</strong> <br />{solicitud.nivel_estudio?.nombre}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Estado Civil:</strong> <br />{solicitud.estado_civil}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Estado de salud y hábitos personales
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetails">
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <p><strong>¿Como considera su estado de salud?</strong> <br />{solicitud.estado_medico?.estado_salud}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>¿Padece alguna enfermedad crónica?</strong> <br />
                      {solicitud.estado_medico?.padece_enfermedad_cronica !== null && solicitud.estado_medico?.padece_enfermedad_cronica !== undefined ?
                        (solicitud.estado_medico?.padece_enfermedad_cronica === 1 ? 'Sí' : 'No') :
                        'Información no disponible'}
                    </p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Explique la enfermedad crónica:</strong> <br />{solicitud.estado_medico?.enfermedad_cronica}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>¿Cual es su meta en la vida?</strong> <br />{solicitud.estado_medico?.meta_vida}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>¿Practica algun deporte?</strong> <br />

                      {solicitud.estado_medico?.practica_deporte !== null && solicitud.estado_medico?.practica_deporte !== undefined ?
                        (solicitud.estado_medico?.practica_deporte === 1 ? 'Sí' : 'No') :
                        'Información no disponible'}
                    </p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>¿Cual es su pasatiempo favorito?</strong> <br />{solicitud.estado_medico?.pasatiempo}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>¿Pertenece a algún club social o deportivo?</strong> <br />
                      {solicitud.estado_medico?.practica_deporte !== null && solicitud.estado_medico?.practica_deporte !== undefined ?
                        (solicitud.estado_medico?.practica_deporte === 1 ? 'Sí' : 'No') :
                        'Información no disponible'}
                    </p>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Datos familiares

              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetails">
              <div className="accordion-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th><strong>Parentesco</strong></th>
                      <th><strong>Nombre</strong></th>
                      <th><strong>Edad</strong></th>
                      <th><strong>¿Vive?</strong></th>
                      <th><strong>Domicilio</strong></th>
                      <th><strong>Ocupación</strong></th>
                    </tr>
                  </thead>
                  <tbody>

                    {Array.isArray(solicitud.familiares) && solicitud.familiares.length > 0 ? (
                      solicitud.familiares.map((familiar) => (
                        <tr key={familiar.id}>
                          <td>{familiar.parentesco}</td>
                          <td>{`${familiar.nombre} ${familiar.primer_apellido} ${familiar.segundo_apellido || ''}`}</td>
                          <td>{familiar.edad}</td>
                          <td>{familiar.esta_vivo ? 'Sí' : 'No'}</td>
                          <td>{familiar.domicilio}</td>
                          <td>{familiar.ocupacion}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>No hay datos familiares disponibles</td>
                      </tr>
                    )}
                  </tbody>



                </table>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Escolaridad
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetails">
              <div className="accordion-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th><strong>Nombre</strong></th>
                      <th><strong>Dirección</strong></th>
                      <th><strong>Fecha de inicio</strong></th>
                      <th><strong>Fecha de término</strong></th>
                      <th><strong>Título</strong></th>
                      <th><strong>Años</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    {solicitud.escolaridades && solicitud.escolaridades.length > 0 ? (
                      solicitud.escolaridades.map((escolaridad) => (
                        <tr key={escolaridad.id}>
                          <td>{escolaridad.nombre}</td>
                          <td>{escolaridad.direccion}</td>
                          <td>{escolaridad.fecha_inicio}</td>
                          <td>{escolaridad.fecha_final}</td>
                          <td>{escolaridad.titulo}</td>
                          <td>{escolaridad.anios}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} >No hay datos de escolaridad disponibles</td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Documentación
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetails">
              <div className="accordion-body">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <p><strong>CURP</strong><br />{solicitud.curp}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>RFC</strong> <br />{solicitud.documentos?.rfc}</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>¿Tiene Licencia de Manejo?</strong> <br />
                      {solicitud.documentos?.tiene_licencia !== null && solicitud.documentos?.tiene_licencia !== undefined ?
                        (solicitud.documentos?.tiene_licencia === 1 ? 'Sí' : 'No') :
                        'Información no disponible'}
                    </p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <p><strong>Clase de Licencia:</strong> <br />{solicitud.documentos?.clase_licencia}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Número de Licencia:</strong> <br />{solicitud.documentos?.numero_licencia}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Pasaporte No:</strong> <br />{solicitud.documentos?.pasaporte}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>AFORE:</strong> <br />{solicitud.documentos?.afore}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Número de Seguridad Social:</strong> <br />{solicitud.documentos?.nss}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>¿Cuenta con el Servicio Militar?</strong> <br />{solicitud.documentos?.servicio_militar}</p>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Número de Servicio Militar:</strong> <br />{solicitud.documentos?.num_servicio_militar}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo CURP:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_curp}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_curp}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo RFC:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_curp}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_rfc}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>


                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo Licencia:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_licencia}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_licencia}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>


                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo Pasaporte:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_pasaporte}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_pasaporte}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>


                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo Afore:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_afore}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_afore}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo NSS:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_nss}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_nss}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Archivo Servicio:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_archivo_servicio}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_archivo_servicio}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Acta de Nacimiento:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_acta_nacimiento}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_acta_nacimiento}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <p><strong>Comprobante de Domicilio:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_comprobante_domicilio}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_comprobante_domicilio}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>


                  <div className="col-md-4 mb-3">
                    <p><strong>Credencial de Elector:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_credencial_elector}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_credencial_elector}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>


                  <div className="col-md-4 mb-3">
                    <p><strong>Certificado de Estudios:</strong></p>
                    <iframe src={`${imageBaseUrl}${solicitud.documentos?.clave_certificado_estudios}`} width="100%" height="200px"></iframe>
                    <br />
                    {/* Botón minimalista para abrir el PDF */}
                    <div className="text-center">
                      {/* Botón minimalista para abrir el PDF */}
                      <button
                        type="button"
                        className="btn btn-outline-secondary mt-2" // Usando 'btn-outline-secondary' para un aspecto más suave
                        style={{ padding: '5px 10px', fontSize: '12px' }} // Estilos en línea para un tamaño más pequeño y menos intrusivo
                        onClick={() => window.open(`${imageBaseUrl}${solicitud.documentos?.clave_certificado_estudios}`, '_blank')}>
                        Ver Documento
                      </button>
                    </div>
                  </div>

                </div>



              </div>


            </div>
          </div>

          {/* Continúa agregando más secciones de acordeón según sea necesario */}
        </div>


      </div>
    </div>
  );
};

export default SolicitudesDetail;
