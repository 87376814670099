import axios, { AxiosResponse } from "axios";
import { AnalisisHabitos, ExamenToxicologico, InvestigacionAntecedente, ValoracionPsicologica } from "./model";

//Ejemplo temporal para que no marque error
export const getMotivoEstimulos = async ()=> {

 }

 export const createInvestigacion = async (formData: FormData): Promise<InvestigacionAntecedente> => {
    try {
      const response: AxiosResponse<{ message: string; investigacionAntecedente: InvestigacionAntecedente }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/investigacion-antecedentes`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando investigacion de antecedentes');
      }
      return response.data.investigacionAntecedente;
    } catch (error) {
      throw error;
    }
  };

  export const updateInvestigacion = async (
    investigacionId:number,
    formData: FormData
   ): Promise<InvestigacionAntecedente> => {
    try {
      console.log(investigacionId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; investigacionAntecedente: InvestigacionAntecedente }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/investigacion-antecedentes/${investigacionId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error actualizando investigación de antecedentes');
      }
      return response.data.investigacionAntecedente;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };
  export const createValoracion = async (formData: FormData): Promise<ValoracionPsicologica> => {
    try {
      const response: AxiosResponse<{ message: string; valoracionPsicologica: ValoracionPsicologica }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/valoraciones-psicologicas`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando valoración psicológica');
      }
      return response.data.valoracionPsicologica;
    } catch (error) {
      throw error;
    }
  };


  export const updateValoracion = async (
    valoracionId:number,
    formData: FormData
   ): Promise<ValoracionPsicologica> => {
    try {
      console.log(valoracionId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; valoracionPsicologica: ValoracionPsicologica }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/valoraciones-psicologicas/${valoracionId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error actualizando valoración psicologica');
      }
      return response.data.valoracionPsicologica;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };

  export const createAnalisis = async (formData: FormData): Promise<AnalisisHabitos> => {
    try {
      const response: AxiosResponse<{ message: string; analisisHabito: AnalisisHabitos }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/analisis-habitos`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando análisis de habitos');
      }
      return response.data.analisisHabito;
    } catch (error) {
      throw error;
    }
  };


  export const updateAnalisis = async (
    analisisId:number,
    formData: FormData
   ): Promise<AnalisisHabitos> => {
    try {
      console.log(analisisId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; analisisHabito: AnalisisHabitos }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/analisis-habitos/${analisisId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error actualizando analisis de habitos');
      }
      return response.data.analisisHabito;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };

  export const createExamen = async (formData: FormData): Promise<ExamenToxicologico> => {
    try {
      const response: AxiosResponse<{ message: string; examenToxicologico: ExamenToxicologico }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/examenes-toxicologicos`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando examen toxicologico');
      }
      return response.data.examenToxicologico;
    } catch (error) {
      throw error;
    }
  };


  export const updateExamen = async (
    examenId:number,
    formData: FormData
   ): Promise<ExamenToxicologico> => {
    try {
      console.log(examenId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; examenToxicologico: ExamenToxicologico }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/examenes-toxicologicos/${examenId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error actualizando examen toxicologico');
      }
      return response.data.examenToxicologico;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };
