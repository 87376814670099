import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Estimulos from './index'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Estimulos',
        path: '/estimulos',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const EstimulosPage = () => (
    <Routes>



        <Route
            path=':solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Estimulos</PageTitle>
                    <Estimulos />
                </>
            }
        />

        <Route index element={<Navigate to='/estimulos/:solicitudId' />} />

    </Routes>
)

export default EstimulosPage
