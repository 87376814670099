import axios,{ AxiosResponse } from "axios";
import { IUser, IUserFormValues } from "./model";

export const fetchUsers = async (
  currentPage: number,
  limit: number,
  searchValue: string,
): Promise<{ users: IUser[], totalPages: number }> => { // Notice the return type change
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/users?page=${currentPage}&per_page=${limit}&name=${searchValue}`;

    const response = await axios.get(queryString);

    // Assuming your API response format is the same as the sample JSON you provided:
    const users = response.data.data; // This is the list of users
    const totalPages = response.data.last_page; // This is the total number of pages

    return { users, totalPages }; // Return both the data and the page count
  } catch (error) {
    throw error;
  }
};
  export const createUser = async (formData: FormData): Promise<IUser> => {
    try {


 
      const response: AxiosResponse<{ message: string; user: IUser }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/users`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Especifica el tipo de contenido para el envío de archivos
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creating announcement');
      }
      return response.data.user;
    } catch (error) {
      throw error;
    }
  };


export const deleteUser = async (userId: number) => {
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/users/${userId}`;
    const response = await axios.delete(queryString);
    return response.data; // Puedes ajustar esto según lo que necesites manejar en tu componente
  } catch (error) {
    throw error;
  }
};

export const getUser = async (userId: number): Promise<IUser> => {
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/users/${userId}`;
    const response: AxiosResponse<IUser> = await axios.get<IUser>(queryString);

    if (response.status !== 200) {
      throw new Error('Error fetching user');
    }

    return response.data; // Devolver solo los datos de la respuesta
  } catch (error) {
    throw error;
  }
};

export const editUser = async (userId: number, data: IUserFormValues): Promise<void> => {
  try {
    // Realiza la solicitud PUT
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/users/${userId}`;
    const response: AxiosResponse<void> = await axios.put(queryString, {
      ...data, // Incluir todos los campos del formulario
    });

    if (response.status !== 200) {
      throw new Error('Error editing user');
    }
  } catch (error) {
    throw error;
  }
};