
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Curso, CursoFormValues } from './model';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateCurso } from './apiCursos';
import { useAuth } from '../auth';
//import { IAnnouncement } from './model';

interface EditCursosModalProps {
    show: boolean;
    curso:Curso | null;
    handleClose: () => void;
    modificarCurso: any;
   // announcement: IAnnouncement | null;
}

const EditCurso: React.FC<EditCursosModalProps> = ({ show, handleClose,curso,modificarCurso }) => {  
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    

  const cursoSchema = Yup.object().shape({
    nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
  });
  
  //console.log(curso);

  let formik = useFormik<CursoFormValues>({
    initialValues:{
        nombre: '', // Asignar el nombre del curso actual o una cadena vacía si no hay curso
    },
    validationSchema: cursoSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        if(currentUser){
          formData.append('user_id', currentUser.id.toString());
        }

        const nuevoCurso = await updateCurso(curso?.id || 1,formData);
        console.log(nuevoCurso);
        Swal.fire({
          title: 'Actualizado',
          text: 'Curso actualizado exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de cursos
        modificarCurso(nuevoCurso);
        //Cerramos el modal
        handleClose();
        navigate('/catalogos/index');
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error actualizando un curso',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
    if (curso) {
        // Actualizar los valores del formulario cuando cambie el curso
        formik.setValues({
            nombre: curso.nombre,
        });
    }
}, [curso]); 

  
    return (
        <Modal show={show} onHide={handleClose} id={curso?.id}>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Curso {curso?.nombre}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
                <div className="mb-3 col-md-12 mt-3">
                <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
                <input type="text" className="form-control"  id="nombre" {...formik.getFieldProps('nombre')} />
                {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
                </div>
            </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>


        </Modal>
    );
};

export default EditCurso;