import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    console.log(auth.token)
    if (auth) {
      console.log(auth)
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (config: { headers: { Authorization?: string } }) => {
      const auth = getAuth(); // Asegúrate de que esto recupera el token de autenticación
      if (auth && auth.token) {
        const tokenParts = auth.token.split('|');
        if (tokenParts.length === 2) {
          // Usa solo la parte del token después del '|'
          const actualToken = tokenParts[1];
          config.headers.Authorization = `Bearer ${actualToken}`;
        }
      }
      return config;
    },
    (error: any) => Promise.reject(error)
  );
}



export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
