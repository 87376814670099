import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'


import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import JusticiaCivicaPage from '../modules/justicia-civica/page'
import IphPage from '../modules/iph/page'
import AnnouncementPage from '../modules/announcements/page'
import UserPage from '../modules/users/page'
import SolicitudesPage from '../modules/solicitudes/page'
import ProfilePage from '../modules/profile/page'
import CatalogoPage from '../modules/catalogos/page'
import ReclutamientoPage from '../modules/reclutamientos/page'
import SeleccionesPage from '../modules/selecciones/page'
import NombramientosPage from '../modules/nombramientos/page'
import ElementoPage from '../modules/elementos/page'
import FormacionContinuaPage from '../modules/formacion-continua/page'
import EstimulosPage from '../modules/estimulos/page'
import SancionesPage from '../modules/sanciones/page'
import CorreccionesDisciplinariasPage from '../modules/correcciones-disciplinarias/page'
import ActivosPage from '../modules/activos/page'
import FormacionInicial from '../modules/formacion-inicial/page'
import ControlConfianza from '../modules/control-confianza/page'
import Desempeno from '../modules/desempeno/page'
import Competencia from '../modules/competencia/page'
import NominaPage from '../modules/nomina/page'
import FuerzaPage from '../modules/fuerza/page'
import CertificadosPage from '../modules/certificados/page'
import NoCertificadosPage from '../modules/no-certificados/page'
import VencidosControlConfianzaPage from '../modules/vencidos-control-confianza/page'
import VencidosDesempenoPage from '../modules/vencidos-desempeno/page'
import VencidosCompetenciasBasicasPage from '../modules/vencidos-competencias-basicas/page'
import PorVencerControlConfianzaPage from '../modules/por-vencer-control-confianza/page'
import PorVencerDesempenoPage from '../modules/por-vencer-desempeno/page'
import PorVencerCompetenciasBasicasPage from '../modules/por-vencer-competencias-basicas/page'



const PrivateRoutes = () => {
  //const CrudPage = lazy(() => import('../modules/crud/crudPage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />


        {/* Lazy Modules */}
        <Route
          path='justicia-civica/*'
          element={
            <SuspensedView>
              <JusticiaCivicaPage />
            </SuspensedView>
          }
        />
        <Route
          path='iph/*'
          element={
            <SuspensedView>
              <IphPage />
            </SuspensedView>
          }
        />
        <Route
          path='announcements/*'
          element={
            <SuspensedView>
              <AnnouncementPage />
            </SuspensedView>
          }
        />
        <Route
          path='catalogos/*'
          element={
            <SuspensedView>
              <CatalogoPage />
            </SuspensedView>
          }
        />
        <Route
          path='reclutamientos/*'
          element={
            <SuspensedView>
              <ReclutamientoPage />
            </SuspensedView>
          }
        />

      <Route
          path='elementos/*'
          element={
            <SuspensedView>
              <ElementoPage />
            </SuspensedView>
          }
        />
        <Route
          path='formacion-continua/*'
          element={
            <SuspensedView>
              <FormacionContinuaPage />
            </SuspensedView>
          }
        />
        <Route
          path='estimulos/*'
          element={
            <SuspensedView>
              <EstimulosPage />
            </SuspensedView>
          }
        />
        <Route
          path='sanciones/*'
          element={
            <SuspensedView>
              <SancionesPage />
            </SuspensedView>
          }
        />
        <Route
          path='correcciones-disciplinarias/*'
          element={
            <SuspensedView>
              <CorreccionesDisciplinariasPage />
            </SuspensedView>
          }
        />

        <Route
          path='selecciones/*'
          element={
            <SuspensedView>
              <SeleccionesPage />
            </SuspensedView>
          }
        />

        <Route
          path='nombramientos/*'
          element={
            <SuspensedView>
              <NombramientosPage />
            </SuspensedView>
          }
        />
         <Route
          path='activos/*'
          element={
            <SuspensedView>
              <ActivosPage />
            </SuspensedView>
          }
        />

        <Route
          path='formacion-inicial/*'
          element={
            <SuspensedView>
              <FormacionInicial />
            </SuspensedView>
          }
        />

        <Route
          path='control-confianza/*'
          element={
            <SuspensedView>
              <ControlConfianza />
            </SuspensedView>
          }
        />

        <Route
          path='desempeno/*'
          element={
            <SuspensedView>
              <Desempeno />
            </SuspensedView>
          }
        />

        <Route
          path='competencia/*'
          element={
            <SuspensedView>
              <Competencia />
            </SuspensedView>
          }
        />

        <Route
          path='nomina/*'
          element={
            <SuspensedView>
              <NominaPage />
            </SuspensedView>
          }
        />

        <Route
          path='fuerza/*'
          element={
            <SuspensedView>
              <FuerzaPage />
            </SuspensedView>
          }
        />

        <Route
          path='certificados/*'
          element={
            <SuspensedView>
              <CertificadosPage />
            </SuspensedView>
          }
        />

        <Route
          path='no-certificados/*'
          element={
            <SuspensedView>
              <NoCertificadosPage />
            </SuspensedView>
          }
        />

        <Route
          path='vencidos-control-confianza/*'
          element={
            <SuspensedView>
              <VencidosControlConfianzaPage />
            </SuspensedView>
          }
        />

        <Route
          path='vencidos-desempeno/*'
          element={
            <SuspensedView>
              <VencidosDesempenoPage />
            </SuspensedView>
          }
        />

        <Route
          path='vencidos-competencias-basicas/*'
          element={
            <SuspensedView>
              <VencidosCompetenciasBasicasPage />
            </SuspensedView>
          }
        />

        <Route
          path='por-vencer-control-confianza/*'
          element={
            <SuspensedView>
              <PorVencerControlConfianzaPage />
            </SuspensedView>
          }
        />

        <Route
          path='por-vencer-desempeno/*'
          element={
            <SuspensedView>
              <PorVencerDesempenoPage />
            </SuspensedView>
          }
        />

        <Route
          path='por-vencer-competencias-basicas/*'
          element={
            <SuspensedView>
              <PorVencerCompetenciasBasicasPage />
            </SuspensedView>
          }
        />
          
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='solicitudes/*' // Agrega el parámetro de ruta ":id"
          element={
            <SuspensedView>
              <SolicitudesPage />
            </SuspensedView>
          }
        />


        <Route
          path='perfil/*' // Agrega el parámetro de ruta ":id"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />





        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
