import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext, FormikValues } from "formik";
import { TipoEvaluacion, ResultadoEvaluacion } from '../modelEvaluacionDesempeno'; 
import Swal from 'sweetalert2';
import { useAuth } from "../../auth";
import { getInstanciasEvaluadoras } from "../api";
import { createEvaluacionDesempeno, updateEvaluacionDesempeno } from "../api";
import { useParams } from 'react-router-dom';

const Step3: React.FC = () => {
    const formik = useFormikContext();
    const [loading, setLoading] = useState(false);
    const [instanciasEvaluadoras, setInstanciasEvaluadoras] = useState([]);
    const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
    const [updating, setUpdating] = useState('d-none');
    const [step3Value, setStep3Value] = useState<number | undefined>(undefined);
    const { currentUser } = useAuth(); 
    let { solicitudId } = useParams();

    useEffect(() => {
        cargarInstanciasEvaluadoras();
    }, [currentUser]);

    const cargarInstanciasEvaluadoras = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasEvaluadoras(currentUser.id.toString());
                setInstanciasEvaluadoras(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias evaluadoras.", "error");
        } finally {
            setLoading(false);
        }
    };

    const handleStep3 = async () => {
        const errors = await formik.validateForm();
        const { values } = formik;
        if (Object.keys(errors).length === 0) {
            setLoading(true);
            console.log('Valores actuales del formulario:', values);
            const formValues = values as FormikValues;
    
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });

          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }

            if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }
            
            try {
                const ed = await createEvaluacionDesempeno(formData);
                Swal.fire({
                    title: 'Guardado',
                    text: 'Evaluación registrada exitosamente',
                    icon: 'success',
                });

                setSaving('d-none');
                setUpdating('card-footer d-flex justify-content-end py-6 px-9');
                if(ed){
                    setStep3Value(ed.evaluacionDesempeno.id);
                }
                console.log(ed);
    
            } catch (error) {
                console.error('Error al guardar la evaluación:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Error al guardar la evaluación',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        } else {
            Swal.fire({
                html: `
                <div class="spinner-container" style="overflow: hidden;">
                <i class="fas fa-spinner fa-spin"></i>
                <span>Se encontraron diversos errores</span> <ol>
                ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
                </ol></div>
                `,
                showConfirmButton: true,
                allowOutsideClick: true,
            });
        }
    };

    const handleStep3Update = async () => {
        const errors = await formik.validateForm();
        const { values } = formik;
        if (Object.keys(errors).length === 0) {
            setLoading(true);
            console.log('Valores actuales del formulario:', values);
            const formValues = values as FormikValues;
    
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });

          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }
          
            if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }
            
            try {
                const ed = await updateEvaluacionDesempeno(formData, step3Value as number);
                Swal.fire({
                    title: 'Guardado',
                    text: 'Evaluación actualizada exitosamente',
                    icon: 'success',
                });

                setSaving('d-none');
                setUpdating('card-footer d-flex justify-content-end py-6 px-9');
                if(ed){
                    setStep3Value(ed.evaluacionDesempeno.id);
                }
                console.log(ed);
    
            } catch (error) {
                console.error('Error al actualizar la evaluación:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Error al actualizar la evaluación',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        } else {
            Swal.fire({
                html: `
                <div class="spinner-container" style="overflow: hidden;">
                <i class="fas fa-spinner fa-spin"></i>
                <span>Se encontraron diversos errores</span> <ol>
                ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
                </ol></div>
                `,
                showConfirmButton: true,
                allowOutsideClick: true,
            });
        }
    };

    return (
        <div className="card mb-5 mb-xl-10">
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'>
                <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Evaluación de Desempeño</h3>
                </div>
            </div>
            <div className='card-body border-top p-9'>
            <div className='row mb-6'>
                <div className="col-lg-12">
                <label htmlFor="tipo" className="form-label required fw-bold fs-6">Tipo de Evaluación</label>
                <Field name="tipo_d" as="select" className="form-control">
                    {/* Opciones del enum TipoEvaluacion */}
                    {Object.entries(TipoEvaluacion).map(([key, value]) => (
                        <option key={key} value={value}>{value}</option>
                    ))}
                </Field>
                <ErrorMessage name="tipo_d" component="div" className="text-danger" />
                </div>

                <div className="col-lg-12">
                <label htmlFor="fechaEvaluacion" className="form-label required fw-bold fs-6">Fecha de Evaluación</label>
                <Field name="fechaEvaluacion" type="date" className="form-control"/>
                <ErrorMessage name="fechaEvaluacion" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="resultado_d" className="form-label required fw-bold fs-6">Resultado de la Evaluación</label>
                <Field name="resultado_d" as="select" className="form-control">
                    {/* Opciones del enum ResultadoEvaluacion */}
                    {Object.entries(ResultadoEvaluacion).map(([key, value]) => (
                        <option key={key} value={value}>{value}</option>
                    ))}
                </Field>
                <ErrorMessage name="resultado_d" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="calificacionObtenida_d" className="form-label required fw-bold fs-6">Calificación Obtenida</label>
                <Field name="calificacionObtenida_d" type="number" className="form-control"/>
                <ErrorMessage name="calificacionObtenida_d" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="observaciones_d" className="form-label required fw-bold fs-6">Observaciones</label>
                <Field name="observaciones_d" as="textarea" className="form-control"/>
                <ErrorMessage name="observaciones_d" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="idInstanciaEvaluadora" className="form-label required fw-bold fs-6">Instancia Evaluadora</label>
                <Field name="idInstanciaEvaluadora" as="select" className="form-control">
                    <option value="">Seleccione una instancia evaluadora</option>
                    {instanciasEvaluadoras.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <ErrorMessage name="idInstanciaEvaluadora" component="div" className="text-danger"/>
                </div>
            </div>
            </div>

          <div className={saving}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep3}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className={updating}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep3Update}>
              {!loading && 'Actualizar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
    );
};

export { Step3 };
