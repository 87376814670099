import React, { useState, useEffect, useMemo } from 'react';
import {useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import SolicitudesListBody from './SolicitudesListBody';
import SolicitudesListSearch from './SolicitudesListSearch';
import Pagination from 'react-bootstrap/Pagination';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import { ISolicitud } from '../solicitudes/model';
import { fetchIndex } from './api';
import Swal from 'sweetalert2';

const ModelList: React.FC = () => {
  let navigate = useNavigate();
  const intl = useIntl();
  let { convocatoriaId } = useParams(); // Cambia a userId según corresponda en tus rutas
  const [data, setData] = useState<ISolicitud[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [resetting, setResetting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [edad, setEdad] = useState('');
  const [servicioMilitar, setServicioMilitar] = useState('');
  const [escolaridad, setEscolaridad] = useState('');
  const [licencia, setLicencia] = useState('');
  const [sexo, setSexo] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const {currentUser} = useAuth();



  // Variable para almacenar el temporizador de búsqueda
  let searchTimer: NodeJS.Timeout;

  const fetchData = async (
    page: number,
    limit: number,
    searchValue: string,
    edad: string,
    servicioMilitar: string,
    escolaridad: string,
    licencia: string,
    sexo: string,
    estadoCivil: string
  ) => {
    setIsLoading(true);
    setError('');
    setIsSearching(true);
   // console.log('Ni hay convocatoria' + convocatoriaId)
    console.log(currentUser!.id)

    try {
      const { solicitudes, totalPages } = await fetchIndex(
        page,
        limit,
        searchValue,
        0,
        edad,
        servicioMilitar,
        escolaridad,
        licencia,
        sexo,
        estadoCivil,
        currentUser!.id,
      );
      setData(solicitudes);
      setTotalPages(totalPages);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Ocurrió un error inesperado');
      }
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  };


  useEffect(() => {
    fetchData(currentPage, limit, searchValue, edad, servicioMilitar, escolaridad, licencia, sexo, estadoCivil);
  }, [currentPage, limit]);


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(parseInt(event.target.value));
    setCurrentPage(1);
  };


  const handleSearch = () => {
    // Realizar la búsqueda solo si hay al menos un valor de búsqueda
    if (
      searchValue.trim() !== '' ||
      edad.trim() !== '' ||
      servicioMilitar.trim() !== '' ||
      escolaridad.trim() !== '' ||
      licencia.trim() !== '' ||
      sexo.trim() !== '' ||
      estadoCivil.trim() !== ''
    ) {
      // Si ya hay un temporizador en curso, lo limpiamos
      if (searchTimer) {
        clearTimeout(searchTimer);
      }

      // Creamos un nuevo temporizador para la búsqueda
      searchTimer = setTimeout(() => {
        fetchData(currentPage, limit, searchValue, edad, servicioMilitar, escolaridad, licencia, sexo, estadoCivil);
      }, 500); // Esperamos 500 milisegundos antes de realizar la búsqueda
    }
  };


  const handleReset = () => {
    setResetting(true);
    setSearchValue('');
    setEdad('');
    setServicioMilitar('');
    setEscolaridad('');
    setLicencia('');
    setSexo('');
    setEstadoCivil('');
  };


  useEffect(() => {
    if (resetting) {
      fetchData(currentPage, limit, searchValue, edad, servicioMilitar, escolaridad, licencia, sexo, estadoCivil);
      setResetting(false);
    }
  }, [resetting]);

  const memoizedData = useMemo(() => data, [data]);

  const handleDetail = (solicitudId: number) => {
    navigate(`/nomina/edit/${solicitudId}`);
  };

  const removeSolicitudById = (solicitudId: number) => {
    setData(prevData => prevData.filter(solicitud => solicitud.id !== solicitudId));
  };

  const handle = (solicitudId: number) => {
    navigate(`/control-confianza/${solicitudId}`); // Redirige a la página
  };

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className="card-header d-flex justify-content-between align-items-center" > {/* Ajuste en la altura del header */}
        <div className="d-flex align-items-center" style={{ width: '50%' }}>
          <h3>Por Vencer Control Confianza (Menos 1 año)</h3>
        </div>
        <div className="d-flex justify-content-end" style={{ width: '50%' }}>
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => navigate('/announcements/index')}>
            <i className="fa fa-arrow-left"></i> Regresar
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <SolicitudesListSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          handleReset={handleReset}
          edad={edad}
          setEdad={setEdad}
          servicioMilitar={servicioMilitar}
          setServicioMilitar={setServicioMilitar}
          escolaridad={escolaridad}
          setEscolaridad={setEscolaridad}
          licencia={licencia}
          setLicencia={setLicencia}
          sexo={sexo}
          setSexo={setSexo}
          estadoCivil={estadoCivil}
          setEstadoCivil={setEstadoCivil}
        />

        <div className='table-responsive mb-3'>
          <table className='table table-striped' id='table'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                <th>Nombre</th>
                <th>Corporación</th>
                <th>CUIP</th>
                <th>CUP</th>
                <th>Comisión </th>
                <th>Cargo </th>
                <th>No. Empleado</th>
                <th>Email</th>
                <th>CURP</th>
                <th>Registros</th>
              </tr>
            </thead>

            <SolicitudesListBody
              isLoading={isLoading || isSearching}
              data={memoizedData}
              handleDetail={handleDetail} // Pasa la función handleDetail al componente SolicitudesListBody
              onRemoveSolicitud={removeSolicitudById} 
              handle={handle} // Add handle prop
            />
          </table>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
          <div>
            <select value={limit} onChange={handleLimitChange} className='form-select form-select-sm'>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelList;
