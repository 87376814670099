import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Index } from './index'
import { Create } from './create'


const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Reclutamientos',
        path: '/reclutamientos',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ReclutamientoPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Reclutamientos</PageTitle>
                    <Index />
                </>
            }
        />
        <Route
            path='create/:solicitudId/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Reclutamientos</PageTitle>
                    <Create />
                </>
            }
        />

        <Route index element={<Navigate to='/reclutamientos/index' />} />
       

        
    </Routes>
)

export default ReclutamientoPage