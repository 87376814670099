
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { MotivoEstimulo, MotivoEstimuloFormValues } from './model';
import * as Yup from 'yup';
import { useAuth } from '../auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateMotivoEstimulo } from './apiEstimulos';

interface EditEstimulosModalProps {
    show: boolean;
    handleClose: () => void;
    motivoEstimulo:MotivoEstimulo | null;
    modificarMotivoEstimulo : any;
}

const EditEstimulo: React.FC<EditEstimulosModalProps> = ({ show, handleClose,motivoEstimulo,modificarMotivoEstimulo }) => {  
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    

  const motivoEstimuloSchema = Yup.object().shape({
    nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
  });
  
  //console.log(curso);

  let formik = useFormik<MotivoEstimuloFormValues>({
    initialValues:{
        nombre: '', // Asignar el nombre del curso actual o una cadena vacía si no hay curso
    },
    validationSchema: motivoEstimuloSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        if(currentUser){
          formData.append('user_id', currentUser.id.toString());
        }

        const nuevoMotivoEstimulo = await updateMotivoEstimulo(motivoEstimulo?.id || 1,formData);
        console.log(nuevoMotivoEstimulo);
        Swal.fire({
          title: 'Actualizado',
          text: 'Curso actualizado exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de cursos
        modificarMotivoEstimulo(nuevoMotivoEstimulo);
        //Cerramos el modal
        handleClose();
        navigate('/catalogos/index');
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error actualizando un curso',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
    if (motivoEstimulo) {
        // Actualizar los valores del formulario cuando cambie el motivo de estimulo
        formik.setValues({
            nombre: motivoEstimulo.nombre,
        });
    }
}, [motivoEstimulo]); 

  
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Estimulo</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
                <div className="mb-3 col-md-12 mt-3">
                <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
                <input type="text" className="form-control"  id="nombre" {...formik.getFieldProps('nombre')} />
                {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
                </div>
            </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>


        </Modal>
    );
};

export default EditEstimulo;