import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import { FormacionInicialModel } from "./model";
import { getModel } from "./api";
import { useAuth } from "../auth";
import { useParams } from "react-router-dom";
import Create from "./create";
import Edit from "./edit";
import Destroy from "./destroy";

const FormacionInicial: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedModel, setSelectedModel] = useState<FormacionInicialModel | null>(null);
    const [model, setData] = useState<FormacionInicialModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {currentUser} = useAuth();
    const { solicitudId } = useParams<{ solicitudId: string }>(); // Obtenemos el solicitudId de los parámetros

    useEffect(() => {
        const fetchModel = async () => {
          setIsLoading(true);
          setError('');
          try {
            const  {model}  = await getModel(solicitudId);
            setData(model);
            setIsLoading(false);
          } catch (error) {
            if (error instanceof Error) {
              setError(error.message); 
            } else {
              setError('Ocurrio un error inesperado'); 
            }
            setIsLoading(false);
          }
        };
    
        fetchModel();
        
      },[]);
      
    //Para poder cambiar el estatus en memoria de los cambios
  const crearModel = (nuevoModelo: FormacionInicialModel) => {
    setData(model => [...model, nuevoModelo]);
  };
  
  const actualizarModelo = (nuevoModelo: FormacionInicialModel) => {
    setData(model => {
        const modelExistenteIndex = model.findIndex(model => model.id === nuevoModelo.id);

        if (modelExistenteIndex !== -1) {
            const nuevoM = [...model];
            nuevoM[modelExistenteIndex] = nuevoModelo;
            return nuevoM;
        } else {
            return [...model, nuevoModelo];
        }
    });
};
const eliminarModelo = (modelEliminar: FormacionInicialModel) => {
  setData(model => {
      const nuevoM = model.filter(model => model.id !== modelEliminar.id);
      return nuevoM;
  });
};
  if (isLoading) {
    return <div>Por favor espere</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }


    const handleShowCreateModal = () => {
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleShowEditModal = (model:FormacionInicialModel) => {
        setSelectedModel(model);
        setShowEditModal(true);
    };
    const handleShowDestroyModal = (model:FormacionInicialModel) => {
        setSelectedModel(model);
        setShowDestroyModal(true);
    };
    const handleCloseModal = () => {
        setShowEditModal(false);
        setShowDestroyModal(false);
      };

    return (
        
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title m-0">Formación Inicial</h3>
                    <div>
                        <button className="btn btn-info" onClick={() => handleShowCreateModal()}>Nuevo <BsPlusCircle /></button>
                            <Create solicitud={solicitudId} show={showCreateModal} handleClose={handleCloseCreateModal} data={model} agregarNuevoModelo={crearModel}  />
                    </div>
            </div>
            <div className="card-body">
                <div className="row">
                {model.map((model) => (
                    <div className="col-md-4 mt-2">
                    <div className="card shadow-lg">
                        <div className="card-header bg-primary text-white align-items-center">
                        Tipo: {model.tipo} 
                        </div>
                        <div className="card-body align-items-center">
                            <p className="text-info text-center">Resultado: {model.resultado}</p>
                            <p className="text-info text-center">Fecha Inicio: {model.fecha_inicio}</p>
                            <p className="text-info text-center">Fecha Fin: {model.fecha_fin}</p>
                            <p className="text-info text-center">Duración: {model.duracion} {model.cantidad_duracion}</p>
                            <p className="text-info text-center">Estatus Evaluación: {model.estatus_evaluacion}</p>
                            <p className="text-info text-center">Observaciones: {model.observaciones}</p>
                            <p className="text-info text-center">Instancia Capacitadora: {model?.instancia_capacitadora?.nombre}</p>
                            <p className="text-info text-center">Instancia Responsable: {model?.instancia_responsable?.nombre}</p>
                   
                            <button className="btn btn-warning m-2" onClick={() => handleShowEditModal(model)}><BsPencil/></button>
                          <button className="btn btn-danger m-2" onClick={() => handleShowDestroyModal(model)}><BsTrash /></button>
                
                        </div>
                    </div>
                    </div>
                    ))}
                     <Edit modelo={selectedModel} show={showEditModal} handleClose={handleCloseModal} modificarModelo={actualizarModelo} solicitud={solicitudId}/>*
                    
                    <Destroy modelo={selectedModel} show={showDestroyModal} handleClose={handleCloseModal} eliminarModel={eliminarModelo}/>
                    
                </div>
                
            </div>
        </div>
    
            
      );
};

export default FormacionInicial;
    