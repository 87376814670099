import React, {FC,useState} from 'react'
import {Field, ErrorMessage} from 'formik'


const Step3: React.FC = () => {
  const [loading, setLoading] = useState(false)
  
  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Puesta a disposición</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
        
            

            <div className='row mb-6'>
              <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Nombre completo de quien recibe la puesta a disposición </label>

                  <Field
                    name='rName'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='rName' />
                    </div>
                </div>


              <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Cargo de quien recibe la puesta a disposición</label>

                  <Field
                    name='rPosition'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='rPosition' />
                    </div>
                </div>

              <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Adscripción de quien recibe la puesta a disposición</label>
                  <Field
                    name='rAdscription'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='rAdscription' />
                    </div>
                </div>
              </div>   
        </div>
            
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>

    
  )
}

export {Step3}