import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IAnnouncement } from './model'; // Asegúrate de importar el modelo correcto para los anuncios

import Swal from 'sweetalert2';
import AnnouncementListBody from './AnnouncementListBody'; // Asegúrate de importar el componente de cuerpo de la lista de anuncios
import AnnouncementListSearch from './AnnouncementListSearch'; // Asegúrate de importar el componente de búsqueda de la lista de anuncios
import Pagination from 'react-bootstrap/Pagination';
import { deleteAnnouncement, fetchAnnouncements } from './api';
import { useNavigate } from 'react-router-dom';


const AnnouncementList: React.FC = () => {
  let navigate = useNavigate();
  const intl = useIntl();

  const [data, setData] = useState<IAnnouncement[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchStatus, setSearchStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError('');

      try {
        const { announcements, totalPages } = await fetchAnnouncements(currentPage, limit, searchValue, searchStatus);
        setData(announcements);
        setTotalPages(totalPages);
        setIsLoading(false);
        console.log(announcements);

      } catch (error) {
        // Check if error is an instance of Error
        if (error instanceof Error) {
          setError(error.message); // Set error message if it's an Error object
        } else {
          setError('An unexpected error occurred'); // Set generic error message otherwise
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, limit, searchValue, searchStatus]);





  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(parseInt(event.target.value));
    setCurrentPage(1); // Resetear a la primera página al cambiar el límite
  };

  //[currentPage, limit, orderType, orderDirection, searchValue, searchStatus]);

  const handleDelete = async (announcementId: number) => {
    try {
      Swal.fire({
        title: '¿Está seguro de que desea eliminar esta convocatoria?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteAnnouncement(announcementId);
          Swal.fire({
            title: 'Eliminado con éxito',
            icon: 'success',
          });
          //fetchData();
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al eliminar el anuncio.',
        icon: 'error',
      });
    }
  };

  const handleEdit = (announcementId: number) => {

    navigate(`/announcements/edit/${announcementId}`);
  };


  const handleSearch = async () => {
    // setIsLoading(true);
    // setError(null);
    // setCurrentPage(1);

    // try {
    //   const config = await getTokenConfig();
    //   const queryString = `${process.env.REACT_APP_API_URL}/list-announcements?limit=${limit}&page=${currentPage}&search=${searchValue}&announcement_status_id=${searchStatus}`;

    //   axios
    //     .get(queryString, config)
    //     .then((response) => {
    //       setData(response.data.data.announcements);
    //       setIsLoading(false);
    //       setTotalPages(Math.ceil(response.data.data.total / limit));
    //     })
    //     .catch((error) => {
    //       setError(error);
    //       setIsLoading(false);
    //     });
    // } catch (err: any) {
    //   setError(err);
    //   setIsLoading(false);
    // }
  };

  const handleReset = async () => {
    setSearchValue('');
    setSearchStatus('');
  };

  const memoizedData = useMemo(() => data, [data]);

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="card-title m-0">Convocatorias</h3>
        <div>

          <Link to={'/announcements/create'} className='btn btn-primary add-button rounded-pill'>
            Agregar convocatoria
          </Link>
        </div>
      </div>
      <div className='card-body py-3'>


        <AnnouncementListSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchStatus={searchStatus}
          setSearchStatus={setSearchStatus}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <div className='table-responsive mb-3'>
          <table className='table table-striped' id='table'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>

                <th className='min-w-50px'>ID</th>
                <th className='min-w-100px'>Título</th>
                <th className='min-w-200px'>Descripción</th>
                <th className='min-w-150px'>Fecha de Inicio</th>
                <th className='min-w-150px'>Fecha de Fin</th>
                <th className='min-w-150px'>Ubicación</th>
                <th className='min-w-200px'>Referencia de Contacto</th>
                <th>Acción</th>
              </tr>
            </thead>
            <AnnouncementListBody
              data={memoizedData}
              handleDelete={handleDelete}
              handleEdit={handleEdit} // Agregar la función de editar

            />
          </table>

        </div>

        <div className='d-flex justify-content-between align-items-center'>
          <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>


          <div>
            <select value={limit} onChange={handleLimitChange} className='form-select form-select-sm'>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementList;
