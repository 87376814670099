import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsAwardFill, BsCardChecklist, BsCheck, BsEye, BsFillShieldLockFill, BsFlagFill, BsPencil, BsPeople, BsPersonBoundingBox, BsPersonCheck, BsTrash, BsX } from 'react-icons/bs';
import { FaHandshake } from "react-icons/fa";
import { MdOutlineWorkHistory } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { GiWhiteBook } from "react-icons/gi";
import { FaPencil } from "react-icons/fa6";

import Swal from 'sweetalert2';
import { ISolicitud } from '../solicitudes/model';

interface SolicitudesListBodyProps {
  data: ISolicitud[];
  isLoading: boolean;
  handleDetail: (solicitudId: number) => void; // Agrega handleDetail como prop
  handleFormacionContinua: (solicitudId: number) => void; 
  handleEstimulos: (solicitudId: number) => void; 
  handleSanciones: (solicitudId: number) => void; 
  handleCorrecciones: (solicitudId: number) => void; 
  handleFormacionInicial: (solicitudId: number) => void;
  handleControlConfianza: (solicitudId: number) => void;
  handleDesempeno: (solicitudId: number) => void;
  handleCompetencias: (solicitudId: number) => void;
  onRemoveSolicitud: (solicitudId: number) => void; // Nueva prop
}
interface StatusMessages {
  [key: number]: string;
}

  const SolicitudesListBody: React.FC<SolicitudesListBodyProps> = ({ data, isLoading, handleDetail,handleFormacionContinua,handleEstimulos,handleSanciones, handleCorrecciones, onRemoveSolicitud, 
    handleFormacionInicial, handleControlConfianza, handleDesempeno, handleCompetencias}) => {

  const statusMessages: StatusMessages = {
    3: 'No calificado',
    4: 'Rechazado',
    6: 'Reclutamiento',
    7: 'Selección'
  };

  return (
    <>

      <tbody>
        {isLoading ? ( // Mostrar mensaje de carga mientras se realiza la búsqueda
          <tr>
            <td colSpan={5}>Cargando...</td>
          </tr>
        ) : (
          data.map((solicitud) => (
            <tr key={solicitud.id}>
              <td>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Formación Continua
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleFormacionContinua(solicitud.id)}
                    >
                      <GiWhiteBook  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Estimulos
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleEstimulos(solicitud.id)}
                    >
                      <BsAwardFill  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Sanciones
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleSanciones(solicitud.id)}
                    >
                      <BsFlagFill  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Correcciones Disciplinarias
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleCorrecciones(solicitud.id)}
                    >
                      <BsFillShieldLockFill  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>

                  </div>

                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Formación Inicial
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleFormacionInicial(solicitud.id)}
                    >
                      <FaPencil  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Evaluación de Control de Confianza.
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleControlConfianza(solicitud.id)}
                    >
                      <FaHandshake  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Evaluación de Desempeño
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleDesempeno(solicitud.id)}
                    >
                      <MdOutlineWorkHistory color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Evaluación de Competencias Básicas. 
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleCompetencias(solicitud.id)}
                    >
                      <GoChecklist  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>

                </div>
                  
              </td>
              <td>{solicitud.id}</td>
              <td>{solicitud.nombre} {solicitud.primer_apellido} {solicitud.segundo_apellido}</td>
              <td>{solicitud?.curp}</td>
              <td>{solicitud.fecha_solicitud}</td>
              <td>{solicitud.municipio_residencia?.nombre}</td>

              <td>{solicitud.email}</td>

              <td>
                <ButtonGroup aria-label="Actions">
                  <Button variant="info" onClick={() => handleDetail(solicitud.id)}> {/* Agrega onClick para llamar a handleDetail */}
                    <BsEye /> {/* Cambia el icono por uno que represente "Ver detalle" */}
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

export default SolicitudesListBody;
