import axios, { AxiosResponse } from "axios";
import { Sancion } from "./model";

export const getSanciones = async (): 
Promise<{ sanciones: Sancion[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/tipo-sancions`;
        const response = await axios.get(queryString);
        const sanciones = response.data.data;
        return  {sanciones};
    }catch (error) {
        throw error;
    }
 }


 export const createSancion = async (formData: FormData): Promise<Sancion> => {
    try {
      const response: AxiosResponse<{ message: string; tipoSancion: Sancion }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/tipo-sancions`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando sanción');
      }
      return response.data.tipoSancion;
    } catch (error) {
      throw error;
    }
  };

  export const updateSancion = async (
    sancionId:number,
    formData: FormData
   ): Promise<Sancion> => {
    try {
      console.log(sancionId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; tipoSancion: Sancion }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/tipo-sancions/${sancionId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 201) {
        throw new Error('Error actualizando sanción');
      }
      return response.data.tipoSancion;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };

  export const deleteSancion = async (
    sancionId:number,
   ): Promise<Sancion> => {
    try {
      console.log(sancionId);
      const response: AxiosResponse<{ message: string; tipoSancion: Sancion }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/tipo-sancions/${sancionId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error eliminando sancion');
      }
      return response.data.tipoSancion;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };