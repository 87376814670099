import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import UserList from './UserList'
import Create from './create'
import Edit from './edit'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Usuarios',
        path: '/user',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const UserPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Convocatorias</PageTitle>
                    <UserList />
                </>
            }
        />
        <Route
            path='create'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Nueva convocatoria</PageTitle>
                    <Create />
                </>
            }
        />

        <Route
            path='edit/:userId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Editar convocatoria</PageTitle>
                    <Edit />
                </>
            }
        />

        <Route index element={<Navigate to='/user/index' />} />

    </Routes>
)

export default UserPage
