import { ErrorMessage, Field, useFormikContext, FormikValues } from "formik";
import { useState, useEffect  } from "react";
import Swal from "sweetalert2";
import { getInstanciasEvaluadoras, getInstanciasResponsables } from "../api"; 
import { createCompetenciasBasicas, updateCompetenciasBasicas } from "../api";
import { useAuth } from "../../auth";
import { useParams } from 'react-router-dom';

const Step1: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [instancias, setInstancias] = useState([]);// Instancias evaluadoras
    const [instanciasr, setInstanciasr] = useState([]);// Instancias responsables
    const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
    const [updating, setUpdating] = useState('d-none');
    const [step1Value, setStep1Value] = useState<number | undefined>(undefined);
    const formik = useFormikContext();
    const { currentUser } = useAuth(); 
    let { solicitudId } = useParams();

    useEffect(() => {
      cargarInstanciasEvaluadoras();
      cargarInstanciasResponsables();
    }, [currentUser]);

      const cargarInstanciasEvaluadoras = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasEvaluadoras(currentUser.id.toString());
                setInstancias(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias evaluadoras.", "error");
        } finally {
            setLoading(false);
        }
    };

    const cargarInstanciasResponsables = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasResponsables(currentUser.id.toString());
                setInstanciasr(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias responsables.", "error");
        } finally {
            setLoading(false);
        }
    }
    
    const handleStep1 = async () => {
        const { values } = formik; // Obtener los valores actuales del formulario
        const errors = await formik.validateForm(); // Validar el formulario
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, ejecutar la acción
        setLoading(true);
          console.log('Valores actuales del formulario:', values);
          // Asegurar que values sea de tipo FormikValues
          const formValues = values as FormikValues;
    
          // Crear un nuevo objeto FormData
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });
          
          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }

            if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }

    
          try {
            const cf = await createCompetenciasBasicas(formData);
            Swal.fire({
                title: 'Guardado',
                text: 'Registrado exitosamente',
                icon: 'success',
            });
            setSaving('d-none');
            setUpdating('card-footer d-flex justify-content-end py-6 px-9');
            if(cf){
              setStep1Value(cf.evaluacionCompetencia.id);
            }
            console.log(cf);
    
        } catch (error) {
            console.error('Error al crear:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error al crear',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
        } else {
          // Mostrar los errores de validación
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
            showConfirmButton: true,
            allowOutsideClick: true,
    
    
          });
        }
        //setLoading(false);
      };

      const handleStep1update = async () => {
        const { values } = formik; // Obtener los valores actuales del formulario
        const errors = await formik.validateForm(); // Validar el formulario
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, ejecutar la acción
        setLoading(true);
          console.log('Valores actuales del formulario:', values);
          // Asegurar que values sea de tipo FormikValues
          const formValues = values as FormikValues;
    
          // Crear un nuevo objeto FormData
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });
          
          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }
          
          
          if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }

            
          try {  
            const cf = await updateCompetenciasBasicas(formData, step1Value as number);
            Swal.fire({
                title: 'Actualizado',
                text: 'Actualizado exitosamente',
                icon: 'success',
            });
            setSaving('d-none');
            setUpdating('card-footer d-flex justify-content-end py-6 px-9');
            console.log(cf.evaluacionCompetencia.id);
            if(cf){
              setStep1Value(cf.evaluacionCompetencia.id);
            }
            
    
        } catch (error) {
            console.error('Error al actualizar:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error al actualizar',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
        } else {
          // Mostrar los errores de validación
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
            showConfirmButton: true,
            allowOutsideClick: true,
    
    
          });
        }
        //setLoading(false);
      };

  interface CustomSweetAlertOptions{
    onOpen?: () => void
  }

    return (
        <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Evaluación de Competencias Básicas</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
            <div className='row mb-6'>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Fecha de evaluación</label>
            <Field name="fecha_de_evaluacion" type="date" className="form-control" />
            <ErrorMessage name="fecha_de_evaluacion" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Calificación</label>
            <Field name="calificacion" as="select" className="form-control">
              <option value="">Seleccione una calificación</option>
              <option value="Aprobado">Aprobado</option>
              <option value="No Aprobado">No Aprobado</option>
              <option value="En Proceso">En Proceso</option>
              <option value="Sin Resultado">Sin Resultado</option>
            </Field>
            <ErrorMessage name="calificacion" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Resultado</label>      
            <Field name="resultado" type="text" className="form-control" />
            <ErrorMessage name="resultado" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Calificación obtenida</label>
            <Field name="calificacion_obtenida" type="number" className="form-control" placeholder="Calificación obtenida" />
            <ErrorMessage name="calificacion_obtenida" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Fecha de reevaluación</label>
            <Field name="fecha_de_reevaluacion" type="date" className="form-control" />
            <ErrorMessage name="fecha_de_reevaluacion" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Observaciones</label>
            <Field name="observaciones" as="textarea" className="form-control" placeholder="Observaciones" />
            <ErrorMessage name="observaciones" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Instancia responsable</label>
            <Field name="id_instancia_responsable" as="select" className="form-control">
              <option value="">Seleccione una instancia responsable</option>
              {instanciasr && instanciasr.map((instancia: any) => (
                <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
              ))}
            </Field>
            <ErrorMessage name="id_instancia_responsable" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Instancia evaluadora</label>
            <Field name="id_instancia_evaluadora" as="select" className="form-control">
              <option value="">Seleccione una instancia evaluadora</option>
              {(instancias).map((instancia: any) => (
                <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
              ))}
            </Field>
            <ErrorMessage name="id_instancia_evaluadora" component="div" className="text-danger" />
            </div>



            </div>   
          </div>

          <div className={saving}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className={updating}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1update}>
              {!loading && 'Actualizar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>
    );
}

export {Step1}
