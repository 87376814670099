import axios, { AxiosResponse } from "axios";
import { ISolicitud } from "../solicitudes/model";

export const fetchIndex = async (
    currentPage: number,
    limit: number,
    searchValue: string,
    convocatoriaId: number | null,
    edad: string,
    servicioMilitar: string,
    escolaridad: string,
    licencia: string,
    sexo: string,
    estadoCivil: string,
    user: number
  ): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
    try {
      let queryString = `${process.env.REACT_APP_API_DEV_URL}/pvcompetencias?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}&user=${user}`;
      console.log(convocatoriaId);
      // Añadir filtros adicionales al queryString si están presentes
      if (edad) queryString += `&edad=${edad}`;
      if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
      if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
      if (licencia) queryString += `&licencia=${licencia}`;
      if (sexo) queryString += `&sexo=${sexo}`;
      if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;
  
      const response = await axios.get(queryString);
      const solicitudes = response.data.data;
      const totalPages = response.data.last_page;
  
      return { solicitudes, totalPages };
    } catch (error) {
      throw error;
    }
  };