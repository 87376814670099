import axios, { AxiosResponse } from "axios";
import { Curso, CursoFormValues } from "./model";


export const getCursos = async (user:any): 
Promise<{ cursos: Curso[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/cursos?municipio=${user.id_municipio}`;
        const response = await axios.get(queryString);
        //console.log('La respuesta'+response.data);
        const cursos = response.data.data;
        console.log(user);
       // console.log(cursos)
        return  {cursos};
    }catch (error) {
        throw error;
    }
 }


 export const createCurso = async (formData: FormData): Promise<Curso> => {
    try {
      const response: AxiosResponse<{ message: string; curso: Curso }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/cursos`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando curso');
      }
      return response.data.curso;
    } catch (error) {
      throw error;
    }
  };

  
 export const updateCurso = async (
  cursoId:number,
  formData: FormData
 ): Promise<Curso> => {
  try {
    console.log(cursoId);
    formData.append("_method", "PUT");
    const response: AxiosResponse<{ message: string; curso: Curso }> = await axios.post(
      `${process.env.REACT_APP_API_DEV_URL}/cursos/${cursoId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response.status !== 201) {
      throw new Error('Error actualizando curso');
    }
    return response.data.curso;
    //console.log(response);
  } catch (error) {
    throw error;
  }
};

export const deleteCurso = async (
  cursoId:number,
 ): Promise<Curso> => {
  try {
    console.log(cursoId);
    const response: AxiosResponse<{ message: string; curso: Curso }> = await axios.delete(
      `${process.env.REACT_APP_API_DEV_URL}/cursos/${cursoId}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    if (response.status !== 201) {
      throw new Error('Error eliminando curso');
    }
    return response.data.curso;
    //console.log(response);
  } catch (error) {
    throw error;
  }
};

