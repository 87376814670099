import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import CreateCorreccion from "./createCorrecciones"; 
import EditCorreccion from "./editCorrecciones"; 
import DestroyCorreccion from "./destroyCorrecciones"; 
import { Correccion } from "./model"; 
import { getCorrecciones } from "./apiCorrecciones";
import { useAuth } from "../auth";

const Correcciones = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedCorreccion, setSelectedCorreccion] = useState<Correccion | null>(null);
    const [data, setData] = useState<Correccion[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {currentUser} = useAuth();

    useEffect(() => {
        const fetchCorrecciones = async () => {
            setIsLoading(true);
            setError('');
            try {
                const {correccion} = await getCorrecciones();
                setData(correccion);
                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('Ocurrió un error inesperado');
                }
                setIsLoading(false);
            }
        };

        fetchCorrecciones();
        
    }, [currentUser]);

    const crearCorreccion = (nuevaCorreccion: Correccion) => {
        setData(correcciones => [...correcciones, nuevaCorreccion]);
    };

    const actualizarCorreccion = (nuevaCorreccion: Correccion) => {
        setData(correcciones => {
            const correccionExistenteIndex = correcciones.findIndex(c => c.id === nuevaCorreccion.id);
            if (correccionExistenteIndex !== -1) {
                const nuevasCorrecciones = [...correcciones];
                nuevasCorrecciones[correccionExistenteIndex] = nuevaCorreccion;
                return nuevasCorrecciones;
            } else {
                return [...correcciones, nuevaCorreccion];
            }
        });
    };

    const eliminarCorreccion = (correccionEliminar: Correccion) => {
        setData(correcciones => correcciones.filter(c => c.id !== correccionEliminar.id));
    };

    if (isLoading) {
        return <div>Por favor espere...</div>;
    }

    if (error) {
        return <div>Ocurrió un error: {error}</div>;
    }

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="card-title m-0">Correcciones</h3>
                <div>
                    <Button onClick={() => setShowCreateModal(true)}>Nuevo <BsPlusCircle /></Button>
                    <CreateCorreccion show={showCreateModal} handleClose={() => setShowCreateModal(false)} data={data} agregarNuevaCorreccion={crearCorreccion} />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    {data.map((correccion) => (
                        <div className="col-md-4 mt-2" key={correccion.id}>
                            <div className="card shadow-lg">
                                <div className="card-body align-items-center">
                                    <p className="text-bold text-center">{correccion.nombre}</p>
                                    <Button variant="warning" onClick={() => {
                                        setSelectedCorreccion(correccion);
                                        setShowEditModal(true);
                                    }}><BsPencil /></Button>
                                    <Button variant="danger" onClick={() => {
                                        setSelectedCorreccion(correccion);
                                        setShowDestroyModal(true);
                                    }}><BsTrash /></Button>
                                </div>
                            </div>
                        </div>
                    ))}
                    {selectedCorreccion && <EditCorreccion correccion={selectedCorreccion} show={showEditModal} handleClose={() => setShowEditModal(false)} modificarCorreccion={actualizarCorreccion} />}
                    {selectedCorreccion && <DestroyCorreccion correccion={selectedCorreccion} show={showDestroyModal} handleClose={() => setShowDestroyModal(false)} eliminarCorreccion={eliminarCorreccion} />}
                </div>
            </div>
        </div>
    );
};

export default Correcciones;