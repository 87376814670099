import axios, { AxiosResponse } from "axios";
import { SancionModel } from "./model";


export const getSancion = async (solicitud:any): 
Promise<{ sanciones: SancionModel[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/sanciones?solicitud=${solicitud}`;
        const response = await axios.get(queryString);
        console.log(response.data);
        const sanciones = response.data.data;
       // console.log(sanciones)
        return  {sanciones};
    }catch (error) {
        throw error;
    }
 }


 export const createSancion = async (formData: FormData): Promise<SancionModel> => {
    try {
      const response: AxiosResponse<{ message: string; sanciones: SancionModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/sanciones`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando sanción');
      }
      console.log(response.data.sanciones)
      return response.data.sanciones;
    } catch (error) {
      throw error;
    }
  };


  export const updateSancion = async (
    sancionId:number,
    formData: FormData
   ): Promise<SancionModel> => {
    try {
      console.log(sancionId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; sanciones: SancionModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/sanciones/${sancionId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 201) {
        throw new Error('Error actualizando sanción');
      }
      return response.data.sanciones;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };
  export const deleteSancion = async (
    sancionId:number,
   ): Promise<SancionModel> => {
    try {
      console.log(sancionId);
      const response: AxiosResponse<{ message: string; sanciones: SancionModel }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/sanciones/${sancionId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error eliminando sanción');
      }
      return response.data.sanciones;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };
