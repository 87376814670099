
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ControlConfianzaModel, ControlConfianzaFormValues } from './model';
import * as Yup from 'yup';
import { useAuth } from '../auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FormikValues, useFormik, Field, ErrorMessage, FormikProvider  } from 'formik';
import Swal from 'sweetalert2';
import { updateModel } from './api';
import {getInstanciasEvaluadoras } from '../selecciones/api';

interface EditModalProps {
    show: boolean;
    handleClose: () => void;
    modelo:ControlConfianzaModel | null;
    modificarModelo : any;
    solicitud:any;
}

const Edit: React.FC<EditModalProps> = ({ show, handleClose,modelo,modificarModelo,solicitud }) => {  
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    //Para los select de catalogos
    const [instancias, setInstancias] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');
    

    const modelSchema = Yup.object().shape({
        fecha_evaluacion: Yup.date().max(new Date(), 'La fecha de evaluación no debe ser futura').required('Por favor ingrese la fecha de evaluación'),
        motivo_evaluacion: Yup.string().max(255).required('Por favor ingrese el motivo de evaluación'),
        resultado: Yup.string().max(255).required('Por favor ingrese el resultado de la evaluación'),
        observaciones: Yup.string().max(255).required('Por favor ingrese las observaciones'),
        estatus_observaciones: Yup.string().max(255).required('Por favor ingrese el estatus de las observaciones'),
        fecha_reexaminacion: Yup.date(),
        estatus_evaluacion: Yup.string().max(255).required('Por favor ingrese el estatus de la evaluación'),
        motivo_incompleta: Yup.string().when('estatus_evaluacion', {
            is: 'Incompleta',
            then: Yup.string().required('Por favor ingrese el motivo incompleto')
        }),
        id_instancia_evaluadora: Yup.number().required('Por favor ingrese la instancia evaluadora').min(1, 'Por favor ingrese la instancia evaluadora'),
  });


  let formik = useFormik<ControlConfianzaFormValues>({
    initialValues: {},
    validationSchema: modelSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevoModelo = await updateModel(modelo?.id || 1,formData);
        console.log(nuevoModelo);
        Swal.fire({
          title: 'Actualizado',
          text: 'Actualizada exitosamente',
          icon: 'success',
        });
        
        modificarModelo(nuevoModelo);
        //Cerramos el modal
        handleClose();
        navigate('/control-confianza/'+ solicitud);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error actualizando.',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
      const cargarInstancias = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasEvaluadoras(currentUser.id.toString());
                setInstancias(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias capacitadoras.", "error");
        } finally {
            setLoading(false);
        }
    };  

    cargarInstancias();

    
  },[currentUser]);

  useEffect(() => {
    if (modelo) {
        formik.setValues({
            fecha_evaluacion: modelo.fecha_evaluacion,
            motivo_evaluacion: modelo.motivo_evaluacion,
            resultado: modelo.resultado,
            observaciones: modelo.observaciones,
            estatus_observaciones: modelo.estatus_observaciones,
            fecha_reexaminacion: modelo.fecha_reexaminacion,
            estatus_evaluacion: modelo.estatus_evaluacion,
            motivo_incompleta: modelo.motivo_incompleta,
            id_instancia_evaluadora: modelo.id_instancia_evaluadora,
        });
    }
}, [modelo]); 

    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <FormikProvider value={formik}>
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de evaluación</label>
                <Field
                    name='fecha_evaluacion'
                    type='date'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_evaluacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Motivo de evaluación</label>
                <Field
                    name='motivo_evaluacion'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Nuevo ingreso'>Nuevo ingreso</option>
                    <option value='Reingreso'>Reingreso</option>
                    <option value='Permanencia'>Permanencia</option>
                    <option value='Promoción'>Promoción</option>
                    <option value='Observación'>Observación</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='motivo_evaluacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Resultado de la evaluación</label>
                <Field
                    name='resultado'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Aprobado'>Aprobado</option>
                    <option value='No aprobado'>No aprobado</option>
                    <option value='En proceso'>En proceso</option>
                    <option value='Sin resultado'>Sin resultado</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='resultado' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Observaciones</label>
                <Field
                    name='observaciones'
                    type='text'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='observaciones' />
                </div>

            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Estatus de las observaciones</label>
                <Field
                    name='estatus_observaciones'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Cumplidas'>Cumplidas</option>
                    <option value='En proceso'>En proceso</option>
                    <option value='Sin cumplir'>Sin cumplir</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='estatus_observaciones' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de reexaminación</label>
                <Field
                    name='fecha_reexaminacion'
                    type='date'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_reexaminacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Estatus de la evaluación</label>
                <Field
                    name='estatus_evaluacion'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Programada'>Programada</option>
                    <option value='En proceso'>En proceso</option>
                    <option value='Finalizada'>Finalizada</option>
                    <option value='Incompleta'>Incompleta</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='estatus_evaluacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Motivo incompleto</label>
                <Field
                    name='motivo_incompleta'
                    type='text'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='motivo_incompleta' />
                </div>

            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Instancia evaluadora</label>
                <Field
                    name='id_instancia_evaluadora'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value=''>Selecciona una instancia</option> {/* Opción por defecto */}
                    {instancias.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='id_instancia_evaluadora' />
                </div>
            </div>
            </FormikProvider>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>


        </Modal>
    );
};

export default Edit;