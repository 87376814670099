import axios,{ AxiosResponse } from "axios";
import { ISolicitud, RespuestaActualizarEstado } from "./model";

export const fetchSolicitudesAspirantes = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-aspirantes?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}`;

    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};

export const fetchSolicitudesAprobados = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-aprobados?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}`;

    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};

export const fetchSolicitudesNoCalificados = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-no-calificados?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}`;

    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};




export const getSolicitud = async (convocatoriaId: number): Promise<ISolicitud> => {
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes/${convocatoriaId}`;
    const response: AxiosResponse<{ data: ISolicitud }> = await axios.get<{ data: ISolicitud }>(queryString);

    if (response.status !== 200) {
      throw new Error('Error fetching solicitud');
    }

    return response.data.data; // Devolver solo los datos de la respuesta
  } catch (error) {
    throw error;
  }
};


export const actualizarEstadoSolicitud = async (solicitudId: number, nuevoEstado: number): Promise<RespuestaActualizarEstado> => {
  try {
    const response = await axios.post<RespuestaActualizarEstado>(`${process.env.REACT_APP_API_DEV_URL}/solicitudes/${solicitudId}/estado`, {
      nuevoEstado,
    });
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el estado de la solicitud:', error);
    throw error;
  }
};

export const fetchSolicitudesSeleccion = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-seleccion?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}`;

    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};

export const fetchActivos = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number | null,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string,
  user: number
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-activos?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}&user=${user}`;
    console.log('Hola');
    console.log(convocatoriaId);
    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};

export const fetchReclutamientoSeleccion = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-reclutamiento?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}`;

    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};

export const fetchSolicitudesNombramiento = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-nombramiento?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}`;

    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};

export const fetchNomina = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  convocatoriaId: number | null,
  edad: string,
  servicioMilitar: string,
  escolaridad: string,
  licencia: string,
  sexo: string,
  estadoCivil: string,
  user: number
): Promise<{ solicitudes: ISolicitud[], totalPages: number }> => {
  try {
    let queryString = `${process.env.REACT_APP_API_DEV_URL}/solicitudes-nomina?page=${currentPage}&per_page=${limit}&convocatoriaId=${convocatoriaId}&search=${searchValue}&user=${user}`;
    console.log('Hola');
    console.log(convocatoriaId);
    // Añadir filtros adicionales al queryString si están presentes
    if (edad) queryString += `&edad=${edad}`;
    if (servicioMilitar) queryString += `&servicio_militar=${servicioMilitar}`;
    if (escolaridad) queryString += `&escolaridad=${escolaridad}`;
    if (licencia) queryString += `&licencia=${licencia}`;
    if (sexo) queryString += `&sexo=${sexo}`;
    if (estadoCivil) queryString += `&estado_civil=${estadoCivil}`;

    const response = await axios.get(queryString);
    const solicitudes = response.data.data;
    const totalPages = response.data.last_page;

    return { solicitudes, totalPages };
  } catch (error) {
    throw error;
  }
};