import { useEffect, useMemo, useState } from "react";
import Correcciones from "./correcciones";
import Cursos from "./cursos";
import Estimulos from "./estimulos";
import Instancias from "./instancias";
import Sanciones from "./sanciones";
import { createCurso, getCursos } from "./apiCursos";
import { Curso } from "./model";
import { useIntl } from "react-intl";

const Catalogos: React.FC = () => {
    return (
        <div className="card bg-gray">
            <div className="card-body">
                <Cursos></Cursos>
                <Instancias></Instancias>
                <Estimulos></Estimulos>
                <Correcciones></Correcciones>
                <Sanciones></Sanciones>
            </div>
        </div>        
      );
};
    
    


export default Catalogos;
