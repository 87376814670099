import axios, { AxiosResponse } from "axios";
import { CorreccionDisciplinariaModel } from "./model";


export const getCorreccionDisciplinaria = async (solicitud:any): 
Promise<{ correccionesDisciplinarias: CorreccionDisciplinariaModel[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/correcciones-disciplinarias?solicitud=${solicitud}`;
        const response = await axios.get(queryString);
        console.log(response.data);
        const correccionesDisciplinarias = response.data.data;
       // console.log(correccionesDisciplinarias)
        return  {correccionesDisciplinarias};
    }catch (error) {
        throw error;
    }
 }


 export const createCorreccionDisciplinaria = async (formData: FormData): Promise<CorreccionDisciplinariaModel> => {
    try {
      const response: AxiosResponse<{ message: string; correccionesDisciplinarias: CorreccionDisciplinariaModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/correcciones-disciplinarias`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando corrección disciplinaria');
      }
      console.log(response.data.correccionesDisciplinarias)
      return response.data.correccionesDisciplinarias;
    } catch (error) {
      throw error;
    }
  };


  export const updateCorreccionDisciplinaria = async (
    correccionDisciplinariaId:number,
    formData: FormData
   ): Promise<CorreccionDisciplinariaModel> => {
    try {
      console.log(correccionDisciplinariaId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; correccionesDisciplinarias: CorreccionDisciplinariaModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/correcciones-disciplinarias/${correccionDisciplinariaId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 201) {
        throw new Error('Error actualizando corrección disciplinaria');
      }
      return response.data.correccionesDisciplinarias;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };
  export const deleteCorreccionDisciplinaria = async (
    correccionDisciplinariaId:number,
   ): Promise<CorreccionDisciplinariaModel> => {
    try {
      console.log(correccionDisciplinariaId);
      const response: AxiosResponse<{ message: string; correccionesDisciplinarias: CorreccionDisciplinariaModel }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/correcciones-disciplinarias/${correccionDisciplinariaId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error eliminando corrección disciplinaria');
      }
      return response.data.correccionesDisciplinarias;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };
