import { Navigate, Routes, Route } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Index } from './index';
import { Create } from './create';

const seleccionBreadCrumbs: Array<PageLink> = [
    {
        title: 'Selecciones',
        path: '/selecciones',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
];

const SeleccionesPage = () => (
    <Routes>
        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={seleccionBreadCrumbs}>Selecciones</PageTitle>
                    <Index />
                </>
            }
        />
        <Route
            path='create/:solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={seleccionBreadCrumbs}>Selecciones</PageTitle>
                    <Create />
                </>
            }
        />

        <Route index element={<Navigate to='/selecciones/index' />} />
    </Routes>
);

export default SeleccionesPage;
