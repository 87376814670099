import axios, { AxiosResponse } from "axios";
import { Elemento, Estado, Municipio, Nacionalidad } from "./model";

export const createElemento = async (formData: FormData): Promise<Elemento> => {
    try {
      const response: AxiosResponse<{ message: string; elementos: Elemento }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/elementos`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando elemento');
      }
      return response.data.elementos;
    } catch (error) {
      throw error;
    }
  };


export const getEstados = async (): 
Promise<{ estados: Estado[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/estados`;
        const response = await axios.get(queryString);
        //console.log('La respuesta'+response.data);
        const estados = response.data.data;
       // console.log(estados)
        return  {estados};
    }catch (error) {
        throw error;
    }
 }
 
export const getMunicipios = async (estadoId:any): 
Promise<{ municipios: Municipio[],municipiosActuales: Municipio[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/municipios?estadoId=${estadoId}`;
        const response = await axios.get(queryString);
        //console.log('La respuesta'+response.data);
        const municipios = response.data.data;
        const municipiosActuales = response.data.data;
       // console.log(municipios)
        return  {municipios,municipiosActuales};
    }catch (error) {
        throw error;
    }
 }

 export const getNacionalidades = async (): 
Promise<{ nacionalidades: Nacionalidad[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/nacionalidades`;
        const response = await axios.get(queryString);
        //console.log('La respuesta'+response.data);
        const nacionalidades = response.data.data;
       // console.log(nacionalidades)
        return  {nacionalidades};
    }catch (error) {
        throw error;
    }
 }


 export const getMunicipiosCorporaciones = async (id_user: any):
 Promise<{ municipios: Municipio[]}> => {
   try {
       const response = await axios.get(`${process.env.REACT_APP_API_DEV_URL}/municipios/${id_user}`);
       const municipios = response.data.municipios; // Accede a la propiedad 'municipios' del objeto de respuesta
       console.log(municipios)
       return { municipios };
   } catch (error) {
       console.error("Error al cargar los municipios:", error);
       throw error;
   }
 }
