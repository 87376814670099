import axios, { AxiosResponse } from "axios";
import { MotivoEstimulo } from "./model";

export const getMotivoEstimulos = async (): 
Promise<{ motivoEstimulos: MotivoEstimulo[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/motivo-estimulos`;
        const response = await axios.get(queryString);
        const motivoEstimulos = response.data.data;
        return  {motivoEstimulos};
    }catch (error) {
        throw error;
    }
 }

 export const createMotivoEstimulo = async (formData: FormData): Promise<MotivoEstimulo> => {
    try {
      const response: AxiosResponse<{ message: string; motivoEstimulos: MotivoEstimulo }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/motivo-estimulos`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando motivo de estimulo');
      }

      return response.data.motivoEstimulos;
    } catch (error) {
      throw error;
    }
  };

  export const updateMotivoEstimulo = async (
    motivoEstimuloId:number,
    formData: FormData
   ): Promise<MotivoEstimulo> => {
    try {
      console.log(motivoEstimuloId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; motivoEstimulos: MotivoEstimulo }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/motivo-estimulos/${motivoEstimuloId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 201) {
        throw new Error('Error actualizando motivo de estimulo');
      }
      return response.data.motivoEstimulos;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };

  export const deleteMotivoEstimulo = async (
    motivoEstimuloId:number,
   ): Promise<MotivoEstimulo> => {
    try {
      console.log(motivoEstimuloId);
      const response: AxiosResponse<{ message: string; motivoEstimulos: MotivoEstimulo }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/motivo-estimulos/${motivoEstimuloId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error eliminando motivo de estimulo');
      }
      return response.data.motivoEstimulos;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };