import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { editAnnouncement, getAnnouncement } from './api';


const Edit = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { announcementId } = useParams();

    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL || '';


    const announcementSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'TITLE_REQUIRED' })),
        description: Yup.string().required(intl.formatMessage({ id: 'DESCRIPTION_REQUIRED' })),
        start_date: Yup.date().required(intl.formatMessage({ id: 'START_DATE_REQUIRED' })),
        end_date: Yup.date().required(intl.formatMessage({ id: 'END_DATE_REQUIRED' })),
        location: Yup.string().required(intl.formatMessage({ id: 'LOCATION_REQUIRED' })),
        contact_reference: Yup.string().required(intl.formatMessage({ id: 'CONTACT_REFERENCE_REQUIRED' })),
        image_url: Yup.mixed().test('fileSize', 'La imagen debe ser menor a 2MB', (value) =>
            value ? value.size <= 2000000 : true
        ),
    });

    useEffect(() => {

        const fetchAnnouncementData = async () => {
            try {
                if (announcementId) {
                    const response = await getAnnouncement(Number(announcementId));

                    formik.setValues({
                        name: response?.name || '',
                        description: response?.description || '',
                        start_date: response?.start_date || '',
                        end_date: response?.end_date || '',
                        location: response?.location || '',
                        contact_reference: response?.contact_reference || '',
                        requirements: response?.requirements || '',
                        selection_process: response?.selection_process || '',
                        required_documents: response?.required_documents || '',
                        image_url: '',
                    });
                    setImagePreview(`${imageBaseUrl}${response.image_url}`)


                }
            } catch (error) {
                console.error('Error fetching announcement data:', error);
            }
        };

        fetchAnnouncementData();
    }, [announcementId]); // Asegúrate de incluir formik como dependencia


    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            start_date: '',
            end_date: '',
            location: '',
            contact_reference: '',
            requirements: '',
            selection_process: '',
            required_documents: '',
            image_url: '', // Inicializado como una cadena vacía
        },
        validationSchema: announcementSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                await editAnnouncement(Number(announcementId), values);
                Swal.fire({
                    title: 'Success',
                    text: 'Announcement edited successfully',
                    icon: 'success',
                });
                navigate('/announcements/index');
            } catch (error) {
                console.error('Error editing announcement:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Error editing announcement',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        },
    });


    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            formik.setFieldValue('image_url', file);
            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='card mb-1 '>
            <div
                className='card-header border-1 cursor-pointer'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>
                        <h1>{intl.formatMessage({ id: 'EDIT_ANNOUNCEMENT' })}</h1>
                    </h3>
                </div>
            </div>
            <div className='card-body py-3'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className='mb-3 col-md-6 mt-3'>
                            <label htmlFor='name' className='form-label'>
                                {intl.formatMessage({ id: 'TITLE' })}
                            </label>
                            <input type='text' className='form-control' id='title' {...formik.getFieldProps('name')} />
                            {formik.touched.name && typeof formik.errors.name === 'string' && (
                                <div className='text-danger'>{formik.errors.name}</div>
                            )}
                        </div>

                        <div className='mb-3 col-md-6 mt-3'>
                            <label htmlFor='contact_reference' className='form-label'>
                                {intl.formatMessage({ id: 'CONTACT_REFERENCE' })}
                            </label>
                            <input type='text' className='form-control' id='contact_reference' {...formik.getFieldProps('contact_reference')} />
                            {formik.touched.contact_reference && typeof formik.errors.contact_reference === 'string' && (
                                <div className='text-danger'>{formik.errors.contact_reference}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='image' className='form-label'>
                                {intl.formatMessage({ id: 'IMAGE' })}
                            </label>
                            <input
                                type='file'
                                id='image'
                                name='image'
                                accept='image/*'
                                className='form-control'
                                onChange={handleImageChange}
                            />
                            {imagePreview && <img src={imagePreview} alt='Preview' style={{ marginTop: '10px', maxWidth: '100%' }} />}
                            {formik.touched.image_url && typeof formik.errors.image_url === 'string' && (
                                <div className='text-danger'>{formik.errors.image_url}</div>
                            )}
                        </div>

                        <div className='mb-3 col-md-6 mt-3'>
                            <label htmlFor='start_date' className='form-label'>
                                {intl.formatMessage({ id: 'START_DATE' })}
                            </label>
                            <input type='date' className='form-control' id='start_date' {...formik.getFieldProps('start_date')} />
                            {formik.touched.start_date && typeof formik.errors.start_date === 'string' && (
                                <div className='text-danger'>{formik.errors.start_date}</div>
                            )}
                        </div>

                        <div className='mb-3 col-md-6 mt-3'>
                            <label htmlFor='end_date' className='form-label'>
                                {intl.formatMessage({ id: 'END_DATE' })}
                            </label>
                            <input type='date' className='form-control' id='end_date' {...formik.getFieldProps('end_date')} />
                            {formik.touched.end_date && typeof formik.errors.end_date === 'string' && (
                                <div className='text-danger'>{formik.errors.end_date}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='location' className='form-label'>
                                {intl.formatMessage({ id: 'LOCATION' })}
                            </label>
                            <input type='text' className='form-control' id='location' {...formik.getFieldProps('location')} />
                            {formik.touched.location && typeof formik.errors.location === 'string' && (
                                <div className='text-danger'>{formik.errors.location}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='description' className='form-label'>
                                {intl.formatMessage({ id: 'DESCRIPTION' })}
                            </label>
                            <textarea className='form-control' id='description' {...formik.getFieldProps('description')} />
                            {formik.touched.description && typeof formik.errors.description === 'string' && (
                                <div className='text-danger'>{formik.errors.description}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='requirements' className='form-label'>
                                {intl.formatMessage({ id: 'REQUIREMENTS' })}
                            </label>
                            <textarea className='form-control' id='requirements' {...formik.getFieldProps('requirements')} />
                            {formik.touched.requirements && typeof formik.errors.requirements === 'string' && (
                                <div className='text-danger'>{formik.errors.requirements}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='selection_process' className='form-label'>
                                {intl.formatMessage({ id: 'SELECTION_PROCESS' })}
                            </label>
                            <textarea className='form-control' id='selection_process' {...formik.getFieldProps('selection_process')} />
                            {formik.touched.selection_process && typeof formik.errors.selection_process === 'string' && (
                                <div className='text-danger'>{formik.errors.selection_process}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='required_documents' className='form-label'>
                                {intl.formatMessage({ id: 'REQUIRED_DOCUMENTS' })}
                            </label>
                            <textarea className='form-control' id='required_documents' {...formik.getFieldProps('required_documents')} />
                            {formik.touched.required_documents && typeof formik.errors.required_documents === 'string' && (
                                <div className='text-danger'>{formik.errors.required_documents}</div>
                            )}
                        </div>


                    </div>

                    <div className="card-footer">
                        <div className="row justify-content-end mt-2">
                            <div className="col d-flex justify-content-end">
                                <button type="button" className="btn btn-secondary mx-2"
                                    onClick={() => navigate('/announcements/index')}
                                >
                                    {intl.formatMessage({ id: 'CANCEL' })}
                                </button>
                                <button type="submit" className="btn btn-primary ml-3" disabled={loading}>
                                    {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                                </button>
                            </div>
                        </div>
                    </div>





                </form>
            </div>
        </div>
    );
};

export default Edit;
