import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Correccion } from './model'; 
import Swal from 'sweetalert2';
import { deleteCorreccion } from './apiCorrecciones';

interface DestroyCorreccionesModalProps {
    correccion: Correccion | null;
    show: boolean;
    handleClose: () => void;
    eliminarCorreccion: (correccion: Correccion) => void;
}

const DestroyCorreccion: React.FC<DestroyCorreccionesModalProps> = ({ show, handleClose, correccion, eliminarCorreccion }) => { 
  const [loading, setLoading] = useState(false);

  const handleDelete = async (correccionId: number) => {
    setLoading(true);

    try {
        await deleteCorreccion(correccionId);
        Swal.fire({
            title: 'Eliminado',
            text: 'Corrección eliminada exitosamente',
            icon: 'warning',
        });
        
        eliminarCorreccion(correccion!); 
        handleClose();

    } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error eliminando la corrección',
          icon: 'error',
        });
    } finally {
        setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton className='bg-danger'>
        <Modal.Title className='text-white'>Eliminar Corrección {correccion?.nombre}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Estás seguro de eliminar la corrección?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <button type="button" disabled={loading} className='btn btn-danger' onClick={() => handleDelete(correccion!.id)}>Eliminar</button>
      </Modal.Footer>
    </Modal>
  );
};

export default DestroyCorreccion;