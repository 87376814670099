import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from 'react-router-dom';
import { getData, updateNomina } from "./api";
import Swal from 'sweetalert2';

const NominaEdit = () => {
  const [loading, setLoading] = useState(false);
  const { solicitudId } = useParams(); // Obtiene solicitudId de la URL

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        if(!solicitudId) return;
        const response = await getData(+solicitudId);
        if (response.data) {
          formik.setValues({
            funcionSNSP: response.data.funcionSNSP || "",
            cat_tabular: response.data.cat_tabular || "",
            cat_CNCA: response.data.cat_CNCA || "",
            puesto_CNCA: response.data.puesto_CNCA || "",
            jer_puesto: response.data.jer_puesto || "",
            sueldo_mensual: response.data.sueldo_mensual || "",
            sexo: response.data.sexo || "",
            fecha_nacimiento: response.data.fecha_nacimiento || "",
            idNivelEstudio: response.data.idNivelEstudio || "",
            nombre: response.data.nombre || "",
            primer_apellido: response.data.primer_apellido || "",
            segundo_apellido: response.data.segundo_apellido || "",
            curp: response.data.curp || "",
            cargo: response.data?.nombramientos_certificaciones?.cargo || "",
            comision: response.data?.nombramientos_certificaciones?.comision || "",
            cuip: response.data?.nombramientos_certificaciones?.cuip || "",
            no_empleado: response.data?.nombramientos_certificaciones?.no_empleado || "",
            rfc: response.data?.documentos?.rfc || "",
          });
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener los datos',
          text: 'Ocurrió un error al obtener los datos',
        });
      }
    };

    if(solicitudId) {
      fetchInitialData();
    }
  }, [solicitudId]);

  const formularioSchema = Yup.object().shape({
    funcionSNSP: Yup.string().required("Este campo es requerido"),
    cat_tabular: Yup.string().required("Este campo es requerido"),
    cat_CNCA: Yup.string().required("Este campo es requerido"),
    puesto_CNCA: Yup.string().required("Este campo es requerido"),
    jer_puesto: Yup.number().required("Este campo es requerido"),
    sueldo_mensual: Yup.number().required("Este campo es requerido"),
    sexo: Yup.string().required("Este campo es requerido"),
    fecha_nacimiento: Yup.string().required("Este campo es requerido"),
    idNivelEstudio: Yup.string().required("Este campo es requerido"),
    nombre: Yup.string().required("Este campo es requerido"),
    primer_apellido: Yup.string().required("Este campo es requerido"),
    segundo_apellido: Yup.string().required("Este campo es requerido"),
    curp: Yup.string().required("Este campo es requerido"),
    cargo: Yup.string().required("Este campo es requerido"),
    comision: Yup.string().required("Este campo es requerido"),
    cuip: Yup.string().required("Este campo es requerido"),
    no_empleado: Yup.string().required("Este campo es requerido"),
    rfc: Yup.string().required("Este campo es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      funcionSNSP: '',
      cat_tabular: '',
      cat_CNCA: '',
      puesto_CNCA: '',
      jer_puesto: '',
      sueldo_mensual: '',
      sexo: '',
      fecha_nacimiento: '',
      idNivelEstudio: '',
      nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      curp: '',
      cargo: '',
      comision: '',
      cuip: '',
      no_empleado: '',
      rfc: '',
    },
    validationSchema: formularioSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        if(!solicitudId) return;
        await updateNomina(+solicitudId, formData);
        //alert("Nomina actualizada correctamente");
        Swal.fire({
          icon: 'success',
          title: 'Nómina actualizada',
          text: 'La nómina se actualizó correctamente',
        });
      } catch (error) {
        console.error("Error al actualizar la nómina:", error);
        //alert("Error al actualizar");
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar la nómina',
          text: 'Ocurrió un error al actualizar la nómina',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="card">
    <div className="card-body">
      <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
          <label htmlFor="funcionSNSP" className="form-label">Función SNSP:</label>
          <select className="form-control" id="funcionSNSP" {...formik.getFieldProps('funcionSNSP')}>
            <option value="Elemento">Elemento</option>
            <option value="Administrativo">Administrativo</option>
          </select>
          {formik.touched.funcionSNSP && formik.errors.funcionSNSP && <div className="text-danger">{formik.errors.funcionSNSP}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="cat_tabular" className="form-label">Categoría Tabular:</label>
          <select className="form-control" id="cat_tabular" {...formik.getFieldProps('cat_tabular')}>
            <option value="Mando Superior">Mando Superior</option>
            <option value="Mando Medio">Mando Medio</option>
            <option value="Operativo">Operativo</option>
            <option value="Administrativo">Administrativo</option>
          </select>
          {formik.touched.cat_tabular && formik.errors.cat_tabular && <div className="text-danger">{formik.errors.cat_tabular}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="cat_CNCA" className="form-label">Categoría CNCA:</label>
          <select className="form-control" id="cat_CNCA" {...formik.getFieldProps('cat_CNCA')}>
            <option value="Mando Superior">Mando Superior</option>
            <option value="Mando Medio">Mando Medio</option>
            <option value="Operativo">Operativo</option>
            <option value="Gabinete">Gabinete</option>
            <option value="Administrativo">Administrativo</option>
          </select>
          {formik.touched.cat_CNCA && formik.errors.cat_CNCA && <div className="text-danger">{formik.errors.cat_CNCA}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="puesto_CNCA" className="form-label">Puesto CNCA:</label>
          <select className="form-control" id="puesto_CNCA" {...formik.getFieldProps('puesto_CNCA')}>
            <option value="Con Acceso A Información Y/O Función Sensible">Con Acceso A Información Y/O Función Sensible</option>
            <option value="Sin Acceso A Información Y/O Función Sensible">Sin Acceso A Información Y/O Función Sensible</option>
            <option value="Vacio">Vacío</option>
          </select>
          {formik.touched.puesto_CNCA && formik.errors.puesto_CNCA && <div className="text-danger">{formik.errors.puesto_CNCA}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="jer_puesto" className="form-label">Jerarquía del Puesto:</label>
          <input type="number" className="form-control" id="jer_puesto" {...formik.getFieldProps('jer_puesto')} />
          {formik.touched.jer_puesto && formik.errors.jer_puesto && <div className="text-danger">{formik.errors.jer_puesto}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="sueldo_mensual" className="form-label">Sueldo Mensual:</label>
          <input type="number" className="form-control" id="sueldo_mensual" step="0.01" {...formik.getFieldProps('sueldo_mensual')} />
          {formik.touched.sueldo_mensual && formik.errors.sueldo_mensual && <div className="text-danger">{formik.errors.sueldo_mensual}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="sexo" className="form-label">Sexo:</label>
          <select className="form-control" id="sexo" {...formik.getFieldProps('sexo')}>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </select>
          {formik.touched.sexo && formik.errors.sexo && <div className="text-danger">{formik.errors.sexo}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="fecha_nacimiento" className="form-label">Fecha de Nacimiento:</label>
          <input type="date" className="form-control" id="fecha_nacimiento" {...formik.getFieldProps('fecha_nacimiento')} />
          {formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento && <div className="text-danger">{formik.errors.fecha_nacimiento}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="nombre" className="form-label">Nombre:</label>
          <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
          {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="primer_apellido" className="form-label">Primer Apellido:</label>
          <input type="text" className="form-control" id="primer_apellido" {...formik.getFieldProps('primer_apellido')} />
          {formik.touched.primer_apellido && formik.errors.primer_apellido && <div className="text-danger">{formik.errors.primer_apellido}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="segundo_apellido" className="form-label">Segundo Apellido:</label>
          <input type="text" className="form-control" id="segundo_apellido" {...formik.getFieldProps('segundo_apellido')} />
          {formik.touched.segundo_apellido && formik.errors.segundo_apellido && <div className="text-danger">{formik.errors.segundo_apellido}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="curp" className="form-label">CURP:</label>
          <input type="text" className="form-control" id="curp" {...formik.getFieldProps('curp')} />
          {formik.touched.curp && formik.errors.curp && <div className="text-danger">{formik.errors.curp}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="cargo" className="form-label">Puesto Funcional [Cargo o Puesto]</label>
          <input type="text" className="form-control" id="cargo" {...formik.getFieldProps('cargo')} />
          {formik.touched.cargo && formik.errors.cargo && <div className="text-danger">{formik.errors.cargo}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="comision" className="form-label">Puesto Tabular [Comisión]</label>
          <input type="text" className="form-control" id="comision" {...formik.getFieldProps('comision')} />
          {formik.touched.comision && formik.errors.comision && <div className="text-danger">{formik.errors.comision}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="cuip" className="form-label">CUIP:</label>
          <input type="text" className="form-control" id="cuip" {...formik.getFieldProps('cuip')} />
          {formik.touched.cuip && formik.errors.cuip && <div className="text-danger">{formik.errors.cuip}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="no_empleado" className="form-label">No. Empleado:</label>
          <input type="text" className="form-control" id="no_empleado" {...formik.getFieldProps('no_empleado')} />
          {formik.touched.no_empleado && formik.errors.no_empleado && <div className="text-danger">{formik.errors.no_empleado}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="rfc" className="form-label">RFC:</label>
          <input type="text" className="form-control" id="rfc" {...formik.getFieldProps('rfc')} />
          {formik.touched.rfc && formik.errors.rfc && <div className="text-danger">{formik.errors.rfc}</div>}
        </div>

        <div className="mb-3">
          <label htmlFor="idNivelEstudio" className="form-label">Nivel de Estudio:</label>
          <select
            className="form-control"
            id="idNivelEstudio"
            {...formik.getFieldProps('idNivelEstudio')}
          >
            <option value="">Selecciona</option>
            <option value="1">Sin escolaridad</option>
            <option value="2">Primaria completa</option>
            <option value="3">Primaria incompleta</option>
            <option value="4">Secundaria completa</option>
            <option value="5">Secundaria incompleta</option>
            <option value="6">Bachillerato completo</option>
            <option value="7">Bachillerato incompleto</option>
            <option value="8">Carrera técnica completa</option>
            <option value="9">Carrera técnica incompleta</option>
            <option value="10">Licenciatura completa</option>
            <option value="11">Licenciatura incompleta</option>
            <option value="12">Maestría completa</option>
            <option value="13">Maestría incompleta</option>
            <option value="14">Doctorado completo</option>
            <option value="15">Doctorado incompleto</option>
          </select>
          {formik.touched.idNivelEstudio && formik.errors.idNivelEstudio && <div className="text-danger">{formik.errors.idNivelEstudio}</div>}
        </div>

        <button type="submit" className="btn btn-success" disabled={loading}>
          {loading ? "Actualizando..." : "Actualizar"}
        </button>
      </form>
    </div>
  </div>
  );
};

export { NominaEdit };
