import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import FormacionInicial from './index'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Formación Inicial',
        path: '/formacion-inicial',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const FormacionInicialPage = () => (
    <Routes>



        <Route
            path=':solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Formación Inicial</PageTitle>
                    <FormacionInicial />
                </>
            }
        />

        <Route index element={<Navigate to='/formacion-inicial/:solicitudId' />} />

    </Routes>
)

export default FormacionInicialPage
