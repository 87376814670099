import React, { useEffect, useState } from 'react';
import { IUser } from './model';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsPencil, BsTrash } from 'react-icons/bs';

interface UserListBodyProps {
  data: IUser[];
  isLoading: boolean; // Agregar isLoading como prop
  handleDelete: (userId: number) => void;
  handleEdit: (userId: number) => void;
}

const UserListBody: React.FC<UserListBodyProps> = ({ data, isLoading, handleDelete, handleEdit }) => {
  return (
    <>
      <tbody>
        {isLoading ? ( // Mostrar mensaje de carga mientras se realiza la búsqueda
          <tr>
            <td colSpan={5}>Cargando...</td>
          </tr>
        ) : (
          data.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>

              <td>
                <ButtonGroup aria-label="Actions">
                  <Button
                    variant="warning"
                    onClick={() => user && user.id ? handleEdit(user.id) : undefined}
                  >
                    <BsPencil />
                  </Button>

                  <Button
                    variant="danger"
                    onClick={() => user && user.id ? handleDelete(user.id) : undefined}
                  >
                    <BsTrash />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

export default UserListBody;
