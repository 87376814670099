import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Profile from './Profile'
import EvaluationForm from './Create'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Perfil',
        path: '/perfil',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ProfilePage = () => (
    <Routes>

        <Route
            path='index/:solicitanteId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Perfil</PageTitle>
                    <Profile />
                </>
            }
        />

        <Route
            path='create'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Perfil</PageTitle>
                    <EvaluationForm />
                </>
            }
        />

        <Route index element={<Navigate to='/profile/index/:solicitanteId' />} />

    </Routes>
)

export default ProfilePage
