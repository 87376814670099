/* eslint-disable react/jsx-no-target-blank */

//import { useIntl } from 'react-intl'

import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  // const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Opciones</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/announcements/index'
        icon='/media/icons/duotune/communication/com004.svg'
        title='Convocatorias'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/catalogos/index'
        icon='/media/icons/duotune/abstract/abs015.svg'
        title='Catalogos'
        fontIcon='bi-person-fill-check'
      />
      <SidebarMenuItem
        to='/activos/index'
        icon='/media/icons/duotune/general/gen048.svg'
        title='Activos'
        fontIcon='bi-person-fill-check'
      />

      <SidebarMenuItem
        to='/elementos/create'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Elementos'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/users/index'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Usuarios'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/nomina/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Listado Nominal'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Estado de fuerza y certificaciones</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/fuerza/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Estado de fuerza'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/certificados/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Certificados'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/no-certificados/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Sin certificar'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vigencias Vencidas</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/vencidos-control-confianza/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Control de Confianza'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/vencidos-desempeno/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Desempeño'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/vencidos-competencias-basicas/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Competencias Básicas'
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vigencias por Vencer</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/por-vencer-control-confianza/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Control de Confianza'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/por-vencer-desempeno/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Desempeño'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/por-vencer-competencias-basicas/index'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Competencias Básicas'
        fontIcon='bi-app-indicator'
      />


      {/* <SidebarMenuItem
        to='/solicitudes/index'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Solicitudes'
        fontIcon='bi-app-indicator'
      />

      */}

      {/* 
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Justicia Cívica'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
  */}

    </>
  )
}

export { SidebarMenuMain }
