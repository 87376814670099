import React, {FC,useState} from 'react'
import {Field, ErrorMessage} from 'formik'


const Step2: React.FC = () => {
  const [loading, setLoading] = useState(false)
  
  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Puesta a disposición</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
        
            

            <div className='row mb-6'>

              <div className='col-lg-6'>
              <label className='form-label required fw-bold fs-6'>Nombre</label>
                  <Field
                    name='name'
                    type='text'
                    placeholder ='Ejemplo: Jesus'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='name' />
                    </div>
                </div>
                <div className='col-lg-6 fv-row'>
              <label className='form-label required fw-bold fs-6'>Apellido Paterno</label>
                  <Field
                    name='surname'
                    type='text'
                    placeholder ='Ejemplo: Medina'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='surname' />
                    </div>
                </div>


              <div className='col-lg-6'>
              <label className='form-label required fw-bold fs-6'>Apellido Materno</label>
                  <Field
                    name='secondSurname'
                    type='text'
                    placeholder ='Ejemplo: Torres'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='secondSurname' />
                    </div>
                </div>
                <div className='col-lg-6 fv-row'>
              <label className='form-label required fw-bold fs-6'>¿Cuál es su grado o cargo?</label>
                  <Field
                    name='position'
                    type='text'
                    placeholder ='Ejemplo: Policia Municipal'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='position' />
                    </div>
                </div>
                <div className='col-lg-12 fv-row'>
                <label className='form-label required fw-bold fs-6'>Adscripción</label>
                  <Field
                    name='adscription'
                    type='text'
                    placeholder ='Ejemplo: Fresnillo, Zac'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='adscription' />
                    </div>
                </div>
                <div className='col-lg-12 fv-row'>
                <label className='form-label required fw-bold fs-6'>¿En qué unidad arribó al lugar de la intervención?</label>
                  <Field
                    name='intervetionUnit'
                    type='number'
                    placeholder ='Ejemplo: 858'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='intervetionUnit' />
                    </div>
                </div>
            </div>       
        </div>
            
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>

    
  )
}

export {Step2}