import { ErrorMessage, Field, useFormikContext, FormikValues } from "formik";
import { useState, useEffect  } from "react";
import Swal from "sweetalert2";
import { getInstanciasEvaluadoras } from "../api"; 
import { createControlConfianza, updateControlConfianza } from "../api";
import { useAuth } from "../../auth";
import { useParams } from 'react-router-dom';

const Step1: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [instancias, setInstancias] = useState([]);
    const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
    const [updating, setUpdating] = useState('d-none');
    const [step1Value, setStep1Value] = useState<number | undefined>(undefined);
    const formik = useFormikContext();
    const { currentUser } = useAuth(); 
    let { solicitudId } = useParams();

    useEffect(() => {
        cargarInstancias();
    }, [currentUser]);

    const cargarInstancias = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasEvaluadoras(currentUser.id.toString());
                setInstancias(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias evaluadoras.", "error");
        } finally {
            setLoading(false);
        }
    };
    
    
    const handleStep1 = async () => {
        const { values } = formik; // Obtener los valores actuales del formulario
        const errors = await formik.validateForm(); // Validar el formulario
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, ejecutar la acción
        setLoading(true);
          console.log('Valores actuales del formulario:', values);
          // Asegurar que values sea de tipo FormikValues
          const formValues = values as FormikValues;
    
          // Crear un nuevo objeto FormData
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });
          
          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }

            if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }

    
          try {
            const cf = await createControlConfianza(formData);
            Swal.fire({
                title: 'Guardado',
                text: 'Investigación de Control de Confianza registrado exitosamente',
                icon: 'success',
            });
            setSaving('d-none');
            setUpdating('card-footer d-flex justify-content-end py-6 px-9');
            if(cf){
              setStep1Value(cf.evaluacionConfianza.id);
            }
            console.log(cf);
    
        } catch (error) {
            console.error('Error al crear investigación de Control de Confianza:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error al crear investigación de Control de Confianza',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
        } else {
          // Mostrar los errores de validación
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
            showConfirmButton: true,
            allowOutsideClick: true,
    
    
          });
        }
        //setLoading(false);
      };

      const handleStep1update = async () => {
        const { values } = formik; // Obtener los valores actuales del formulario
        const errors = await formik.validateForm(); // Validar el formulario
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, ejecutar la acción
        setLoading(true);
          console.log('Valores actuales del formulario:', values);
          // Asegurar que values sea de tipo FormikValues
          const formValues = values as FormikValues;
    
          // Crear un nuevo objeto FormData
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });
          
          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }
          
          
          if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }

            
          try {  
            const cf = await updateControlConfianza(formData, step1Value as number);
            Swal.fire({
                title: 'Guardado',
                text: 'Investigación de Control de Confianza actualizado exitosamente',
                icon: 'success',
            });
            setSaving('d-none');
            setUpdating('card-footer d-flex justify-content-end py-6 px-9');
            console.log(cf.evaluacionConfianza.id);
            if(cf){
              setStep1Value(cf.evaluacionConfianza.id);
            }
            
    
        } catch (error) {
            console.error('Error al actualizar Control de Confianza:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error al actualizar Control de Confianza',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
        } else {
          // Mostrar los errores de validación
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
            showConfirmButton: true,
            allowOutsideClick: true,
    
    
          });
        }
        //setLoading(false);
      };

  interface CustomSweetAlertOptions{
    onOpen?: () => void
  }

    return (
        <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Evaluación de Control de Confianza</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
        
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de evaluación</label>
                <Field
                    name='fecha_evaluacion'
                    type='date'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_evaluacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Motivo de evaluación</label>
                <Field
                    name='motivo_evaluacion'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Nuevo ingreso'>Nuevo ingreso</option>
                    <option value='Reingreso'>Reingreso</option>
                    <option value='Permanencia'>Permanencia</option>
                    <option value='Promoción'>Promoción</option>
                    <option value='Observación'>Observación</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='motivo_evaluacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Resultado de la evaluación</label>
                <Field
                    name='resultado'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Aprobado'>Aprobado</option>
                    <option value='No aprobado'>No aprobado</option>
                    <option value='En proceso'>En proceso</option>
                    <option value='Sin resultado'>Sin resultado</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='resultado' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Observaciones</label>
                <Field
                    name='observaciones'
                    type='text'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='observaciones' />
                </div>

            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Estatus de las observaciones</label>
                <Field
                    name='estatus_observaciones'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Cumplidas'>Cumplidas</option>
                    <option value='En proceso'>En proceso</option>
                    <option value='Sin cumplir'>Sin cumplir</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='estatus_observaciones' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de reexaminación</label>
                <Field
                    name='fecha_reexaminacion'
                    type='date'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_reexaminacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Estatus de la evaluación</label>
                <Field
                    name='estatus_evaluacion'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value='Programada'>Programada</option>
                    <option value='En proceso'>En proceso</option>
                    <option value='Finalizada'>Finalizada</option>
                    <option value='Incompleta'>Incompleta</option>
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='estatus_evaluacion' />
                </div>
            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Motivo incompleto</label>
                <Field
                    name='motivo_incompleta'
                    type='text'
                    className='form-control form-control-lg form-control-solid'/>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='motivo_incompleta' />
                </div>

            </div>

            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Instancia evaluadora</label>
                <Field
                    name='id_instancia_evaluadora'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value=''>Selecciona una instancia</option> {/* Opción por defecto */}
                    {instancias.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='id_instancia_evaluadora' />
                </div>
            </div>
            </div>   
    </div>

          <div className={saving}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className={updating}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1update}>
              {!loading && 'Actualizar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>
    );
}

export {Step1}
