import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import CreateEstimulo from "./createEstimulos";
import EditEstimulo from "./editEstimulos";
import DestroyEstimulo from "./destroyEstimulos";
import { MotivoEstimulo } from "./model";
import { getMotivoEstimulos } from "./apiEstimulos";

const Estimulos: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedMotivoEstimulo, setSelectedMotivoEstimulo] = useState<MotivoEstimulo | null>(null);
    const [data, setData] = useState<MotivoEstimulo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    //Para mandar llamar el listado de motivos de estimulos
    useEffect(() => {
        const fetchMotivoEstimulos = async () => {
          setIsLoading(true);
          setError('');
          try {
            const  {motivoEstimulos}  = await getMotivoEstimulos();
            setData(motivoEstimulos);
            setIsLoading(false);
          } catch (error) {
            if (error instanceof Error) {
              setError(error.message); 
            } else {
              setError('Ocurrio un error inesperado'); 
            }
            setIsLoading(false);
          }
        };
    
        fetchMotivoEstimulos();
        
      },[]);
  

       //Para poder cambiar el estatus en memoria de los cambios
       const crearMotivoEstimulo = (nuevoMotivoEstimulo: MotivoEstimulo) => {
        setData(motivoEstimulos => [...motivoEstimulos, nuevoMotivoEstimulo]);
      };
      const actualizarMotivoEstimulo = (nuevoMotivoEstimulo: MotivoEstimulo) => {
        setData(motivoEstimulos => {
            const motivoEstimuloExistenteIndex = motivoEstimulos.findIndex(motivoEstimulo => motivoEstimulo.id === nuevoMotivoEstimulo.id);
    
            if (motivoEstimuloExistenteIndex !== -1) {
                // Si el curso existe, actualiza su información
                const nuevosMotivoEstimulos = [...motivoEstimulos];
                nuevosMotivoEstimulos[motivoEstimuloExistenteIndex] = nuevoMotivoEstimulo;
                return nuevosMotivoEstimulos;
            } else {
                // Si el curso no existe, agrégalo a la lista
                return [...motivoEstimulos, nuevoMotivoEstimulo];
            }
        });
    };
    
    const eliminarMotivoEstimulo = (motivoEstimuloEliminar: MotivoEstimulo) => {
      setData(motivoEstimulos => {
          const nuevosMotivoEstimulos = motivoEstimulos.filter(motivoEstimulo => motivoEstimulo.id !== motivoEstimuloEliminar.id);
          return nuevosMotivoEstimulos;
      });
    };

    const handleShowCreateModal = () => {
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleShowEditModal = (motivoEstimulo:MotivoEstimulo) => {
        setSelectedMotivoEstimulo(motivoEstimulo);
        setShowEditModal(true);
    };
    const handleShowDestroyModal = (motivoEstimulo:MotivoEstimulo) => {
        setSelectedMotivoEstimulo(motivoEstimulo);
        setShowDestroyModal(true);
    };
    const handleCloseModal = () => {
        setShowEditModal(false);
        setShowDestroyModal(false);
      };



    return (
        
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title m-0">Estimulos</h3>
                    <div>
                        <button className="btn btn-info" onClick={() => handleShowCreateModal()}>Nuevo <BsPlusCircle /></button>
                          <CreateEstimulo show={showCreateModal} handleClose={handleCloseCreateModal} data={data} agregarNuevoMotivoEstimulo={crearMotivoEstimulo}/>

                    </div>
            </div>
            <div className="card-body">
                <div className="row">
                {data.map((motivoEstimulo) => (
                    <div className="col-md-4 mt-2">
                    <div className="card shadow-lg">
                        <div className="card-body align-items-center">
                            <p className="text-bold text-center">{motivoEstimulo.nombre}</p>
                            <button className="btn btn-warning m-2" onClick={() => handleShowEditModal(motivoEstimulo)}><BsPencil/></button>
                            <button className="btn btn-danger m-2" onClick={() => handleShowDestroyModal(motivoEstimulo)}><BsTrash /></button>
                        </div>
                    </div>
                    </div>
                    ))}
                            <EditEstimulo show={showEditModal} handleClose={handleCloseModal} motivoEstimulo={selectedMotivoEstimulo} modificarMotivoEstimulo={actualizarMotivoEstimulo}/>
                            <DestroyEstimulo show={showDestroyModal} handleClose={handleCloseModal} motivoEstimulo={selectedMotivoEstimulo} eliminarMotivoEstimulo={eliminarMotivoEstimulo} />



                  
                    
                </div>
                
            </div>
        </div>
    );
};

export default Estimulos;
    