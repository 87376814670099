import * as Yup from 'yup'

const seleccionesSchemas = [
    //Validaciones por cada paso
    Yup.object({
        fecha_evaluacion: Yup.date().max(new Date(), 'La fecha de evaluación no debe ser futura').required('Por favor ingrese la fecha de evaluación'),
        motivo_evaluacion: Yup.string().max(255).required('Por favor ingrese el motivo de evaluación'),
        resultado: Yup.string().max(255).required('Por favor ingrese el resultado de la evaluación'),
        observaciones: Yup.string().max(255).required('Por favor ingrese las observaciones'),
        estatus_observaciones: Yup.string().max(255).required('Por favor ingrese el estatus de las observaciones'),
        fecha_reexaminacion: Yup.date(),
        estatus_evaluacion: Yup.string().max(255).required('Por favor ingrese el estatus de la evaluación'),
        motivo_incompleta: Yup.string().when('estatus_evaluacion', {
            is: 'Incompleta',
            then: Yup.string().required('Por favor ingrese el motivo incompleto')
        }),
        id_instancia_evaluadora: Yup.number().required('Por favor ingrese la instancia evaluadora').min(1, 'Por favor ingrese la instancia evaluadora'),
    }),
    Yup.object({
        tipo: Yup.string().max(255).required('Por favor ingrese el tipo de formación'),
        resultado_fi: Yup.string().max(255).required('Por favor ingrese el resultado de la formación'),
        fechaInicio: Yup.date().required('Por favor ingrese la fecha de inicio'),
        fechaFin: Yup.date().required('Por favor ingrese la fecha de fin').min(
            Yup.ref('fechaInicio'),
            'La fecha de fin debe ser posterior a la fecha de inicio'
          ),
        duracion: Yup.number().min(1, 'Debe ser mayor a 1').max(100000, 'Debe ser menor a 100,000').required('Por favor ingrese la duración'),
        cantidadDuracion: Yup.string().max(255).required('Por favor ingrese la cantidad de duración'),
        calificacionObtenida: Yup.number().when('resultado_fi', {
            is: 'Aprobado',
            then: Yup.number().required('Por favor ingrese la calificación obtenida')
        }).min(0, 'La calificación debe ser mayor a 0').max(10, 'La calificación debe ser menor a 10'),
        estatusEvaluacion: Yup.string().max(255).required('Por favor ingrese el estatus de la evaluación'),
        motivoIncompleta: Yup.string().when('estatusEvaluacion', {
            is: 'Incompleta',
            then: Yup.string().required('Por favor ingrese el motivo incompleto')
        }),
        observaciones_fi: Yup.string().max(255).required('Por favor ingrese las observaciones'),
        idInstanciaCapacitadora: Yup.number().required('Por favor ingrese la instancia capacitadora'),
        idInstanciaResponsable: Yup.number().required('Por favor ingrese la instancia responsable'),
    }),
    Yup.object({
        tipo_d: Yup.string().max(255).required('Por favor ingrese el tipo de evaluación'),
        fechaEvaluacion: Yup.date().required('Por favor ingrese la fecha de evaluación').max(new Date(), 'La fecha de evaluación no debe ser futura'),
        resultado_d: Yup.string().max(255).required('Por favor ingrese el resultado de la evaluación'),
        calificacionObtenida_d: Yup.number().when('resultado_d', {
            is: 'Aprobado',
            then: Yup.number().required('Por favor ingrese la calificación obtenida')
        }).min(0, 'La calificación debe ser mayor a 0').max(10, 'La calificación debe ser menor a 10'),
        observaciones_d: Yup.string().max(255).required('Por favor ingrese las observaciones'),
        idInstanciaEvaluadora: Yup.number().required('Por favor ingrese la instancia evaluadora'),
    }),
  ]
export {seleccionesSchemas}