
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Sancion, SancionFormValues } from './model';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useAuth } from '../auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { updateSancion } from './apiSanciones';
//import { IAnnouncement } from './model';

interface EditSancionesModalProps {
    show: boolean;
    sancion: Sancion | null;
    handleClose: () => void;
    modificarSancion: any;
}

const EditSancion: React.FC<EditSancionesModalProps> = ({ show, handleClose,modificarSancion,sancion }) => { 
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    

  const sancionSchema = Yup.object().shape({
    nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
  });
  

  let formik = useFormik<SancionFormValues>({
    initialValues:{
        nombre: '', // Asignar el nombre del sancion actual o una cadena vacía si no hay sancion
    },
    validationSchema: sancionSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        if(currentUser){
          formData.append('user_id', currentUser.id.toString());
        }

        const nuevaSancion = await updateSancion(sancion!.id ,formData);
        console.log('La nueva ' + nuevaSancion);
        //console.log('Actualizado'+nuevaSancion);
        Swal.fire({
          title: 'Actualizado',
          text: 'Sanción actualizado exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de sancions
        modificarSancion(nuevaSancion);
        //Cerramos el modal
        handleClose();
        navigate('/catalogos/index');
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: 'Error',
          text: 'Error actualizando una sanción',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
    if (sancion) {
        // Actualizar los valores del formulario cuando cambie el sancion
        formik.setValues({
            nombre: sancion.nombre,
        });
    }
}, [sancion]);  
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Sanción</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
                <div className="mb-3 col-md-12 mt-3">
                <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
                <input type="text" className="form-control"  id="nombre" {...formik.getFieldProps('nombre')} />
                {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
                </div>
            </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>

        </Modal>
    );
};

export default EditSancion;