
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CompetenciaValues, CompetenciaModel} from './model';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import { FormikValues, useFormik, Field, ErrorMessage, FormikProvider  } from 'formik';
import Swal from 'sweetalert2';
import { createCompetenciasBasicas, getInstanciasEvaluadoras, getInstanciasResponsables} from '../selecciones/api'; 

interface CreateModalProps {
    show: boolean;
    handleClose: () => void;
    agregarNuevoModelo: any;
    solicitud: any;
    data:CompetenciaModel[];
}

const Create: React.FC<CreateModalProps> = ({ show, handleClose,agregarNuevoModelo, solicitud}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {currentUser} = useAuth();
    const [instancias, setInstancias] = useState([]);
    const [instanciasResponsables, setInstanciasResponsables] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      cargarInstancias();
  }, [currentUser]);

  const cargarInstancias = async () => {
      setLoading(true);
      try {
          if (currentUser) {
              const data = await getInstanciasEvaluadoras(currentUser.id.toString());
              setInstancias(data);
              const dataResponsables = await getInstanciasResponsables(currentUser.id.toString());
              setInstanciasResponsables(dataResponsables);
          }
      } catch (error) {
          Swal.fire("Error", "No se pudieron cargar las instancias.", "error");
      } finally {
          setLoading(false);
      }
  };


  const modelSchema = 
  Yup.object({
    fecha_evaluacion: Yup.date().max(new Date(), 'La fecha de evaluación no debe ser futura').required('Por favor ingrese la fecha de evaluación'),
    calificacion: Yup.string().required('Por favor ingrese la calificación').max(255).oneOf(['Aprobado', 'No aprobado', 'En proceso', 'Sin resultado'], 'La calificación debe ser Aprobado, No Aprobado, En Proceso o Sin Resultado'),
    resultado: Yup.string().max(255).required('Por favor ingrese el resultado'),
    calificacion_obtenida: Yup.number().when('calificacion', {
        is: 'Aprobado',
        then: Yup.number().required('Por favor ingrese la calificación obtenida')
    }).when('calificacion', {
        is: 'No Aprobado',
        then: Yup.number().required('Por favor ingrese la calificación obtenida')
    }).min(0, 'La calificación debe ser mayor a 0').max(10, 'La calificación debe ser menor a 10'),
    fecha_reevaluacion: Yup.date(),
    observaciones: Yup.string().max(255),
    id_instancia_responsable: Yup.number(),
    id_instancia_evaluadora: Yup.number().required('Por favor ingrese la instancia evaluadora').min(1, 'Por favor ingrese la instancia evaluadora'),
  });

  const formik = useFormik<CompetenciaValues>({
    initialValues: {
    },
    validationSchema: modelSchema,
    onSubmit: async (values) => {
      console.log("No inicia");
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevoModelo = await createCompetenciasBasicas(formData);
        
        Swal.fire({
          title: 'Guardado',
          text: 'Creado exitosamente',
          icon: 'success',
        });
        
        agregarNuevoModelo(nuevoModelo);
        //Cerramos el modal
        handleClose();
        navigate('/competencia/'+ solicitud);
      } catch (error) {
        console.error('Error al crear:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al crear',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
    return (
        <Modal show={show} onHide={handleClose} className='modal-lg'>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Evaluación de Desempeño</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <FormikProvider value={formik}>
            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Fecha de evaluación</label>
            <Field name="fecha_evaluacion" type="date" className="form-control" />
            <ErrorMessage name="fecha_evaluacion" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Calificación</label>
            <Field name="calificacion" as="select" className="form-control">
              <option value="">Seleccione una calificación</option>
              <option value="Aprobado">Aprobado</option>
              <option value="No Aprobado">No Aprobado</option>
              <option value="En Proceso">En Proceso</option>
              <option value="Sin Resultado">Sin Resultado</option>
            </Field>
            <ErrorMessage name="calificacion" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Resultado</label>      
            <Field name="resultado" type="text" className="form-control" />
            <ErrorMessage name="resultado" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Calificación obtenida</label>
            <Field name="calificacion_obtenida" type="number" className="form-control" placeholder="Calificación obtenida" />
            <ErrorMessage name="calificacion_obtenida" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Fecha de reevaluación</label>
            <Field name="fecha_reevaluacion" type="date" className="form-control" />
            <ErrorMessage name="fecha_reevaluacion" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Observaciones</label>
            <Field name="observaciones" as="textarea" className="form-control" placeholder="Observaciones" />
            <ErrorMessage name="observaciones" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Instancia responsable</label>
            <Field name="id_instancia_responsable" as="select" className="form-control">
              <option value="">Seleccione una instancia responsable</option>
              {instanciasResponsables && instanciasResponsables.map((instancia: any) => (
                <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
              ))}
            </Field>
            <ErrorMessage name="id_instancia_responsable" component="div" className="text-danger" />
            </div>

            <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Instancia evaluadora</label>
            <Field name="id_instancia_evaluadora" as="select" className="form-control">
              <option value="">Seleccione una instancia evaluadora</option>
              {(instancias).map((instancia: any) => (
                <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
              ))}
            </Field>
            <ErrorMessage name="id_instancia_evaluadora" component="div" className="text-danger" />
            </div>
            </FormikProvider>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                </button>


            </Modal.Footer>
            </form>
        </Modal>
    );
};

export default Create;