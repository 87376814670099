import * as Yup from 'yup'

const seleccionesSchemas = [
    Yup.object({
        fecha_de_evaluacion: Yup.date().max(new Date(), 'La fecha de evaluación no debe ser futura').required('Por favor ingrese la fecha de evaluación'),
        calificacion: Yup.string().required('Por favor ingrese la calificación').max(255).oneOf(['Aprobado', 'No aprobado', 'En proceso', 'Sin resultado'], 'La calificación debe ser Aprobado, No Aprobado, En Proceso o Sin Resultado'),
        resultado: Yup.string().max(255).required('Por favor ingrese el resultado'),
        calificacion_obtenida: Yup.number().when('calificacion', {
            is: 'Aprobado',
            then: Yup.number().required('Por favor ingrese la calificación obtenida')
        }).when('calificacion', {
            is: 'No Aprobado',
            then: Yup.number().required('Por favor ingrese la calificación obtenida')
        }).min(0, 'La calificación debe ser mayor a 0').max(10, 'La calificación debe ser menor a 10'),
        fecha_de_reevaluacion: Yup.date(),
        observaciones: Yup.string().max(255),
        id_instancia_responsable: Yup.number(),
        id_instancia_evaluadora: Yup.number().required('Por favor ingrese la instancia evaluadora').min(1, 'Por favor ingrese la instancia evaluadora'),
    }),
    Yup.object({
        cargo: Yup.string().required('Por favor ingrese el cargo').max(255),
        comision: Yup.string().required('Por favor ingrese la comisión').max(255),
        fecha_inicio: Yup.date().required('Por favor ingrese la fecha de inicio'),  
        area_adscripcion: Yup.string().required('Por favor ingrese el área de adscripción').max(255),
        cuip: Yup.string().required('Por favor ingrese el CUIP').max(255),
        cup: Yup.string().required('Por favor ingrese el CUP').max(255),
        fecha_emision_cup: Yup.date().required('Por favor ingrese la fecha de emisión del CUP').max(new Date(), 'La fecha de emisión del CUP no debe ser futura'),
        no_empleado: Yup.string().required('Por favor ingrese el número de empleado').max(255),
        no_portacion_arma: Yup.string().max(255),
        idMunicipioCorporacion: Yup.number().required('Por favor ingrese el municipio de la corporación').min(1, 'Por favor ingrese el municipio de la corporación'),
    }),
  ]
export {seleccionesSchemas}