
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CorreccionDisciplinariaFormValues, CorreccionDisciplinariaModel} from './model';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import Swal from 'sweetalert2';
import { createCorreccionDisciplinaria } from './api';
import { Correccion, Instancia, Sancion } from '../catalogos/model';
import { getCorrecciones } from '../catalogos/apiCorrecciones';


interface CreateCorreccionesDisciplinariasModalProps {
    show: boolean;
    handleClose: () => void;
    agregarNuevaCorreccionDisciplinaria: any;
    solicitud: any;
    data:CorreccionDisciplinariaModel[];
}

const CreateCorreccionDisciplinaria: React.FC<CreateCorreccionesDisciplinariasModalProps> = ({ show, handleClose,agregarNuevaCorreccionDisciplinaria, solicitud}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {currentUser} = useAuth();
    //Para los select de catalogos
    const [dataCorreccion, setDataCorrecciones] = useState<Correccion[]>([]);
    const [error, setError] = useState('');
    //const [isLoading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);






//Para mandar llamar el listado de motivos de correcciones
useEffect(() => {
    const fetchCorrecciones = async () => {
        setIsLoading(true);
        setError('');
        try {
            const {correccion} = await getCorrecciones();
            setDataCorrecciones(correccion);
            setIsLoading(false);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocurrió un error inesperado');
            }
            setIsLoading(false);
        }
    };

    fetchCorrecciones();
    
}, [currentUser]);
  const correccionDisciplinariaSchema = Yup.object().shape({
    superior_aplica: Yup.string().max(250,'No se permiten mas de 250 caracteres').required('Es necesario ingresar el nombre de quien aplica'),
    id_tipo_correccion: Yup.number().typeError('El tipo de corrección no es valido').integer('El tipo de corrección no es valido').required('Es necesario ingresar un tipo de corrección').test('is-number', 'El tipo de corrección no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
      cumplimiento: Yup.number().typeError('El cumplimiento no es valido').integer('El cumplimiento no es valido').required('Es necesario ingresar el cumplimiento').test('is-number', 'El cumplimiento no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
      duracion: Yup.number().typeError('La duración debe ser un número').integer('La duración debe ser un numero entero').max(365,'La duración debe ser menor a 365').min(1,'La duración debe ser mayor a 0').required('Es necesario ingresar la duración').test('is-number', 'La duración debe ser un número', value => {
        if (!value) return true; // Si no hay valor, la validación pasa
        return !isNaN(value); // Si es un número, la validación pasa
      }),
      cantidad_duracion: Yup.string().required('Es necesario ingresar el tipo de cantidad de duracion'),
      fecha_determinacion: Yup.date().required('Es necesario ingresar la fecha de determinación'),
  });
  const formik = useFormik<CorreccionDisciplinariaFormValues>({
    initialValues: {
        superior_aplica: '',
    },
    validationSchema: correccionDisciplinariaSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevaSancion = await createCorreccionDisciplinaria(formData);
        
        Swal.fire({
          title: 'Guardado',
          text: 'Corrección disciplinaria creado exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de una corrección disciplinaria
        agregarNuevaCorreccionDisciplinaria(nuevaSancion);
        //Cerramos el modal
        handleClose();
        navigate('/correcciones-disciplinarias/'+ solicitud);
      } catch (error) {
        console.error('Error creando una corrección disciplinaria:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creando una corrección disciplinaria',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
    return (
        <Modal show={show} onHide={handleClose} className='modal-lg'>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Nueva Corrección Disciplinaria</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
            
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="id_tipo_correccion" className="form-label">Tipo de Corrección</label>
                <select className="form-control" id="id_tipo_correccion" {...formik.getFieldProps('id_tipo_correccion')}>
                <option value="">Por favor seleccione una opción</option>
                {dataCorreccion.map((correccion) => (
                    <option value={correccion.id}>{correccion.nombre}</option>
                ))}

                </select>
                {formik.touched.id_tipo_correccion && formik.errors.id_tipo_correccion && <div className="text-danger">{formik.errors.id_tipo_correccion}</div>}
            </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="superior_aplica" className="form-label">¿Quien aplica?</label>
                <input type="text" className="form-control" id="superior_aplica" {...formik.getFieldProps('superior_aplica')} />
                {formik.touched.superior_aplica && formik.errors.superior_aplica && <div className="text-danger">{formik.errors.superior_aplica}</div>}
                </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="duracion" className="form-label">Duración</label>
                <input type="number" className="form-control" id="duracion" {...formik.getFieldProps('duracion')} />
                {formik.touched.duracion && formik.errors.duracion && <div className="text-danger">{formik.errors.duracion}</div>}
                </div>
                <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="cantidad_duracion" className="form-label">---</label>
                <select className="form-control" id="cantidad_duracion" {...formik.getFieldProps('cantidad_duracion')}>
                <option value="">Por favor seleccione una opción</option>
                    <option value="Horas">Horas</option>
                    <option value="Dias">Dias</option>
                    <option value="Semanas">Semanas</option>
                    <option value="Meses">Meses</option>
                    <option value="Años">Años</option>
                </select>
                {formik.touched.cantidad_duracion && formik.errors.cantidad_duracion && <div className="text-danger">{formik.errors.cantidad_duracion}</div>}
            </div>
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="cumplimiento" className="form-label">¿Cumplio?</label>
                <select className="form-control" id="cumplimiento" {...formik.getFieldProps('cumplimiento')}>
                <option value="">Por favor seleccione una opción</option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                </select>
                {formik.touched.cumplimiento && formik.errors.cumplimiento && <div className="text-danger">{formik.errors.cumplimiento}</div>}
             </div>
           
                <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="fecha_determinacion" className="form-label">Fecha de Determinación</label>
                <input type="date" className="form-control" id="fecha_determinacion" {...formik.getFieldProps('fecha_determinacion')} />
                {formik.touched.fecha_determinacion && formik.errors.fecha_determinacion && <div className="text-danger">{formik.errors.fecha_determinacion}</div>}
                </div>
        
            </div>
        


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                </button>


            </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CreateCorreccionDisciplinaria;