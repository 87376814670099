import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Index } from './index'
import { Create } from './create'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Justicia Civica',
        path: '/justicia-civica',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const JusticiaCivicaPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Justicia Civica</PageTitle>
                    <Index />
                </>
            }
        />
        <Route
            path='create'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Nueva Justicia Civica</PageTitle>
                    <Create />
                </>
            }
        />

        <Route index element={<Navigate to='/justicia-civica/index' />} />

    </Routes>
)

export default JusticiaCivicaPage
