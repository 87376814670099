import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ControlConfianza from './index'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Control de Confianza',
        path: '/control-confianza',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ControlConfianzaPage = () => (
    <Routes>



        <Route
            path=':solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Control de Confianza</PageTitle>
                    <ControlConfianza />
                </>
            }
        />

        <Route index element={<Navigate to='/control-confianza/:solicitudId' />} />

    </Routes>
)

export default ControlConfianzaPage
