import React from 'react';
import { useState, useEffect  } from "react";
import { useAuth } from "../../auth";
import { getInstanciasCapacitadoras, getInstanciasResponsables } from "../api"; 
import { createFormacionInicial, updateFormacionInicial } from "../api";
import Swal from "sweetalert2";
import { ErrorMessage, Field, useFormikContext, FormikValues } from "formik";
import { TipoFormacion, ResultadoFormacion, DuracionFormacion, EstatusEvaluacionFormacion, FormacionInicial } from '../modelFormacionInicial'; 
import { useParams } from 'react-router-dom';


const Step2: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<FormacionInicial>();
    const [loading, setLoading] = useState(false)
    const [instancias, setInstancias] = useState([]);// Instancias capacitadoras
    const [instanciasr, setInstanciasr] = useState([]);// Instancias responsables
    const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
    const [updating, setUpdating] = useState('d-none');
    const [step2Value, setStep2Value] = useState<number | undefined>(undefined);
    const formik = useFormikContext();
    const { currentUser } = useAuth();
    let { solicitudId } = useParams(); 

    useEffect(() => {
        cargarInstanciasCapacitadoras();
        cargarInstanciasResponsables();
    }, [currentUser]);

    const cargarInstanciasCapacitadoras = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasCapacitadoras(currentUser.id.toString());
                setInstancias(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias capacitadoras.", "error");
        } finally {
            setLoading(false);
        }
    };

    const cargarInstanciasResponsables = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasResponsables(currentUser.id.toString());
                setInstanciasr(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias responsables.", "error");
        } finally {
            setLoading(false);
        }
    }
    
    
    const handleStep2 = async () => {
        const { values } = formik; // Obtener los valores actuales del formulario
        const errors = await formik.validateForm(); // Validar el formulario
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, ejecutar la acción
        setLoading(true);
          console.log('Valores actuales del formulario:', values);
          // Asegurar que values sea de tipo FormikValues
          const formValues = values as FormikValues;
    
          // Crear un nuevo objeto FormData
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });

          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }

            if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }

    
          try {
            const cf = await createFormacionInicial(formData);
            Swal.fire({
                title: 'Guardado',
                text: 'Formación Inicial registrado exitosamente',
                icon: 'success',
            });
            setSaving('d-none');
            setUpdating('card-footer d-flex justify-content-end py-6 px-9');
            if(cf){
              setStep2Value(cf.formacionInicial.id);
            }
            console.log(cf);
    
        } catch (error) {
            console.error('Error al crear Formación Inicial:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error al crear Formación Inicial',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
        } else {
          // Mostrar los errores de validación
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
            showConfirmButton: true,
            allowOutsideClick: true,
    
    
          });
        }
        //setLoading(false);
      };

      const handleStep2Update = async () => {
        const { values } = formik; // Obtener los valores actuales del formulario
        const errors = await formik.validateForm(); // Validar el formulario
        if (Object.keys(errors).length === 0) {
          // No hay errores de validación, ejecutar la acción
        setLoading(true);
          console.log('Valores actuales del formulario:', values);
          // Asegurar que values sea de tipo FormikValues
          const formValues = values as FormikValues;
    
          // Crear un nuevo objeto FormData
          const formData = new FormData();
          
          // Agregar los valores del formulario al objeto FormData
          Object.entries(formValues).forEach(([key, value]) => {
              formData.append(key, value as string); // Asegurar que el valor sea de tipo string
          });

          if(solicitudId){
            formData.set('id_solicitud', solicitudId);
          } else {
              Swal.fire({
                title: 'Error',
                text: 'No se pudo obtener el ID de la solicitud',
                icon: 'error',
            });
            throw new Error('No se pudo obtener el ID de la solicitud');
          }

            if(currentUser){
                formData.append('user_id', currentUser.id.toString());
            }

    
          try {
            const cf = await updateFormacionInicial(formData, step2Value as number);
            Swal.fire({
                title: 'Guardado',
                text: 'Formación Inicial actualizado exitosamente',
                icon: 'success',
            });
            setSaving('d-none');
            setUpdating('card-footer d-flex justify-content-end py-6 px-9');
            if(cf){
              setStep2Value(cf.formacionInicial.id);
            }
            console.log(cf);
    
        } catch (error) {
            console.error('Error al actualizar Formación Inicial:', error);
            Swal.fire({
                title: 'Error',
                text: 'Error al actualizar Formación Inicial',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
        } else {
          // Mostrar los errores de validación
          Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
            showConfirmButton: true,
            allowOutsideClick: true,
    
    
          });
        }
        //setLoading(false);
      };

  interface CustomSweetAlertOptions{
    onOpen?: () => void
  }

    return (
        <div className="card mb-5 mb-xl-10">
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'>
                <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Detalles de la Formación</h3>
                </div>
            </div>
            <div className="card-body border-top p-9">
            <div className='row mb-6'>
                {/* Tipo */}
                <div className="col-lg-12">
                    <label htmlFor="tipo" className="form-label required fw-bold fs-6">Tipo de formación</label>
                    <Field as="select" name="tipo" className="form-select">
                        <option value="">Seleccione un tipo</option>
                        {Object.entries(TipoFormacion).map(([key, value]) => (
                            <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="tipo" component="div" className="text-danger" />
                </div>

                {/* Resultado */}
                <div className="col-lg-12">
                    <label htmlFor="resultado" className="form-label required fw-bold fs-6">Resultado de la formación</label>
                    <Field as="select" name="resultado_fi" className="form-select">
                        <option value="">Seleccione un resultado</option>
                        {Object.entries(ResultadoFormacion).map(([key, value]) => (
                            <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="resultado_fi" component="div" className="text-danger" />
                </div>

                {/* Fecha de Inicio */}
                <div className="col-lg-12">
                    <label htmlFor="fechaInicio" className="form-label required fw-bold fs-6">Fecha de inicio</label>
                    <Field type="date" name="fechaInicio" className="form-control" />
                    <ErrorMessage name="fechaInicio" component="div" className="text-danger" />
                </div>

                {/* Fecha de Fin */}
                <div className="col-lg-12">
                    <label htmlFor="fechaFin" className="form-label required fw-bold fs-6">Fecha de fin</label>
                    <Field type="date" name="fechaFin" className="form-control" />
                    <ErrorMessage name="fechaFin" component="div" className="text-danger" />
                </div>

                {/* Duración */}
                <div className="col-lg-12">
                    <label htmlFor="duracion" className="form-label required fw-bold fs-6">Duración</label>
                    <Field type="number" name="duracion" className="form-control" />
                    <ErrorMessage name="duracion" component="div" className="text-danger" />
                </div>

                {/* Cantidad Duración */}
                <div className="col-lg-12">
                    <label htmlFor="cantidadDuracion" className="form-label required fw-bold fs-6">Cantidad de duración</label>
                    <Field as="select" name="cantidadDuracion" className="form-select">
                        <option value="">Seleccione la unidad de duración</option>
                        {Object.entries(DuracionFormacion).map(([key, value]) => (
                            <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="cantidadDuracion" component="div" className="text-danger" />
                </div>

                {/* Calificación Obtenida */}
                <div className="col-lg-12">
                    <label htmlFor="calificacionObtenida" className="form-label required fw-bold fs-6">Calificación obtenida</label>
                    <Field type="number" name="calificacionObtenida" className="form-control" />
                    <ErrorMessage name="calificacionObtenida" component="div" className="text-danger" />
                </div>

                {/* Estatus Evaluación */}
                <div className="col-lg-12">
                    <label htmlFor="estatusEvaluacion" className="form-label required fw-bold fs-6">Estatus de la evaluación</label>
                    <Field as="select" name="estatusEvaluacion" className="form-select">
                        <option value="">Seleccione el estatus</option>
                        {Object.entries(EstatusEvaluacionFormacion).map(([key, value]) => (
                        <option key={key} value={value}>{value}</option>
                        ))}
                    </Field>
                    <ErrorMessage name="estatusEvaluacion" component="div" className="text-danger" />
                </div>
                            {/* Motivo Incompleta */}
            <div className="col-lg-12">
                <label htmlFor="motivoIncompleta" className="form-label required fw-bold fs-6">Motivo si está incompleta</label>
                <Field type="text" name="motivoIncompleta" className="form-control" />
                <ErrorMessage name="motivoIncompleta" component="div" className="text-danger" />
            </div>

            {/* Observaciones */}
            <div className="col-lg-12">
                <label htmlFor="observaciones" className="form-label required fw-bold fs-6">Observaciones</label>
                <Field type="text" name="observaciones_fi" className="form-control" />
                <ErrorMessage name="observaciones_fi" component="div" className="text-danger" />
            </div>

            {/* Instancia Capacitadora */}
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Instancia Capacitadora</label>
                <Field
                    name='idInstanciaCapacitadora'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value=''>Selecciona una instancia capacitadora</option> {/* Opción por defecto */}
                    {instancias.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='idInstanciaCapacitadora' />
                </div>
            </div>

    

            {/* Instancia Responsable */}
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Instancia Responsable</label>
                <Field
                    name='idInstanciaResponsable'
                    as='select'
                    className='form-select form-select-lg form-select-solid'>
                    <option value=''>Selecciona una instancia responsable</option> {/* Opción por defecto */}
                    {instanciasr.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='idInstanciaResponsable' />
                </div>
            </div>

            </div>
            </div>

            <div className={saving}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep2}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className={updating}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep2Update}>
              {!loading && 'Actualizar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>
);
};

export { Step2 };
