import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Instancia, InstanciaFormValues } from './model'; 
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateInstancia } from './apiInstancias';
import { useAuth } from '../auth';

interface EditInstanciasModalProps {
    show: boolean;
    instancia: Instancia | null; 
    handleClose: () => void;
    modificarInstancia: (instancia: Instancia) => void; 
}

const EditInstancia: React.FC<EditInstanciasModalProps> = ({ show, handleClose, instancia, modificarInstancia }) => {  
    const {currentUser} = useAuth();
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const tipoOpciones = [
        { value: 'Instancia Evaluadora', label: 'Instancia Evaluadora' },
        { value: 'Instancia Capacitadora', label: 'Instancia Capacitadora' },
        { value: 'Instancia Sancionadora', label: 'Instancia Sancionadora' },
        { value: 'Instancia Responsable', label: 'Instancia Responsable' },
    ];


    const instanciaSchema = Yup.object().shape({
        nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
        tipo: Yup.string().required('El tipo es requerido'),
    });
  
    let formik = useFormik<InstanciaFormValues>({
        initialValues: {
            nombre: instancia ? instancia.nombre : '',
            tipo: instancia ? instancia.tipo : '',
        },
        validationSchema: instanciaSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('nombre', values.nombre);
                formData.append('tipo', values.tipo); 
                if(currentUser){
                    formData.append('user_id', currentUser.id.toString());
                }
                const instanciaActualizada = await updateInstancia(instancia?.id || 1, formData);

                Swal.fire({
                    title: 'Actualizado',
                    text: 'Instancia actualizada exitosamente',
                    icon: 'success',
                });

                modificarInstancia(instanciaActualizada);
                handleClose();
                navigate('/catalogos/index');
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error actualizando la instancia',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        if (instancia) {
            formik.setValues({
                nombre: instancia.nombre,
                tipo: instancia.tipo,
            });
        }
    }, [instancia]); 

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Instancia {instancia?.nombre}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="mb-3 col-md-12 mt-3">
                            <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
                            <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
                            {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
                        </div>
                        <div className="mb-3 col-md-12">
                            <label htmlFor="tipo" className="form-label">Tipo</label>
                            <select className="form-control" id="tipo" {...formik.getFieldProps('tipo')}>
                                <option value="">Selecciona un tipo</option>
                                {tipoOpciones.map(opcion => (
                                    <option key={opcion.value} value={opcion.value}>
                                        {opcion.label}
                                    </option>
                                ))}
                            </select>
                            {formik.touched.tipo && formik.errors.tipo && <div className="text-danger">{formik.errors.tipo}</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                        {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditInstancia;