import axios, { AxiosResponse } from "axios";
import { ControlConfianzaModel } from "./model";


export const getModel = async (solicitud:any): 
Promise<{ model: ControlConfianzaModel[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/evaluacion-confianzas?solicitud=${solicitud}`;
        const response = await axios.get(queryString);
        console.log(response.data);
        const model = response.data.data;
        return  {model};
    }catch (error) {
        throw error;
    }
 }


 export const createSancion = async (formData: FormData): Promise<ControlConfianzaModel> => {
    try {
      const response: AxiosResponse<{ message: string; sanciones: ControlConfianzaModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/sanciones`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando sanción');
      }
      console.log(response.data.sanciones)
      return response.data.sanciones;
    } catch (error) {
      throw error;
    }
  };


  export const updateModel = async (
    modelId:number,
    formData: FormData
   ): Promise<ControlConfianzaModel> => {
    try {
      console.log(modelId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; model: ControlConfianzaModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/evaluacion-confianzas2/${modelId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 201) {
        throw new Error('Error actualizando sanción');
      }
      return response.data.model;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };
  export const deleteModel = async (
    modeloId:number,
   ): Promise<ControlConfianzaModel> => {
    try {
      console.log(modeloId);
      const response: AxiosResponse<{ message: string; model: ControlConfianzaModel }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/evaluacion-confianzas/${modeloId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error eliminando.');
      }
      return response.data.model;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };
