import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import Catalogos from '.'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Catalogos',
        path: '/catalogos',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CatalogoPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Catalogos</PageTitle>
                    <Catalogos />
                </>
            }
        />

        <Route index element={<Navigate to='/catalogos/index' />} />

    </Routes>
)

export default CatalogoPage
