import { ErrorMessage, Field, useFormikContext, FormikValues } from "formik";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { getMunicipios } from "../api";
import { createNombramiento, updateNombramiento } from "../api";
import { useAuth } from "../../auth";
import { useParams } from 'react-router-dom';
import { Municipio } from "../../users/model";

const Step2: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [municipio, setMunicipio] = useState<Municipio[]>([]);

  const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
  const [updating, setUpdating] = useState('d-none');
  const [step1Value, setStep1Value] = useState<number | undefined>(undefined);
  const formik = useFormikContext();
  const { currentUser } = useAuth();
  let { solicitudId } = useParams();

  useEffect(() => {
    loadMunicipios();
  }, [currentUser]);

  const loadMunicipios = async () => {
    if (currentUser) {
      try {
        // Llamar a la función de la API para obtener los municipios
        const municipios = await getMunicipios(Number(currentUser.id));
        setMunicipio(municipios.municipios);
      }
      catch (error) {
        console.error('Error al cargar los municipios:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al cargar los municipios',
          icon: 'error',
        });
      }
    }
  };

  const handleStep1 = async () => {
    const { values } = formik; // Obtener los valores actuales del formulario
    const errors = await formik.validateForm(); // Validar el formulario
    if (Object.keys(errors).length === 0) {
      // No hay errores de validación, ejecutar la acción
      setLoading(true);
      console.log('Valores actuales del formulario:', values);
      // Asegurar que values sea de tipo FormikValues
      const formValues = values as FormikValues;

      // Crear un nuevo objeto FormData
      const formData = new FormData();

      // Agregar los valores del formulario al objeto FormData
      Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value as string); // Asegurar que el valor sea de tipo string
      });

      if (solicitudId) {
        formData.set('id_solicitud', solicitudId);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo obtener el ID de la solicitud',
          icon: 'error',
        });
        throw new Error('No se pudo obtener el ID de la solicitud');
      }

      if (currentUser) {
        formData.append('user_id', currentUser.id.toString());
      }


      try {
        const cf = await createNombramiento(formData);
        Swal.fire({
          title: 'Guardado',
          text: 'Registrado exitosamente',
          icon: 'success',
        });
        setSaving('d-none');
        setUpdating('card-footer d-flex justify-content-end py-6 px-9');
        if (cf) {
          setStep1Value(cf.nombramientoCertificacion.id);
        }
        console.log(cf);

      } catch (error) {
        console.error('Error al crear:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al crear',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      // Mostrar los errores de validación
      Swal.fire({
        html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
        showConfirmButton: true,
        allowOutsideClick: true,


      });
    }
    //setLoading(false);
  };

  const handleStep1update = async () => {
    const { values } = formik; // Obtener los valores actuales del formulario
    const errors = await formik.validateForm(); // Validar el formulario
    if (Object.keys(errors).length === 0) {
      // No hay errores de validación, ejecutar la acción
      setLoading(true);
      console.log('Valores actuales del formulario:', values);
      // Asegurar que values sea de tipo FormikValues
      const formValues = values as FormikValues;

      // Crear un nuevo objeto FormData
      const formData = new FormData();

      // Agregar los valores del formulario al objeto FormData
      Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value as string); // Asegurar que el valor sea de tipo string
      });

      if (solicitudId) {
        formData.set('id_solicitud', solicitudId);
      } else {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo obtener el ID de la solicitud',
          icon: 'error',
        });
        throw new Error('No se pudo obtener el ID de la solicitud');
      }


      if (currentUser) {
        formData.append('user_id', currentUser.id.toString());
      }


      try {
        const cf = await updateNombramiento(formData, step1Value as number);
        Swal.fire({
          title: 'Actualizado',
          text: 'Actualizado exitosamente',
          icon: 'success',
        });
        setSaving('d-none');
        setUpdating('card-footer d-flex justify-content-end py-6 px-9');
        console.log(cf.nombramientoCertificacion.id);
        if (cf) {
          setStep1Value(cf.nombramientoCertificacion.id);
        }


      } catch (error) {
        console.error('Error al actualizar:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al actualizar',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      // Mostrar los errores de validación
      Swal.fire({
        html: `
            <div class="spinner-container" style="overflow: hidden;">
              <i class="fas fa-spinner fa-spin"></i>
              <span>Se encontraron diversos errores</span> <ol>
              ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
              </ol></div>
            `,
        showConfirmButton: true,
        allowOutsideClick: true,


      });
    }
    //setLoading(false);
  };

  interface CustomSweetAlertOptions {
    onOpen?: () => void
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Nombramiento y Certificación</h3>
        </div>
      </div>

      <div className='card-body border-top p-9'>
        <div className='row mb-6'>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Cargo o Puesto</label>
            <Field name="cargo" type="text" className="form-control" />
            <ErrorMessage name="cargo" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Comisión o Servicio</label>
            <Field name="comision" type="text" className="form-control" />
            <ErrorMessage name="comision" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Fecha de inicio</label>
            <Field name="fecha_inicio" type="date" className="form-control" />
            <ErrorMessage name="fecha_inicio" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Área de Adscripción o Unidad Especializada</label>
            <Field name="area_adscripcion" type="text" className="form-control" />
            <ErrorMessage name="area_adscripcion" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Clave Única de Identificación Permanente (CUIP)</label>
            <Field name="cuip" type="text" className="form-control" />
            <ErrorMessage name="cuip" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Clave Certificado Único Policial (CUP)</label>
            <Field name="cup" type="text" className="form-control" />
            <ErrorMessage name="cup" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Fecha de emisión CUP</label>
            <Field name="fecha_emision_cup" type="date" className="form-control" />
            <ErrorMessage name="fecha_emision_cup" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Número de empleado</label>
            <Field name="no_empleado" type="text" className="form-control" />
            <ErrorMessage name="no_empleado" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label fw-bold fs-6 mt-3'>Número de Portación de arma de fuego</label>
            <Field name="no_portacion_arma" type="text" className="form-control" />
            <ErrorMessage name="no_portacion_arma" component="div" className="text-danger" />
          </div>

          <div className='col-lg-12'>
            <label className='form-label required fw-bold fs-6 mt-3'>Municipio de la corporación</label>
            <Field name="idMunicipioCorporacion" as="select" className="form-select">
              <option value="">Seleccione un municipio</option>
              {municipio.map((m: any) => (
                <option key={m.id} value={m.id}>{m.nombre}</option>
              ))}
            </Field>
            <ErrorMessage name="idMunicipioCorporacion" component="div" className="text-danger" />
          </div>



        </div>
      </div>

      <div className={saving}>
        <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1}>
          {!loading && 'Guardar'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Por favor espere...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className={updating}>
        <button type='button' className='btn btn-primary' disabled={loading} onClick={handleStep1update}>
          {!loading && 'Actualizar'}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Por favor espere...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  );
}

export { Step2 }
