import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import { FormacionContinuaModel } from "./model";
import { getFormacionContinua } from "./api";
import { useAuth } from "../auth";
import { useParams } from "react-router-dom";
import CreateFormacionContinua from "./create";
import EditFormacionContinua from "./edit";
import DestroyFormacionContinua from "./destroy";

const FormacionContinua: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedFormacionContinua, setSelectedFormacionContinua] = useState<FormacionContinuaModel | null>(null);
    const [formacionesContinuas, setData] = useState<FormacionContinuaModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {currentUser} = useAuth();
    const { solicitudId } = useParams<{ solicitudId: string }>(); // Obtenemos el solicitudId de los parámetros


    //Para mandar llamar el listado de formacionesContinuas
    useEffect(() => {
        const fetchFormacionesContinuas = async () => {
          setIsLoading(true);
          setError('');
          try {
            const  {formacionesContinuas}  = await getFormacionContinua(solicitudId);
            setData(formacionesContinuas);
            setIsLoading(false);
          } catch (error) {
            if (error instanceof Error) {
              setError(error.message); 
            } else {
              setError('Ocurrio un error inesperado'); 
            }
            setIsLoading(false);
          }
        };
    
        fetchFormacionesContinuas();
        
      },[]);
    //Para poder cambiar el estatus en memoria de los cambios
  const crearFormacionContinua = (nuevaFormacionContinua: FormacionContinuaModel) => {
    setData(formacionesContinuas => [...formacionesContinuas, nuevaFormacionContinua]);
  };
 

  const actualizarFormacionContinua = (nuevaFormacionContinua: FormacionContinuaModel) => {
    setData(formacionesContinuas => {
        const formacionContinuaExistenteIndex = formacionesContinuas.findIndex(curso => curso.id === nuevaFormacionContinua.id);

        if (formacionContinuaExistenteIndex !== -1) {
            // Si el curso existe, actualiza su información
            const nuevasFormacionesContinuas = [...formacionesContinuas];
            nuevasFormacionesContinuas[formacionContinuaExistenteIndex] = nuevaFormacionContinua;
            return nuevasFormacionesContinuas;
        } else {
            // Si el curso no existe, agrégalo a la lista
            return [...formacionesContinuas, nuevaFormacionContinua];
        }
    });
};

const eliminarFormacionContinua = (formacionContinuaEliminar: FormacionContinuaModel) => {
  setData(formacionesContinuas => {
      const nuevasFormacionesContinuas = formacionesContinuas.filter(formacionContinua => formacionContinua.id !== formacionContinuaEliminar.id);
      return nuevasFormacionesContinuas;
  });
};

  if (isLoading) {
    return <div>Por favor espere</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }


    const handleShowCreateModal = () => {
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleShowEditModal = (formacionContinua:FormacionContinuaModel) => {
        setSelectedFormacionContinua(formacionContinua);
        setShowEditModal(true);
    };
    const handleShowDestroyModal = (formacionContinua:FormacionContinuaModel) => {
        setSelectedFormacionContinua(formacionContinua);
        setShowDestroyModal(true);
    };
    const handleCloseModal = () => {
        setShowEditModal(false);
        setShowDestroyModal(false);
      };

    return (
        
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title m-0">Formación Continua</h3>
                    <div>
                        <button className="btn btn-info" onClick={() => handleShowCreateModal()}>Nuevo <BsPlusCircle /></button>
                          <CreateFormacionContinua solicitud={solicitudId} show={showCreateModal} handleClose={handleCloseCreateModal} data={formacionesContinuas} agregarNuevaFormacionContinua={crearFormacionContinua}  />
                    </div>
            </div>
            <div className="card-body">
                <div className="row">
                {formacionesContinuas.map((formacionContinua) => (
                    <div className="col-md-4 mt-2">
                    <div className="card shadow-lg">
                        <div className="card-header bg-primary text-white align-items-center">
                        {formacionContinua.curso?.nombre}
                        </div>
                        <div className="card-body align-items-center">
                            <p className="text-info text-center">Instancia Capacitadora: {formacionContinua.instancia_capacitadora?.nombre}</p>
                            <p className="text-bold text-center">Duración: {formacionContinua.duracion} {formacionContinua.cantidad_duracion} </p>
                            <p className="text-bold text-center">Importancia: {formacionContinua.importancia} </p>
                            <p className="text-bold text-center">Del: {formacionContinua.fecha_inicio} - {formacionContinua.fecha_fin}</p>
                    

                            <button className="btn btn-warning m-2" onClick={() => handleShowEditModal(formacionContinua)}><BsPencil/></button>
                        <button className="btn btn-danger m-2" onClick={() => handleShowDestroyModal(formacionContinua)}><BsTrash /></button>
                
                        </div>
                    </div>
                    </div>
                    ))}
                    <EditFormacionContinua formacionContinua={selectedFormacionContinua} show={showEditModal} handleClose={handleCloseModal} modificarFormacionContinua={actualizarFormacionContinua} solicitud={solicitudId}/>
                    
                    <DestroyFormacionContinua formacionContinua={selectedFormacionContinua} show={showDestroyModal} handleClose={handleCloseModal} eliminarFormacionContinua={eliminarFormacionContinua}/>
                    
                </div>
                
            </div>
        </div>
    
            
      );
};

export default FormacionContinua;
    