export enum TipoFormacion {
  "Nuevo ingreso" = 'Nuevo ingreso',
  "Elemento activo" = 'Elemento activo',
}

export enum ResultadoFormacion {
  "Aprobado" = 'Aprobado',
  "No aprobado" = 'No aprobado',
  "En proceso" = 'En proceso',
  "Sin resultado" = 'Sin resultado',
}

export enum DuracionFormacion {
  "Dias" = 'Dias',
  "Meses" = 'Meses',
  "Semanas" = 'Semanas',
  "Años" = 'Años',
  "Horas" = 'Horas',
}

export enum EstatusEvaluacionFormacion {
  "Programada" = 'Programada',
  "En proceso" = 'En proceso',
  "Finalizada" = 'Finalizada',
  "Incompleta" = 'Incompleta',
}

export interface FormacionInicial {
  id: number;
  tipo?: TipoFormacion;
  resultado?: ResultadoFormacion;
  fechaInicio?: Date;
  fechaFin?: Date;
  duracion?: number;
  cantidadDuracion?: DuracionFormacion;
  calificacionObtenida?: number; 
  estatusEvaluacion?: EstatusEvaluacionFormacion;
  motivoIncompleta?: string;
  observaciones?: string;
  idSolicitud: number; 
  idInstanciaCapacitadora: number; 
  idInstanciaResponsable: number; 
  createdAt?: Date; 
  updatedAt?: Date;
}

export {};