import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Step1} from './steps/step1'
import {Step2} from './steps/step2'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues, useFormik} from 'formik'
import { useNavigate } from 'react-router-dom';

import {CompetenciasBasicas, competenciasBasicasInitial } from './competenciasBasicas'
import {seleccionesSchemas} from './nombramientosSchemas'

const Create = () => {
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(seleccionesSchemas[0])
    const navigate = useNavigate();
  
    const loadStepper = () => {
      stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const goBack = () => {
      navigate(-1);
    };

    const prevStep = () => {
      if (!stepper.current) {
        return
      }
  
      stepper.current.goPrev()
  
      setCurrentSchema(seleccionesSchemas[stepper.current.currentStepIndex - 1])
    }
  
    const submitStep = (values: CompetenciasBasicas, actions: FormikValues) => {
      if (!stepper.current) {
        return
      }
  
      setCurrentSchema(seleccionesSchemas[stepper.current.currentStepIndex])
  
      if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
        stepper.current.goNext()
      } else {
        stepper.current.goto(1)
        actions.resetForm()
      }
    }
  
    useEffect(() => {
      if (!stepperRef.current) {
        return
      }
  
      loadStepper()
    }, [stepperRef])
  
    return (
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside*/}
        <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          {/* begin::Wrapper*/}
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            {/* begin::Nav*/}
            <div className='stepper-nav'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}
  
                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Evaluación de Competencias Básicas</h3>

                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
  
                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}
  
              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}
  
                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Nombramiento y Certificación</h3>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
  
                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}
  
            </div>
            {/* end::Nav*/}
          </div>
          {/* end::Wrapper*/}
        </div>
        {/* begin::Aside*/}
  
        <div className='bg-body rounded'>
          <Formik validationSchema={currentSchema} initialValues={competenciasBasicasInitial} onSubmit={submitStep}>
            {() => (
              <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 />
                </div>
  
                <div data-kt-stepper-element='content'>
                  <Step2 />
                </div>
  
                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Regresar
                    </button>
                  </div>
  
                    <div>
                        {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber ? (
                            <button type='submit' className='btn btn-lg btn-primary me-3'>
                            <span className='indicator-label'>
                                Siguiente
                                <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                                />
                            </span>
                            </button>
                        ) : (
                            <button type='button' className='btn btn-lg btn-secondary' onClick={goBack}>
                              Ir a Nombramiento y certificación
                            </button>
                        )}
                    </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
  
  export {Create}
  