
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CorreccionDisciplinariaModel } from './model';
import { deleteCorreccionDisciplinaria } from './api';
import Swal from 'sweetalert2';
//import { IAnnouncement } from './model';

interface DestroyCorreccionesDisciplinariasModalProps {
    show: boolean;
    handleClose: () => void;
    eliminarCorreccionDisciplinaria: any;
    correccionDisciplinaria: CorreccionDisciplinariaModel | null;
}

const DestroyCorreccionDisciplinaria: React.FC<DestroyCorreccionesDisciplinariasModalProps> = ({ show, handleClose,eliminarCorreccionDisciplinaria,correccionDisciplinaria }) => {  
    const [loading, setLoading] = useState(false);

  const handleDelete = async (correccionDisciplinariaId: number) => {
    setLoading(true);

    try{
        //Lo eliminamos de la base de datos
        const correccionDisciplinaria = await deleteCorreccionDisciplinaria(correccionDisciplinariaId);
        Swal.fire({
            title: 'Eliminado',
            text: 'Correccion disciplinaria eliminada exitosamente',
            icon: 'warning',
          });
        //Lo eliminamos de la vista
        eliminarCorreccionDisciplinaria(correccionDisciplinaria);
        //Cerramos el modal
        handleClose();

    }catch(error) {
        Swal.fire({
          title: 'Error',
          text: 'Error eliminando una correccion disciplinaria',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
  }
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-danger'>
                <Modal.Title className='text-white'>Eliminar Corrección Disciplinaria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Estas seguro de eliminar la corrección disciplinaria?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="button" disabled={loading} className='btn btn-danger' onClick={() => handleDelete(correccionDisciplinaria!.id)}>Eliminar</button>

            </Modal.Footer>

        </Modal>
    );
};


export default DestroyCorreccionDisciplinaria;