import React, { useEffect, useState } from 'react';
import { ISolicitud } from '../model';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsCheck, BsEye, BsPencil, BsTrash, BsX } from 'react-icons/bs';
import { actualizarEstadoSolicitud } from '../api';
import Swal from 'sweetalert2';

interface SolicitudesListBodyProps {
  data: ISolicitud[];
  isLoading: boolean;
  handleDetail: (solicitudId: number) => void; // Agrega handleDetail como prop
  onRemoveSolicitud: (solicitudId: number) => void; // Nueva prop
}

const SolicitudesListBody: React.FC<SolicitudesListBodyProps> = ({ data, isLoading, handleDetail,onRemoveSolicitud }) => {
  const [selectedStatus, setSelectedStatus] = useState<{ [id: number]: number }>({});

  const toggleStatus = async (id: number, status: number) => {
    Swal.fire({
        title: '¿Estás seguro?',
        text: "¿Quieres cambiar el estado de esta solicitud?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, cambiar estado',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            const newStatus = selectedStatus[id] === status ? 0 : status;
            setSelectedStatus({ ...selectedStatus, [id]: newStatus });

            if (newStatus !== 0) {
                actualizarEstadoSolicitud(id, newStatus).then(() => {
                    console.log(`Estado actualizado para la solicitud ${id} a ${newStatus}`);
                    onRemoveSolicitud(id); // Usa la prop para eliminar la fila si es necesario
                    Swal.fire(
                        'Actualizado!',
                        'El estado de la solicitud ha sido actualizado.',
                        'success'
                    )
                }).catch((error) => {
                    console.error('Error al actualizar el estado:', error);
                    Swal.fire(
                        'Error!',
                        'No se pudo actualizar el estado de la solicitud.',
                        'error'
                    )
                });
            }
        }
    })
};

  return (
    <>

      <tbody>
        {isLoading ? ( // Mostrar mensaje de carga mientras se realiza la búsqueda
          <tr>
            <td colSpan={5}>Cargando...</td>
          </tr>
        ) : (
          data.map((solicitud) => (
            <tr key={solicitud.id}>
              <td className='text-center'>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      backgroundColor: selectedStatus[solicitud.id] === 3 ? '#28a745' : '#f8f9fa',
                      boxShadow: selectedStatus[solicitud.id] === 3 ? '0px 0px 8px rgba(40, 167, 69, 0.5)' : 'none',
                      border: '1px solid #ccc'
                    }}
                    onClick={() => toggleStatus(solicitud.id, 3)}
                  >
                    <BsCheck color={selectedStatus[solicitud.id] === 3 ? '#fff' : '#28a745'} size="1.5em" />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      backgroundColor: selectedStatus[solicitud.id] === 4 ? '#dc3545' : '#f8f9fa',
                      boxShadow: selectedStatus[solicitud.id] === 4 ? '0px 0px 8px rgba(220, 53, 69, 0.5)' : 'none',
                      border: '1px solid #ccc'
                    }}
                    onClick={() => toggleStatus(solicitud.id, 4)}
                  >
                    <BsX color={selectedStatus[solicitud.id] === 4 ? '#fff' : '#dc3545'} size="1.5em" />
                  </div>
                </div>
              </td>
              <td>{solicitud.id}</td>
              <td>{solicitud.nombre} {solicitud.primer_apellido} {solicitud.segundo_apellido}</td>
              <td>{solicitud?.curp}</td>
              <td>{solicitud.fecha_solicitud}</td>
              <td>{solicitud.municipio_residencia?.nombre}</td>

              <td>{solicitud.email}</td>

              <td>
                <ButtonGroup aria-label="Actions">
                  <Button variant="info" onClick={() => handleDetail(solicitud.id)}> {/* Agrega onClick para llamar a handleDetail */}
                    <BsEye /> {/* Cambia el icono por uno que represente "Ver detalle" */}
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

export default SolicitudesListBody;
