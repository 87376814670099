import * as Yup from 'yup' 

export enum MotivoEvaluacion {
  "Nuevo ingreso" = 'Nuevo ingreso',
  "Reingreso" = 'Reingreso',
  "Permanencia" = 'Permanencia',
  "Promoción" = 'Promoción',
  "Observación" = 'Observación',
}

export enum ResultadoEvaluacion {
  "Aprobado" = 'Aprobado',
  "No aprobado" = 'No aprobado',
  "En proceso" = 'En proceso',
  "Sin resultado" = 'Sin resultado',
}

export enum EstatusObservaciones {
  "Cumplidas" = 'Cumplidas',
  "En proceso" = 'En proceso',
  "Sin cumplir" = 'Sin cumplir',
}

export enum EstatusEvaluacion {
  "Programada" = 'Programada',
  "En proceso" = 'En proceso',
  "Finalizada" = 'Finalizada',
  "Incompleta" = 'Incompleta',
}

export interface ControlConfianza {
  id: number;
  fecha_evaluacion?: Date; 
  motivo_evaluacion?: MotivoEvaluacion;
  resultado?: ResultadoEvaluacion;
  observaciones?: string;
  estatus_observaciones?: EstatusObservaciones;
  fecha_reexaminacion?: Date;
  estatus_evaluacion?: EstatusEvaluacion;
  motivo_incompleta?: string;
  vigencia?: Date;
  id_instancia_evaluadora: number;
  createdAt?: Date; 
  updatedAt?: Date; 
}

export const controlConfianzaInitial: ControlConfianza = {
  id: 0,
  fecha_evaluacion: undefined,
  motivo_evaluacion: MotivoEvaluacion["Nuevo ingreso"],
  resultado: ResultadoEvaluacion["Sin resultado"],
  observaciones: '',
  estatus_observaciones: EstatusObservaciones["Sin cumplir"],
  fecha_reexaminacion: undefined,
  estatus_evaluacion: EstatusEvaluacion["Programada"],
  motivo_incompleta: '',
  vigencia: undefined,
  id_instancia_evaluadora: 0,
}

export {}