import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DesempenoModel, DesempenoValues } from './model';
import * as Yup from 'yup';
import { useAuth } from '../auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FormikValues, useFormik, Field, ErrorMessage, FormikProvider  } from 'formik';
import Swal from 'sweetalert2';
import { updateModel } from './api';
import {getInstanciasEvaluadoras } from '../selecciones/api';
import { TipoEvaluacion, ResultadoEvaluacion } from '../selecciones/modelEvaluacionDesempeno'; 

interface EditModalProps {
    show: boolean;
    handleClose: () => void;
    modelo:DesempenoModel | null;
    modificarModelo : any;
    solicitud:any;
}

const Edit: React.FC<EditModalProps> = ({ show, handleClose,modelo,modificarModelo,solicitud }) => {  
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    //Para los select de catalogos
    const [instancias, setInstancias] = useState([]); 
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');
    

    const modelSchema = Yup.object().shape({
        tipo: Yup.string().max(255).required('Por favor ingrese el tipo de evaluación'),
        fecha_evaluacion: Yup.date().required('Por favor ingrese la fecha de evaluación').max(new Date(), 'La fecha de evaluación no debe ser futura'),
        resultado: Yup.string().max(255).required('Por favor ingrese el resultado de la evaluación'),
        calificacion_obtenida: Yup.number().when('resultado', {
            is: 'Aprobado',
            then: Yup.number().required('Por favor ingrese la calificación obtenida')
        }).min(0, 'La calificación debe ser mayor a 0').max(10, 'La calificación debe ser menor a 10'),
        observaciones: Yup.string().max(255).required('Por favor ingrese las observaciones'),
        id_instancia_evaluadora: Yup.number().required('Por favor ingrese la instancia evaluadora'),
  });


  let formik = useFormik<DesempenoValues>({
    initialValues: {},
    validationSchema: modelSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevoModelo = await updateModel(modelo?.id || 1,formData);
        console.log(nuevoModelo);
        Swal.fire({
          title: 'Actualizado',
          text: 'Actualizada exitosamente',
          icon: 'success',
        });
        
        modificarModelo(nuevoModelo);
        //Cerramos el modal
        handleClose();
        navigate('/desempeno/'+ solicitud);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error actualizando.',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
      const cargarInstancias = async () => {
        setLoading(true);
        try {
            if (currentUser) {
                const data = await getInstanciasEvaluadoras(currentUser.id.toString());
                setInstancias(data);
            }
        } catch (error) {
            Swal.fire("Error", "No se pudieron cargar las instancias capacitadoras.", "error");
        } finally {
            setLoading(false);
        }
    };  

    cargarInstancias();

    
  },[currentUser]);

  useEffect(() => {
    if (modelo) {
        formik.setValues({
            tipo: modelo.tipo,
            fecha_evaluacion: modelo.fecha_evaluacion,
            resultado: modelo.resultado,
            calificacion_obtenida: modelo.calificacion_obtenida,
            observaciones: modelo.observaciones,
            id_instancia_evaluadora: modelo.id_instancia_evaluadora,
        });
    }
}, [modelo]); 

    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <FormikProvider value={formik}>
            <div className="col-lg-12">
                <label htmlFor="tipo" className="form-label required fw-bold fs-6">Tipo de Evaluación</label>
                <Field name="tipo" as="select" className="form-control">
                    {/* Opciones del enum TipoEvaluacion */}
                    {Object.entries(TipoEvaluacion).map(([key, value]) => (
                        <option key={key} value={value}>{value}</option>
                    ))}
                </Field>
                <ErrorMessage name="tipo" component="div" className="text-danger" />
                </div>

                <div className="col-lg-12">
                <label htmlFor="fecha_evaluacion" className="form-label required fw-bold fs-6">Fecha de Evaluación</label>
                <Field name="fecha_evaluacion" type="date" className="form-control"/>
                <ErrorMessage name="fecha_evaluacion" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="resultado" className="form-label required fw-bold fs-6">Resultado de la Evaluación</label>
                <Field name="resultado" as="select" className="form-control">
                    {/* Opciones del enum ResultadoEvaluacion */}
                    {Object.entries(ResultadoEvaluacion).map(([key, value]) => (
                        <option key={key} value={value}>{value}</option>
                    ))}
                </Field>
                <ErrorMessage name="resultado" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="calificacion_obtenida" className="form-label required fw-bold fs-6">Calificación Obtenida</label>
                <Field name="calificacion_obtenida" type="number" className="form-control"/>
                <ErrorMessage name="calificacion_obtenida" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="observaciones" className="form-label required fw-bold fs-6">Observaciones</label>
                <Field name="observaciones" as="textarea" className="form-control"/>
                <ErrorMessage name="observaciones" component="div" className="text-danger"/>
                </div>

                <div className="col-lg-12">
                <label htmlFor="id_instancia_evaluadora" className="form-label required fw-bold fs-6">Instancia Evaluadora</label>
                <Field name="id_instancia_evaluadora" as="select" className="form-control">
                    <option value="">Seleccione una instancia evaluadora</option>
                    {instancias.map((instancia: { id: string, nombre: string }) => (
                        <option key={instancia.id} value={instancia.id}>{instancia.nombre}</option>
                    ))}
                </Field>
                <ErrorMessage name="id_instancia_evaluadora" component="div" className="text-danger"/>
                </div>
            </FormikProvider>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>


        </Modal>
    );
};

export default Edit;