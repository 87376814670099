import axios, { AxiosResponse } from "axios";
import { EstimuloModel } from "./model";


export const getEstimulo = async (solicitud:any): 
Promise<{ estimulos: EstimuloModel[]}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/estimulos?solicitud=${solicitud}`;
        const response = await axios.get(queryString);
        console.log(response.data);
        const estimulos = response.data.data;
       // console.log(estimulos)
        return  {estimulos};
    }catch (error) {
        throw error;
    }
 }


 export const createEstimulo = async (formData: FormData): Promise<EstimuloModel> => {
    try {
      const response: AxiosResponse<{ message: string; estimulos: EstimuloModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/estimulos`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error creando estimulo');
      }
      console.log(response.data.estimulos)
      return response.data.estimulos;
    } catch (error) {
      throw error;
    }
  };


  export const updateEstimulo = async (
    estimuloId:number,
    formData: FormData
   ): Promise<EstimuloModel> => {
    try {
      console.log(estimuloId);
      formData.append("_method", "PUT");
      const response: AxiosResponse<{ message: string; estimulos: EstimuloModel }> = await axios.post(
        `${process.env.REACT_APP_API_DEV_URL}/estimulos/${estimuloId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 201) {
        throw new Error('Error actualizando estimulo');
      }
      return response.data.estimulos;
    } catch (error) {
      console.log(error);

      throw error;
    }
  };
  export const deleteEstimulo = async (
    estimuloId:number,
   ): Promise<EstimuloModel> => {
    try {
      console.log(estimuloId);
      const response: AxiosResponse<{ message: string; estimulos: EstimuloModel }> = await axios.delete(
        `${process.env.REACT_APP_API_DEV_URL}/estimulos/${estimuloId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status !== 201) {
        throw new Error('Error eliminando estimulo');
      }
      return response.data.estimulos;
      //console.log(response);
    } catch (error) {
      throw error;
    }
  };
