import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Correccion, CorreccionFormValues } from './model'; 
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateCorreccion } from './apiCorrecciones'; 
import { useAuth } from '../auth';

interface EditCorreccionesModalProps {
    show: boolean;
    correccion: Correccion | null; 
    handleClose: () => void;
    modificarCorreccion: (correccion: Correccion) => void;
}

const EditCorreccion: React.FC<EditCorreccionesModalProps> = ({ show, handleClose, correccion, modificarCorreccion }) => {  
    const {currentUser} = useAuth();
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const correccionSchema = Yup.object().shape({
        nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
    });
  
    let formik = useFormik<CorreccionFormValues>({
        initialValues: {
            nombre: correccion ? correccion.nombre : '',
        },
        validationSchema: correccionSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('nombre', values.nombre);
                if (currentUser) {
                    formData.append('user_id', currentUser.id.toString());
                }

                const correccionActualizada = await updateCorreccion(correccion?.id || 1, formData);

                Swal.fire({
                    title: 'Actualizado',
                    text: 'Corrección actualizada exitosamente',
                    icon: 'success',
                });

                modificarCorreccion(correccionActualizada);
                handleClose();
                navigate('/catalogos/index');
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error actualizando la corrección',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        if (correccion) {
            formik.setValues({
                nombre: correccion.nombre,
            });
        }
    }, [correccion]); 

    return (
        <Modal show={show} onHide={handleClose} id={correccion?.id.toString()}>
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Corrección {correccion?.nombre}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="mb-3 col-md-12 mt-3">
                            <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
                            <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
                            {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                        {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditCorreccion;