import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { getUser, editUser } from './api';
import { IUserFormValues, Municipio } from './model'; // Asegúrate de importar el modelo correcto
import { useAuth } from '../auth';
import { getMunicipios } from '../nombramientos/api';

const Edit = () => {


    const intl = useIntl();
    const navigate = useNavigate();
    const { userId } = useParams(); // Cambia a userId según corresponda en tus rutas
    const [loading, setLoading] = useState(false);
    const [municipio, setMunicipio] = useState<Municipio[]>([]);
    const { currentUser } = useAuth();


    const userSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
        email: Yup.string().email(intl.formatMessage({ id: 'INVALID_EMAIL' })).required(intl.formatMessage({ id: 'EMAIL_REQUIRED' })),
        password: Yup.string(),
        confirmPassword: Yup.string()
            .when('password', {
                is: (password: string | any[]) => password && password.length > 0,
                then: Yup.string()
                    .oneOf([Yup.ref('password'), null], intl.formatMessage({ id: 'PASSWORD_MATCH' }))
                    .required(intl.formatMessage({ id: 'CONFIRM_PASSWORD_REQUIRED' })),
                otherwise: Yup.string().nullable()
            }),

        role: Yup.string().required(intl.formatMessage({ id: 'ROLE_REQUIRED' })),
    });

    useEffect(() => {
        loadMunicipios();
    }, [currentUser]);

    const loadMunicipios = async () => {
        if (currentUser) {
            try {
                // Llamar a la función de la API para obtener los municipios
                const municipios = await getMunicipios(Number(currentUser.id));
                setMunicipio(municipios.municipios);
            }
            catch (error) {
                console.error('Error al cargar los municipios:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Error al cargar los municipios',
                    icon: 'error',
                });
            }
        }
    };


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId) {
                    const response = await getUser(Number(userId));


                    // Establecer los valores iniciales del formulario después de obtener los datos del usuario
                    formik.setValues({
                        name: response?.name || '',
                        email: response?.email || '',
                        password: '', // No se incluye la contraseña inicialmente
                        confirmPassword: '', // No se incluye la contraseña inicialmente
                        role: response?.role || 'Administrador', // Si el rol está ausente, se asigna 'Administrador'
                        id_municipio: response.id_municipio
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]); // Asegúrate de incluir userId como dependencia

    const formik = useFormik<IUserFormValues>({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: 'Administrador', // Asignando 'Administrador' como valor por defecto
            id_municipio: 0
        },
        validationSchema: userSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {

                console.log("valores", values)
                await editUser(Number(userId), values); // Edita el usuario utilizando el ID y los nuevos valores
                Swal.fire({
                    title: 'Success',
                    text: 'User edited successfully',
                    icon: 'success',
                });
                navigate('/users/index');
            } catch (error) {
                console.error('Error editing user:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Error editing user',
                    icon: 'error',
                });
            } finally {
                setLoading(false);
            }
        },
    });



    return (
        <div className='card mb-1'>
            <div className='card-header border-1 cursor-pointer'>
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>
                        <h1>{intl.formatMessage({ id: 'EDIT_USER' })}</h1>
                    </h3>
                </div>
            </div>
            <div className='card-body py-3'>
                <form onSubmit={formik.handleSubmit}>
                    <div className='row'>
                        <div className='mb-3 col-md-6 mt-3'>
                            <label htmlFor='name' className='form-label'>
                                {intl.formatMessage({ id: 'NAME' })}
                            </label>
                            <input type='text' className='form-control' id='name' {...formik.getFieldProps('name')} />
                            {formik.touched.name && formik.errors.name && (
                                <div className='text-danger'>{formik.errors.name}</div>
                            )}
                        </div>

                        <div className='mb-3 col-md-6 mt-3'>
                            <label htmlFor='email' className='form-label'>
                                {intl.formatMessage({ id: 'EMAIL' })}
                            </label>
                            <input type='text' className='form-control' id='email' {...formik.getFieldProps('email')} />
                            {formik.touched.email && formik.errors.email && (
                                <div className='text-danger'>{formik.errors.email}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='idMunicipio' className='form-label'>
                                {intl.formatMessage({ id: 'SELECT_MUNICIPALITY' })}
                            </label>
                            <select
                                className='form-control'
                                id='id_municipio'
                                {...formik.getFieldProps('id_municipio')}
                            >
                                <option value=''>{intl.formatMessage({ id: 'SELECT_OPTION' })}</option>
                                {municipio.map((m) => (
                                    <option key={m.id} value={m.id}>{m.nombre}</option>
                                ))}
                            </select>
                            {formik.touched.id_municipio && formik.errors.id_municipio && (
                                <div className='text-danger'>{formik.errors.id_municipio}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='password' className='form-label'>
                                {intl.formatMessage({ id: 'PASSWORD' })}
                            </label>
                            <input
                                type='password'
                                className='form-control'
                                id='password'
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='text-danger'>{formik.errors.password}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='confirmPassword' className='form-label'>
                                {intl.formatMessage({ id: 'CONFIRM_PASSWORD' })}
                            </label>
                            <input
                                type='password'
                                className='form-control'
                                id='confirmPassword'
                                {...formik.getFieldProps('confirmPassword')}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                <div className='text-danger'>{formik.errors.confirmPassword}</div>
                            )}
                        </div>

                        <div className='mb-3'>
                            <label htmlFor='role' className='form-label'>
                                {intl.formatMessage({ id: 'ROLE' })}
                            </label>
                            <select className='form-select' id='role' {...formik.getFieldProps('role')}>
                                <option value=''>{intl.formatMessage({ id: 'SELECT_ROLE' })}</option>
                                <option value='Administrador'>{intl.formatMessage({ id: 'ADMINISTRATOR' })}</option>
                                <option value='Postulante'>{intl.formatMessage({ id: 'APPLICANT' })}</option>
                                <option value='Invitado'>{intl.formatMessage({ id: 'GUEST' })}</option>
                            </select>
                            {formik.touched.role && formik.errors.role && <div className='text-danger'>{formik.errors.role}</div>}
                        </div>

                        {/* Aquí puedes agregar más campos del formulario si es necesario */}
                    </div>
                    <div className="card-footer">
                        <div className="row justify-content-end mt-2">
                            <div className="col d-flex justify-content-end">
                                <button type="button" className="btn btn-secondary mx-2"
                                    onClick={() => navigate('/announcements/index')}
                                >
                                    {intl.formatMessage({ id: 'CANCEL' })}
                                </button>
                                <button type="submit" className="btn btn-primary ml-3" disabled={loading}>
                                    {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>

    );
};

export default Edit;
