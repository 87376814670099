import { KTSVG } from "../../../_metronic/helpers"
import { InvestigacionAntecedentes } from "./steps/investigacionAntecedentes"
import {Formik, Form, FormikValues, useFormik} from 'formik'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import {createReclutamientoSchemas, reclutamientoInitial as initialValues, Reclutamiento} from './model'
import { useEffect, useRef, useState } from "react"
import { ValoracionPsicologica } from "./steps/valoracionPsicologica"
import { ExamenToxicologico } from "./steps/examenToxicologico"
import { AnalisisHabitos } from "./steps/analisisHabitos"
import { Finalizar } from "./steps/finalizar"
import { useNavigate, useParams } from "react-router-dom"
import Swal from "sweetalert2"



const Create: React.FC = () => {
    const { solicitudId,convocatoriaId } = useParams<{ solicitudId: string,convocatoriaId: string }>(); // Obtenemos el solicitudId de los parámetros
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(createReclutamientoSchemas[0])
    const navigate = useNavigate();

    const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createReclutamientoSchemas[stepper.current.currentStepIndex - 1])
  }
  const finalStep = () => {
    console.log('No terminas')
  }
  const nextStep = () => {
    Swal.fire({
      title: 'Guardado',
      text: 'Terminaste de llenar los apartados de reclutamiento',
      icon: 'success',
    });
    navigate('/solicitudes/index-reclutamiento/'+ convocatoriaId);

  }

  const submitStep = (values: Reclutamiento, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createReclutamientoSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
    return (
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          {/* begin::Aside*/}
          <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
            {/* begin::Wrapper*/}
            <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
              {/* begin::Nav*/}
              <div className='stepper-nav'>
                {/* begin::Step 1*/}
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    {/* end::Icon*/}
    
                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Investigación de antecedentes</h3>
    
                      <div className='stepper-desc fw-semibold'>Investigación de antecedentes</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
    
                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 1*/}
    
                {/* begin::Step 2*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    {/* end::Icon*/}
    
                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Valoración Psicologica</h3>
                      <div className='stepper-desc fw-semibold'>Valoración Psicologica</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
    
                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 2*/}
    
                {/* begin::Step 3*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}
    
                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Analisis de Habitos y Trayectoria Laboral</h3>
                      <div className='stepper-desc fw-semibold'>Analisis de Habitos y Trayectoria Laboral</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
    
                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 3*/}
    
                {/* begin::Step 4*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    {/* end::Icon*/}
    
                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Examen Toxicologico</h3>
                      <div className='stepper-desc fw-semibold'>Examen Toxicologico</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
    
                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 4*/}
                {/* begin::Step 5*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>
                    {/* end::Icon*/}
    
                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Finalizar</h3>
                      <div className='stepper-desc fw-semibold'>Finalizar</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
    
                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 5*/}
    
              
              </div>
              {/* end::Nav*/}
            </div>
            {/* end::Wrapper*/}
          </div>
          {/* begin::Aside*/}
    
          <div className='bg-body rounded'>
            <Formik validationSchema={currentSchema} initialValues={initialValues} onSubmit={submitStep}>
              {() => (
                <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <InvestigacionAntecedentes solicitudId={parseInt(solicitudId!)} />
                  </div>
    
                  <div data-kt-stepper-element='content'>
                    <ValoracionPsicologica solicitudId={parseInt(solicitudId!)} />
                  </div>
    
                  <div data-kt-stepper-element='content'>
                    <AnalisisHabitos solicitudId={parseInt(solicitudId!)}/>
                  </div>
    
                  <div data-kt-stepper-element='content'>
                    <ExamenToxicologico solicitudId={parseInt(solicitudId!)} />
                  </div>
                  <div data-kt-stepper-element='content'>
                    <Finalizar />
                  </div>
    
                 
                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr063.svg'
                          className='svg-icon-4 me-1'
                        />
                        Anterior
                      </button>
                    </div>
    
                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3' 
                      onClick={stepper.current?.currentStepIndex ===
                        stepper.current?.totatStepsNumber! - 1 ?  nextStep :  finalStep}
                      >
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 && 'Continuar'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 && 'Finalizar'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )
}

export {Create}