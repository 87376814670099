import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import { useState } from "react";
import Swal from "sweetalert2";
import { createValoracion, updateValoracion } from "../api";
interface ValoracionPsicologicaProps {
  solicitudId: number | null;
}
const ValoracionPsicologica: React.FC<ValoracionPsicologicaProps> = ({ solicitudId}) => {

const [loading, setLoading] = useState(false)
const [saving, setSaving] = useState('card-footer d-flex justify-content-end py-6 px-9');
const [valoracionPsicologicaValue, setValoracion] = useState<number | undefined>(undefined);
const [updating, setUpdating] = useState('d-none');const formik = useFormikContext();


//const [swalProps, setSwalProps] = useState({});

  

const handleCreateValoracionPsicologica = async () => {
  const { values } = formik; // Obtener los valores actuales del formulario
  const errors = await formik.validateForm(); // Validar el formulario
  if (Object.keys(errors).length === 0) {
    // No hay errores de validación, ejecutar la acción
  setLoading(true);
    console.log('Valores actuales del formulario:', values);
    // Asegurar que values sea de tipo FormikValues
    const formValues = values as FormikValues;

    // Crear un nuevo objeto FormData
    const formData = new FormData();
    // Agregar los valores del formulario al objeto FormData
    Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value as string); // Asegurar que el valor sea de tipo string
    });
    formData.append('id_solicitud', solicitudId+'');

    try {
      const valoracionPsicologica = await createValoracion(formData);
      Swal.fire({
          title: 'Guardado',
          text: 'Valoracion psicologica registrada exitosamente',
          icon: 'success',
      });
      setSaving('d-none');
        setUpdating('card-footer d-flex justify-content-end py-6 px-9');
        if(valoracionPsicologica){
        setValoracion(valoracionPsicologica.id);
        }
      
  } catch (error) {
      console.error('Error al crear valoracion psicologica:', error);
      Swal.fire({
          title: 'Error',
          text: 'Error al crear valoracion psicologica',
          icon: 'error',
      });
  } finally {
      setLoading(false);
  }
  } else {
    // Mostrar los errores de validación
    Swal.fire({
      html: `
      <div class="spinner-container" style="overflow: hidden;">
        <i class="fas fa-spinner fa-spin"></i>
        <span>Se encontraron diversos errores</span> <ol>
        ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
        </ol></div>
      `,
      showConfirmButton: true,
      allowOutsideClick: true,
      

    });
  }
  //setLoading(false);
};


const handleUpdateValoracionPsicologica = async () => {
  const { values } = formik; // Obtener los valores actuales del formulario
  const errors = await formik.validateForm(); // Validar el formulario
  if (Object.keys(errors).length === 0) {
    // No hay errores de validación, ejecutar la acción
    setLoading(true);
    const formValues = values as FormikValues;

    // Crear un nuevo objeto FormData
    const formData = new FormData();
    // Agregar los valores del formulario al objeto FormData
    Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value as string); // Asegurar que el valor sea de tipo string
    });
    formData.append('id_solicitud', solicitudId+'');

    try {
      const valoracionPsicologica = await updateValoracion(valoracionPsicologicaValue!,formData);
      Swal.fire({
          title: 'Actualizado',
          text: 'Valoracion psicologica actualizada exitosamente',
          icon: 'success',
      });
      setSaving('d-none');
      setUpdating('card-footer d-flex justify-content-end py-6 px-9');
      if(valoracionPsicologica){
        setValoracion(valoracionPsicologica.id);
      }
      console.log(valoracionPsicologica);
      
  } catch (error) {
      console.error('Error al actualizar valoracion psicologica:', error);
      Swal.fire({
          title: 'Error',
          text: 'Error al actualizar valoracion psicologica',
          icon: 'error',
      });
  } finally {
      setLoading(false);
  }
  } else {
    // Mostrar los errores de validación
    Swal.fire({
      html: `
      <div class="spinner-container" style="overflow: hidden;">
        <i class="fas fa-spinner fa-spin"></i>
        <span>Se encontraron diversos errores</span> <ol>
        ${Object.values(errors).map(element => `<li>${element}</li>`).join('')}
        </ol></div>
      `,
      showConfirmButton: true,
      allowOutsideClick: true,
      

    });
  }
  //setLoading(false);
};
  
    return (
        <div className="card mb-5 mb-xl-10">
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Valoración Psicológica</h3>
        </div>
      </div>

          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de valoración</label>
                  <Field
                    name='fecha_valoracion'
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_valoracion' />
                    </div>
                </div>
            <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Sintesis</label>
                  <Field
                    name='sintesis'
                    type='text'
                    as='textarea'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='sintesis' />
                    </div>
                </div>

                <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Pruebas psicologicas aplicadas</label>
                  <Field
                    name='pruebas_psicologicas'
                    type='text'
                    as='textarea'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='pruebas_psicologicas' />
                    </div>
                </div>
                <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Resultado</label>
                  <Field
                    as='select'
                    name='resultado'
                    className='form-control form-control-lg form-control-solid'
                    > 
                    <option value="">Seleccione una opción</option>
                    <option value="Recomendable">Recomendable</option>
                    <option value="Recomendable con reservas">Recomendable con reservas</option>
                    <option value="No recomendable">No recomendable</option>

                    </Field>
                  
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='resultado' />
                    </div>
                </div>
                <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Fecha de emisión de resultado</label>
                  <Field
                    name='fecha_emision_resultado'
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='fecha_emision_resultado' />
                    </div>
                </div>
                <div className='col-lg-12 d-none'>
              <label className='form-label required fw-bold fs-6'>Valoración Psicologica</label>
                  <Field
                    name='id'
                    type='text'
                    value={valoracionPsicologicaValue}
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='id' />
                    </div>
                </div>

             
             
              </div>   
        </div>
            
        <div className={saving}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleCreateValoracionPsicologica}>
              {!loading && 'Guardar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className={updating}>
            <button type='button' className='btn btn-primary' disabled={loading} onClick={handleUpdateValoracionPsicologica}>
              {!loading && 'Actualizar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor espere...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
    </div>
    );
}

export {ValoracionPsicologica}
