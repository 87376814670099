import React from 'react';

interface UserListSearchProps {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void; // Eliminamos la llamada a handleSearch desde aquí
  handleReset: () => void;
}

const UserListSearch: React.FC<UserListSearchProps> = ({
  searchValue,
  setSearchValue,
  handleSearch, // Eliminamos handleSearch de las props
  handleReset,
}) => {
  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-6 mb-lg-0 mb-6">
          <label className="form-label">Buscar:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Título o contenido"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
       
      </div>
      <div className="row">
        <div className="col-md-12 d-flex justify-content-end mt-lg-0 mt-6">
          <button className="btn btn-primary me-3" onClick={handleSearch}> {/* Se mantiene el evento onClick en el botón de búsqueda */}
            Buscar
          </button>
          <button className="btn btn-secondary" onClick={handleReset}>
            Resetear
          </button>
        </div>
      </div>

    </>
  );
};

export default UserListSearch;
