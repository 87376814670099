import axios, { AxiosResponse } from "axios";
import { saveAs } from 'file-saver';

export const getData = async (solicitud:any):
Promise<{ data: any}> => {
    try{
        const queryString = `${process.env.REACT_APP_API_DEV_URL}/nomina/${solicitud}`;
        const response = await axios.get(queryString);
        console.log(response.data.solicitud);
        const data = response.data.solicitud;

        if (response.status !== 200) {
            throw new Error('Error obteniendo datos de la solicitud.');
        }

        return  {data};
    }catch (error) {
        throw error;
    }
 }

export const updateNomina = async (
    solicitud: number,
    formData: FormData
) => {
    try {
        console.log(solicitud);
        formData.append("_method", "PUT");
        const response = await axios.post(
            `${process.env.REACT_APP_API_DEV_URL}/nomina/${solicitud}`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        if (response.status !== 200) {
            throw new Error('Error actualizando modelo.');
        }
        return response.data.model;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const downloadCSV = async (user: number): Promise<void> => {
    try {
        const url = `${process.env.REACT_APP_API_DEV_URL}/getCSV/${user}`; 
        const response = await axios.get(url, {
            responseType: 'blob',  
        });

        saveAs(response.data, "nomina.csv");
    } catch (error) {
        console.error('Error al descargar el archivo CSV:', error);
        throw error;
    }
}

