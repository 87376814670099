import { useEffect, useMemo, useState } from "react";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import { CorreccionDisciplinariaModel } from "./model";
import { getCorreccionDisciplinaria } from "./api";
import { useAuth } from "../auth";
import { useParams } from "react-router-dom";
import CreateCorreccionDisciplinaria from "./create";
import EditCorreccionDisciplinaria from "./edit";
import DestroyCorreccionDisciplinaria from "./destroy";

const CorreccionesDisciplinarias: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedCorreccionDisciplinaria, setSelectedCorreccionDisciplinaria] = useState<CorreccionDisciplinariaModel | null>(null);
    const [correccionesDisciplinarias, setData] = useState<CorreccionDisciplinariaModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const {currentUser} = useAuth();
    const { solicitudId } = useParams<{ solicitudId: string }>(); // Obtenemos el solicitudId de los parámetros


    //Para mandar llamar el listado de correccionesDisciplinarias
    useEffect(() => {
        const fetchCorreccionesDisciplinarias = async () => {
          setIsLoading(true);
          setError('');
          try {
            const  {correccionesDisciplinarias}  = await getCorreccionDisciplinaria(solicitudId);
            setData(correccionesDisciplinarias);
            setIsLoading(false);
          } catch (error) {
            if (error instanceof Error) {
              setError(error.message); 
            } else {
              setError('Ocurrio un error inesperado'); 
            }
            setIsLoading(false);
          }
        };
    
        fetchCorreccionesDisciplinarias();
        
      },[]);
      
    //Para poder cambiar el estatus en memoria de los cambios
  const crearCorreccionDisciplinaria = (nuevaCorreccionDisciplinaria: CorreccionDisciplinariaModel) => {
    setData(correccionesDisciplinarias => [...correccionesDisciplinarias, nuevaCorreccionDisciplinaria]);
  };
 
 

  const actualizarCorreccionDisciplinaria = (nuevaCorreccionDisciplinaria: CorreccionDisciplinariaModel) => {
    setData(correccionesDisciplinarias => {
        const correccionDisciplinariaExistenteIndex = correccionesDisciplinarias.findIndex(correccionDisciplinaria => correccionDisciplinaria.id === nuevaCorreccionDisciplinaria.id);

        if (correccionDisciplinariaExistenteIndex !== -1) {
            const nuevasCorreccionesDisciplinarias = [...correccionesDisciplinarias];
            nuevasCorreccionesDisciplinarias[correccionDisciplinariaExistenteIndex] = nuevaCorreccionDisciplinaria;
            return nuevasCorreccionesDisciplinarias;
        } else {
            return [...correccionesDisciplinarias, nuevaCorreccionDisciplinaria];
        }
    });
};
const eliminarCorreccionDisciplinaria = (correccionDisciplinariaEliminar: CorreccionDisciplinariaModel) => {
  setData(correccionesDisciplinarias => {
      const nuevasCorreccionesDisciplinarias = correccionesDisciplinarias.filter(correccionDisciplinaria => correccionDisciplinaria.id !== correccionDisciplinariaEliminar.id);
      return nuevasCorreccionesDisciplinarias;
  });
};
  if (isLoading) {
    return <div>Por favor espere</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }


    const handleShowCreateModal = () => {
        setShowCreateModal(true);
    };
    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    };

    const handleShowEditModal = (sancion:CorreccionDisciplinariaModel) => {
        setSelectedCorreccionDisciplinaria(sancion);
        setShowEditModal(true);
    };
    const handleShowDestroyModal = (sancion:CorreccionDisciplinariaModel) => {
        setSelectedCorreccionDisciplinaria(sancion);
        setShowDestroyModal(true);
    };
    const handleCloseModal = () => {
        setShowEditModal(false);
        setShowDestroyModal(false);
      };

    return (
        
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                    <h3 className="card-title m-0">Correcciones Disciplinarias</h3>
                    <div>
                        <button className="btn btn-info" onClick={() => handleShowCreateModal()}>Nuevo <BsPlusCircle /></button>
                             <CreateCorreccionDisciplinaria solicitud={solicitudId} show={showCreateModal} handleClose={handleCloseCreateModal} data={correccionesDisciplinarias} agregarNuevaCorreccionDisciplinaria={crearCorreccionDisciplinaria}  />
                    </div>
            </div>
            <div className="card-body">
                <div className="row">
                {correccionesDisciplinarias.map((correccionDisciplinaria) => (
                    <div className="col-md-4 mt-2">
                    <div className="card shadow-lg">
                        <div className="card-header bg-primary text-white align-items-center">
                        {correccionDisciplinaria.tipo_correccion.nombre} 
                        </div>
                        <div className="card-body align-items-center">
                            <p className="text-info text-center">Superior que aplica: {correccionDisciplinaria.superior_aplica}</p>
                            <p className="text-bold text-center">Duración: {correccionDisciplinaria.duracion}  {correccionDisciplinaria.cantidad_duracion} </p>
                            <p className="text-bold text-center">Fecha de determinación: {correccionDisciplinaria.fecha_determinacion} </p>
                            <p className="text-bold text-center">¿Cumplio?: {correccionDisciplinaria.cumplimiento == 0 ? 'No' : 'Si'}</p>
                   

                            <button className="btn btn-warning m-2" onClick={() => handleShowEditModal(correccionDisciplinaria)}><BsPencil/></button>
               <button className="btn btn-danger m-2" onClick={() => handleShowDestroyModal(correccionDisciplinaria)}><BsTrash /></button>
                
                        </div>
                    </div>
                    </div>
                    ))}
                      <EditCorreccionDisciplinaria correccionDisciplinaria={selectedCorreccionDisciplinaria} show={showEditModal} handleClose={handleCloseModal} modificarCorreccionDisciplinaria={actualizarCorreccionDisciplinaria} solicitud={solicitudId}/>
                    
                    <DestroyCorreccionDisciplinaria correccionDisciplinaria={selectedCorreccionDisciplinaria} show={showDestroyModal} handleClose={handleCloseModal} eliminarCorreccionDisciplinaria={eliminarCorreccionDisciplinaria}/>
                    
                    
                </div>
                
            </div>
        </div>
    
            
      );
};

export default CorreccionesDisciplinarias;
    