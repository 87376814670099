/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          ¿Como se entero del hecho?
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Por favor seleccione una opción.
       </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='infrigmentType'
              value='directPresence'
              id='directPresence'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='directPresence'
            >
              <KTSVG
                path='/media/icons/duotune/technology/teh002.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Presencia directa en la comisión de la probable infracción:</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  (Flagrancia)
                </span>
              </span>
            </label>
          </div>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='infrigmentType'
              value='emergencyCall'
              id='emergencyCall'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='emergencyCall'
            >
              <KTSVG
                path='/media/icons/duotune/communication/com005.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Llamada de emergencia</span>
                <span className='text-gray-400 fw-bold fs-6'>
                911 No.
                </span>
              </span>
            </label>
          </div>
          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='infrigmentType'
              value='complaintPresent'
              id='complaintPresent'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
              htmlFor='complaintPresent'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen004.svg'
                className='svg-icon-3x me-5'
              />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Queja</span>
                <span className='text-gray-400 fw-bold fs-6'>
                (petición de parte):
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-6'>
            <Field
              type='radio'
              className='btn-check'
              name='infrigmentType'
              value='otherInfrigment'
              id='otherInfrigment'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
              htmlFor='otherInfrigment'
            >
              <KTSVG path='/media/icons/duotune/general/gen046.svg' className='svg-icon-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>Otro</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  (especifique):
                </span>
              </span>
            </label>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='infrigmentType' />
          </div>

          
          <div className='col-lg-12'>
              <label className='form-label required fw-bold fs-6'>Especifique</label>
                  <Field
                    name='specification'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                    <ErrorMessage name='specification' />
                    </div>
                </div>
        </div>
      </div>
    </div>
  )
}

export {Step4}
