import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsPencil, BsPlusCircle, BsTrash } from "react-icons/bs";
import CreateInstancia from "./createInstancias"; 
import EditInstancia from "./editInstancias"; 
import DestroyInstancia from "./destroyInstancias"; 
import { Instancia } from "./model"; 
import { getInstancias } from "./apiInstancias";
import { useAuth } from "../auth";

const Instancias = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [selectedInstancia, setSelectedInstancia] = useState<Instancia | null>(null);
    const [data, setData] = useState<Instancia[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchInstancias = async () => {
            setIsLoading(true);
            setError('');
            try {
                const { instancia } = await getInstancias();
                setData(instancia);
                setIsLoading(false);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                } else {
                    setError('Ocurrió un error inesperado');
                }
                setIsLoading(false);
            }
        };

        fetchInstancias();
        
    }, [currentUser]);

    const crearInstancia = (nuevaInstancia: Instancia) => {
        setData(instancias => [...instancias, nuevaInstancia]);
    };

    const actualizarInstancia = (instanciaActualizada: Instancia) => {
        setData(instancias => instancias.map(instancia => instancia.id === instanciaActualizada.id ? instanciaActualizada : instancia));
    };

    const eliminarInstancia = (instanciaEliminar: Instancia) => {
        setData(instancias => instancias.filter(instancia => instancia.id !== instanciaEliminar.id));
    };

    if (isLoading) {
        return <div>Por favor espere...</div>;
    }

    if (error) {
        return <div>Ocurrió un error: {error}</div>;
    }

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="card-title m-0">Instancias</h3>
                <Button onClick={() => setShowCreateModal(true)}>Nuevo <BsPlusCircle /></Button>
            </div>
            <div className="card-body">
                <div className="row">
                    {data.map((instancia) => (
                        <div className="col-md-4 mt-2" key={instancia.id}>
                            <div className="card shadow-lg">
                                <div className="card-body align-items-center">
                                    <p className="text-bold text-center">{instancia.nombre}</p>
                                    <Button variant="warning" onClick={() => {
                                        setSelectedInstancia(instancia);
                                        setShowEditModal(true);
                                    }}><BsPencil /></Button>
                                    <Button variant="danger" onClick={() => {
                                        setSelectedInstancia(instancia);
                                        setShowDestroyModal(true);
                                    }}><BsTrash /></Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {showCreateModal && <CreateInstancia show={showCreateModal} handleClose={() => setShowCreateModal(false)} agregarNuevaInstancia={crearInstancia} />}
                {selectedInstancia && showEditModal && <EditInstancia instancia={selectedInstancia} show={showEditModal} handleClose={() => setShowEditModal(false)} modificarInstancia={actualizarInstancia} />}
                {selectedInstancia && showDestroyModal && <DestroyInstancia instancia={selectedInstancia} show={showDestroyModal} handleClose={() => setShowDestroyModal(false)} eliminarInstancia={eliminarInstancia} />}
            </div>
        </div>
    );
};

export default Instancias;

    