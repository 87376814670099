import React, { useEffect, useState } from 'react';
import { ISolicitud } from '../model';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsCardChecklist, BsCheck, BsEye, BsPencil, BsPeople, BsPersonCheck, BsTrash, BsX } from 'react-icons/bs';
import { actualizarEstadoSolicitud } from '../api';
import Swal from 'sweetalert2';

interface SolicitudesListBodyProps {
  data: ISolicitud[];
  isLoading: boolean;
  handleDetail: (solicitudId: number) => void; // Agrega handleDetail como prop
  handleReclutamiento: (solicitudId: number) => void; 
  onRemoveSolicitud: (solicitudId: number) => void; // Nueva prop
}
interface StatusMessages {
  [key: number]: string;
}

const SolicitudesListBody: React.FC<SolicitudesListBodyProps> = ({ data, isLoading, handleDetail,handleReclutamiento, onRemoveSolicitud }) => {
  const [selectedStatus, setSelectedStatus] = useState<{ [id: number]: number }>({});

  const statusMessages: StatusMessages = {
    3: 'No calificado',
    4: 'Rechazado',
    6: 'Reclutamiento',
    7: 'Selección'
  };
  const getStatusMessage = (status: number): string => {
    return statusMessages[status] || 'Desconocido';
  };

  const toggleStatus = async (id: number, status: number) => {
    const statusChangeMessage = `¿Quieres cambiar el estado de esta solicitud a "${getStatusMessage(status)}"?`;
    Swal.fire({
      title: '¿Estás seguro?',
      text: statusChangeMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cambiar estado',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const newStatus = selectedStatus[id] === status ? 0 : status;
        setSelectedStatus({ ...selectedStatus, [id]: newStatus });
  
        if (newStatus !== 0) {
          actualizarEstadoSolicitud(id, newStatus).then(() => {
            console.log(`Estado actualizado para la solicitud ${id} a ${getStatusMessage(newStatus)}`);
            onRemoveSolicitud(id); // Usa la prop para eliminar la fila si es necesario
            Swal.fire(
              'Actualizado!',
              `El estado de la solicitud ha sido actualizado a "${getStatusMessage(newStatus)}".`,
              'success'
            )
          }).catch((error) => {
            console.error('Error al actualizar el estado:', error);
            Swal.fire(
              'Error!',
              'No se pudo actualizar el estado de la solicitud.',
              'error'
            )
          });
        }
      }
    })
  };
  

  return (
    <>

      <tbody>
        {isLoading ? ( // Mostrar mensaje de carga mientras se realiza la búsqueda
          <tr>
            <td colSpan={5}>Cargando...</td>
          </tr>
        ) : (
          data.map((solicitud) => (
            <tr key={solicitud.id}>
              <td>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-6`}>
                        Contestar formularios de Reclutamiento: Investigación de antecedentes, Valoración psicologica, Analisis de habitos y Examen toxicologico.
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0px 0px 8px rgba(255, 193, 7, 0.5)' ,
                        border: '1px solid #ccc'
                      }}
                      onClick={() => handleReclutamiento(solicitud.id)}
                    >
                      <BsCardChecklist  color='#17a2b8' size="1.5em" />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-7`}>
                        Selección - En esta fase se contestarán los apartados de evaluación de confianza, formación inicial y evaluación al desempeño. 
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: selectedStatus[solicitud.idEstatusSolicitud!] === 7 ? '#17a2b8' : '#f8f9fa',
                        boxShadow: selectedStatus[solicitud.idEstatusSolicitud!] === 7 ? '0px 0px 8px rgba(23, 162, 184, 0.5)' : 'none',
                        border: '1px solid #ccc'
                      }}
                      onClick={() => toggleStatus(solicitud.idEstatusSolicitud!, 7)}
                    >
                      <BsPersonCheck color={selectedStatus[solicitud.idEstatusSolicitud!] === 7 ? '#fff' : '#28a745'} size="1.5em" />
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top-4`}>
                        No calificado - La solicitud ha sido evaluada y determinada como no adecuada para el puesto. Este estado indica que el solicitante no cumplió con los criterios necesarios para avanzar en el proceso de selección.
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        backgroundColor: selectedStatus[solicitud.id] === 4 ? '#dc3545' : '#f8f9fa',
                        boxShadow: selectedStatus[solicitud.id] === 4 ? '0px 0px 8px rgba(220, 53, 69, 0.5)' : 'none',
                        border: '1px solid #ccc'
                      }}
                      onClick={() => toggleStatus(solicitud.id, 4)}
                    >
                      <BsX color={selectedStatus[solicitud.id] === 4 ? '#fff' : '#dc3545'} size="1.5em" />
                    </div>
                  </OverlayTrigger>
                  </div>
              </td>
              <td>{solicitud.id}</td>
              <td>{solicitud.nombre} {solicitud.primer_apellido} {solicitud.segundo_apellido}</td>
              <td>{solicitud?.curp}</td>
              <td>{solicitud.fecha_solicitud}</td>
              <td>{solicitud.municipio_residencia?.nombre}</td>

              <td>{solicitud.email}</td>

              <td>
                <ButtonGroup aria-label="Actions">
                  <Button variant="info" onClick={() => handleDetail(solicitud.id)}> {/* Agrega onClick para llamar a handleDetail */}
                    <BsEye /> {/* Cambia el icono por uno que represente "Ver detalle" */}
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

export default SolicitudesListBody;
