import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Index } from './index'
import { Create } from './create'


const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Elementos',
        path: '/elementos',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const ElementoPage = () => (
    <Routes>



        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Elementos</PageTitle>
                    <Index />
                </>
            }
        />
        <Route
            path='create'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Elementos</PageTitle>
                    <Create />
                </>
            }
        />

        <Route index element={<Navigate to='/elementos/index' />} />
       

        
    </Routes>
)

export default ElementoPage