import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import CorreccionesDisciplinarias from './index'



const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Correcciones Disciplinarias',
        path: '/correcciones-disciplinarias',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CorreccionesDisciplinariasPage = () => (
    <Routes>



        <Route
            path=':solicitudId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Correcciones Disciplinarias</PageTitle>
                    <CorreccionesDisciplinarias />
                </>
            }
        />

        <Route index element={<Navigate to='/correcciones-disciplinarias/:solicitudId' />} />

    </Routes>
)

export default CorreccionesDisciplinariasPage
