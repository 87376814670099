import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Correccion, CorreccionFormValues } from './model';
import { createCorreccion } from './apiCorrecciones';
import Swal from 'sweetalert2';
import { useAuth } from '../auth';

interface CreateCorreccionesModalProps {
  show: boolean;
  handleClose: () => void;
  data: Correccion[];
  agregarNuevaCorreccion: (correccion: Correccion) => void;
}

const CreateCorreccion: React.FC<CreateCorreccionesModalProps> = ({ show, handleClose, data, agregarNuevaCorreccion }) => {  
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const correccionSchema = Yup.object().shape({
    nombre: Yup.string().required(intl.formatMessage({ id: 'NAME_REQUIRED' })),
  });

  const formik = useFormik<CorreccionFormValues>({
    initialValues: {
      nombre: '',
    },
    validationSchema: correccionSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('nombre', values.nombre);
        const nuevaCorreccion = await createCorreccion(formData);

        Swal.fire({
          title: 'Guardado',
          text: 'Corrección creada exitosamente',
          icon: 'success',
        });

        agregarNuevaCorreccion(nuevaCorreccion);
        handleClose();
        navigate('/catalogos/index');
      } catch (error) {
        console.error('Error creando una corrección:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creando una corrección',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className='bg-primary'>
        <Modal.Title className='text-white'>Nueva Corrección</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="mb-3 col-md-12 mt-3">
              <label htmlFor="nombre" className="form-label">{intl.formatMessage({ id: 'NAME' })}</label>
              <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
              {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <button type="submit" className="btn btn-success ml-3" disabled={loading}>
            {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateCorreccion;
