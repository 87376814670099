
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CompetenciaModel } from './model';
import { deleteModel } from './api';
import Swal from 'sweetalert2';
//import { IAnnouncement } from './model';

interface DestroyModalProps {
    show: boolean;
    handleClose: () => void;
    eliminarModel: any;
    modelo: CompetenciaModel | null;
}

const Destroy: React.FC<DestroyModalProps> = ({ show, handleClose, eliminarModel, modelo }) => {  
    const [loading, setLoading] = useState(false);

  const handleDelete = async (modeloId: number) => {
    setLoading(true);

    try{
        //Lo eliminamos de la base de datos
        const modelo = await deleteModel(modeloId);
        Swal.fire({
            title: 'Eliminado',
            text: 'Eliminada exitosamente.',
            icon: 'warning',
          });
        //Lo eliminamos de la vista
        eliminarModel(modelo);
        //Cerramos el modal
        handleClose();

    }catch(error) {
        Swal.fire({
          title: 'Error',
          text: 'Error eliminando.',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
  }
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-danger'>
                <Modal.Title className='text-white'>Eliminar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Estas seguro de eliminar este registro?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="button" disabled={loading} className='btn btn-danger' onClick={() => handleDelete(modelo!.id)}>Eliminar</button>

            </Modal.Footer>

        </Modal>
    );
};


export default Destroy;