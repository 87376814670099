import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { createAnnouncement } from './api';
import { IAnnouncementFormValues } from './model';


const Create = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);

  const announcementSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({ id: 'TITLE_REQUIRED' })),
    description: Yup.string().required(intl.formatMessage({ id: 'DESCRIPTION_REQUIRED' })),
    start_date: Yup.date().required(intl.formatMessage({ id: 'START_DATE_REQUIRED' })),
    end_date: Yup.date().required(intl.formatMessage({ id: 'END_DATE_REQUIRED' })),
    location: Yup.string().required(intl.formatMessage({ id: 'LOCATION_REQUIRED' })),
    contact_reference: Yup.string().required(intl.formatMessage({ id: 'CONTACT_REFERENCE_REQUIRED' })),
    image_url: Yup.mixed().test('fileSize', 'La imagen debe ser menor a 2MB', (value) =>
      value ? value.size <= 2000000 : true
    ),
  });

  const formik = useFormik<IAnnouncementFormValues>({
    initialValues: {
      name: '',
      description: '',
      start_date: '',
      end_date: '',
      location: '',
      contact_reference: '',
      requirements: '',
      selection_process: '',
      required_documents: '',
      image_url: undefined,
    },
    validationSchema: announcementSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('description', values.description);
        formData.append('start_date', values.start_date);
        formData.append('end_date', values.end_date);
        formData.append('location', values.location);
        formData.append('contact_reference', values.contact_reference);
        formData.append('requirements', values.requirements);
        formData.append('selection_process', values.selection_process);
        formData.append('required_documents', values.required_documents);
        if (values.image_url) {
          formData.append('image', values.image_url);
        }

        await createAnnouncement(formData);
        Swal.fire({
          title: 'Success',
          text: 'Announcement created successfully',
          icon: 'success',
        });
        navigate('/announcements/index');
      } catch (error) {
        console.error('Error creating announcement:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creating announcement',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      formik.setFieldValue('image_url', file);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };



  return (
    <div className='card mb-1 '>
      <div
        className='card-header border-1 cursor-pointer'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            <h1>{intl.formatMessage({ id: 'CREATE_ANNOUNCEMENT' })}</h1>
          </h3>
        </div>
      </div>
      <div className='card-body py-3'>
        <form onSubmit={formik.handleSubmit}>

          <div className="row">
            <div className="mb-3 col-md-6 mt-3">
              <label htmlFor="name" className="form-label">{intl.formatMessage({ id: 'TITLE' })}</label>
              <input type="text" className="form-control" id="title" {...formik.getFieldProps('name')} />
              {formik.touched.name && formik.errors.name && <div className="text-danger">{formik.errors.name}</div>}
            </div>

            <div className="mb-3 col-md-6 mt-3">
              <label htmlFor="contact_reference" className="form-label">{intl.formatMessage({ id: 'CONTACT_REFERENCE' })}</label>
              <input type="text" className="form-control" id="contact_reference" {...formik.getFieldProps('contact_reference')} />
              {formik.touched.contact_reference && formik.errors.contact_reference && <div className="text-danger">{formik.errors.contact_reference}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="image" className="form-label">Imagen</label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                className="form-control"
                onChange={handleImageChange}
              />
              {imagePreview && <img src={imagePreview} alt="Preview" style={{ marginTop: '10px', maxWidth: '100%' }} />}
              {formik.touched.image_url && formik.errors.image_url && <div className="text-danger">{formik.errors.image_url}</div>}
            </div>



            <div className="mb-3 col-md-6 mt-3">
              <label htmlFor="start_date" className="form-label">{intl.formatMessage({ id: 'START_DATE' })}</label>
              <input type="date" className="form-control" id="start_date" {...formik.getFieldProps('start_date')} />
              {formik.touched.start_date && formik.errors.start_date && <div className="text-danger">{formik.errors.start_date}</div>}
            </div>


            <div className="mb-3 col-md-6 mt-3">
              <label htmlFor="end_date" className="form-label">{intl.formatMessage({ id: 'END_DATE' })}</label>
              <input type="date" className="form-control" id="end_date" {...formik.getFieldProps('end_date')} />
              {formik.touched.end_date && formik.errors.end_date && <div className="text-danger">{formik.errors.end_date}</div>}
            </div>


            <div className="mb-3">
              <label htmlFor="location" className="form-label">{intl.formatMessage({ id: 'LOCATION' })}</label>
              <input type="text" className="form-control" id="location" {...formik.getFieldProps('location')} />
              {formik.touched.location && formik.errors.location && <div className="text-danger">{formik.errors.location}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="description" className="form-label">{intl.formatMessage({ id: 'DESCRIPTION' })}</label>
              <textarea className="form-control" id="description" {...formik.getFieldProps('description')} />
              {formik.touched.description && formik.errors.description && <div className="text-danger">{formik.errors.description}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="requirements" className="form-label">{intl.formatMessage({ id: 'REQUIREMENTS' })}</label>
              <textarea className="form-control" id="requirements" {...formik.getFieldProps('requirements')} />
              {formik.touched.requirements && formik.errors.requirements && <div className="text-danger">{formik.errors.requirements}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="selection_process" className="form-label">{intl.formatMessage({ id: 'SELECTION_PROCESS' })}</label>
              <textarea className="form-control" id="selection_process" {...formik.getFieldProps('selection_process')} />
              {formik.touched.selection_process && formik.errors.selection_process && <div className="text-danger">{formik.errors.selection_process}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="required_documents" className="form-label">{intl.formatMessage({ id: 'REQUIRED_DOCUMENTS' })}</label>
              <textarea className="form-control" id="required_documents" {...formik.getFieldProps('required_documents')} />
              {formik.touched.required_documents && formik.errors.required_documents && <div className="text-danger">{formik.errors.required_documents}</div>}
            </div>



          </div>

          <div className="card-footer">
            <div className="row justify-content-end mt-2">
              <div className="col d-flex justify-content-end">
                <button type="button" className="btn btn-secondary mx-2"
                  onClick={() => navigate('/announcements/index')}
                >
                  {intl.formatMessage({ id: 'CANCEL' })}
                </button>
                <button type="submit" className="btn btn-primary ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  );
};

export default Create;
