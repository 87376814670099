
import 'leaflet/dist/leaflet.css';

import { Card, Col, Row } from 'react-bootstrap';

const DashboardWrapper: React.FC = () => {
 
  return (
      <div className="p-4 dashboard-wrapper">
      <Card className="main-card">
        <Card.Body>
          <Row>
            {/* Aspirantes */}
            <Col md={4} className="mb-4">
              <Card className="child-card">
                <Card.Body className="text-center">
                  <i className="fas fa-users icon-style"></i>
                  <Card.Title>ASPIRANTES</Card.Title>
                </Card.Body>
              </Card>
            </Col>

            {/* Activos */}
            <Col md={4} className="mb-4">
              <Card className="child-card">
                <Card.Body className="text-center">
                  <i className="fas fa-user-plus icon-style"></i>
                  <Card.Title>ACTIVOS</Card.Title>
                </Card.Body>
              </Card>
            </Col>

            {/* Bajas */}
            <Col md={4} className="mb-4">
              <Card className="child-card">
                <Card.Body className="text-center">
                  <i className="fas fa-user-minus icon-style"></i>
                  <Card.Title>BAJAS</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
    // <>
    //   <h1>Hola mundo</h1>
    //   <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
    //     <TileLayer
    //       attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //     />
    //     <Marker position={position} icon={customIcon}>
    //       <Popup>
    //         A pretty CSS3 popup. <br /> Easily customizable.
    //       </Popup>
    //     </Marker>
    //     {/* Agrega otro marcador con el mismo icono o diferentes opciones */}
    //     <Marker position={[22.349, -102.87]} icon={customIcon}>
    //       <Popup>
    //         Otro marcador personalizado.
    //       </Popup>
    //     </Marker>
    //   </MapContainer>
    // </>
  );
};

export { DashboardWrapper };
