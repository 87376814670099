import * as Yup from 'yup'

export interface Authority {
    name: string;
    surname: string;
    secondSurname: string | null;
    position: string;
    adscription: string;
    otherAdscription: string|null;
    intervetionUnit: number;
    madeAvailableId: number;
  }

export const authorityInitial: Authority = {
    name: "",
    surname: "",
    secondSurname: "",
    position: "",
    adscription: "",
    otherAdscription: "",
    intervetionUnit: 2,
    madeAvailableId: 8,
  }
export interface MadeAvailable {
    date: string;
    time: string;
    arrests: number;
    vehicles: number;
  }

export interface Receiver {
    rName: string;
    rPosition: string;
    rAdscription: string;
  }

export interface probableInfringements{
  infrigmentType: string;
  specification: string;
}

  const createAuthoritySchemas = [
    //Validaciones por cada paso
    Yup.object({
      date: Yup.string().required('Por favor ingrese la fecha de la puesta a disposición'),
      time: Yup.string().required('Por favor ingrese la hora de la puesta a disposición'),
      arrests: Yup.number().required('Por favor ingrese el número de detenidos'),
      vehicles: Yup.number().required('Por favor ingrese el número de vehículos '),
    }),
    Yup.object({
    name: Yup.string().required('Por favor ingrese el primer nombre'),
    surname: Yup.string().required('Por favor ingrese el primer apellido'),
    secondSurname: Yup.string().required('Por favor ingrese el segundo apellido'),
    position: Yup.string().required('Por favor ingrese el puesto'),
    adscription: Yup.string().required('Por favor ingrese su adscripción'),
    //otherAdscription: Yup.string().required('Favor de especificar que otra adscripción'),
    intervetionUnit: Yup.number().required('Por favor ingrese la unidad de intervención'),
    //madeAvailableId: Yup.number().required('Es necesario seleccionar el apartado de puestas a disposición'),
     // accountName: Yup.string().required('Account Name'),
    }),
    Yup.object({
    rName: Yup.string().required('Por favor ingrese el nombre completo de quien recibe la puesta a disposición'),
    rPosition: Yup.string().required('Por favor ingrese el cargo de quien recibe la puesta a disposición'),
    rAdscription: Yup.string().required('Por favor ingrese la adscripción de quien recibe la puesta a disposición'),

    }),
    Yup.object({
      infrigmentType: Yup.string().required('Es necesario seleccionar como se entero del hecho.'),
      specification: Yup.string().required('Por favor especifique la clave proporcionada por 911.'),
    }),
  ]
export {createAuthoritySchemas}
