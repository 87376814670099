
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'; // Asegúrate de importar useNavigate
const HomeSolicitudes: React.FC = () => {
    let navigate = useNavigate();

    const { convocatoriaId } = useParams();
    return (
        <div className="p-4 dashboard-wrapper">
            <Card className="main-card">
                <Card.Body>
                    <Row>
                        {/* Aspirantes */}
                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-aspirantes/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-users icon-style"></i>
                                        <Card.Title>ASPIRANTES</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>

                        {/* Activos */}
                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-aprobados/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-user-plus icon-style"></i>
                                        <Card.Title>APROBADOS</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>

                        {/* Bajas */}
                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-no-calificados/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-user-minus icon-style"></i>
                                        <Card.Title>NO CALIFICADOS</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>

                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-reclutamiento/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-check-square icon-style"></i>
                                        <Card.Title>RECLUTAMIENTO</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>

                        {/* Seleccion */}
                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-seleccion/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-list icon-style"></i>
                                        <Card.Title>SELECCIÓN</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-activos/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-person-rays icon-style"></i>
                                        <Card.Title>ACTIVOS</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        {/* Nombramiento */}
                        <Col md={4} className="mb-4">
                            <div onClick={() => navigate(`/solicitudes/index-nombramiento/${convocatoriaId}`)}> {/* Agrega la redirección aquí */}
                                <Card className="child-card clickable">
                                    <Card.Body className="text-center">
                                        <i className="fas fa-user-check icon-style"></i>
                                        <Card.Title>NOMBRAMIENTO</Card.Title>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>

    );
};

export { HomeSolicitudes };
