import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

import SolicitudesDetail from './SolicitudesDetail'
import { HomeSolicitudes } from './HomeSolicitudes'
import SolicitudesListAspirantes from './aspirantes/SolicitudesList'
import SolicitudesListNoCalificados from './no-calificados/SolicitudesList'
import SolicitudesListAprobados from './aprobados/SolicitudesList'
import SolicitudesListSeleccion from './seleccion/SolicitudesList'
import SolicitudesListNombramiento from './nombramiento/SolicitudesList'
import SolicitudesListReclutamiento from './reclutamiento/SolicitudesList'
import SolicitudesListActivos from './activos/SolicitudesList'


const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Solicitudes',
        path: '/solicitud',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const SolicitudesPage = () => (
    <Routes>


        <Route
            path='home/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Solicitudes</PageTitle>
                    <HomeSolicitudes />
                </>
            }
        />

        <Route
            path='index-aspirantes/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Aspirantes</PageTitle>
                    <SolicitudesListAspirantes />
                </>
            }
        />



        <Route
            path='index-aprobados/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Aprobados</PageTitle>
                    <SolicitudesListAprobados />
                </>
            }
        />

        <Route
            path='index-no-calificados/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>No calificados</PageTitle>
                    <SolicitudesListNoCalificados />
                </>
            }
        />

        <Route
            path='index-seleccion/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Selección</PageTitle>
                    <SolicitudesListSeleccion />
                </>
            }
        />
        <Route
            path='index-activos/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Activos</PageTitle>
                    <SolicitudesListActivos />
                </>
            }
        />
        <Route
            path='index-reclutamiento/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Reclutamiento</PageTitle>
                    <SolicitudesListReclutamiento />
                </>
            }
        />

        <Route
            path='detail/:solicitudId' // Ruta para ver detalle de solicitud
            element={<SolicitudesDetail />} // Componente que muestra el detalle de la solicitud
        />

        <Route
            path='index-nombramiento/:convocatoriaId'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Nombramiento y certificación</PageTitle>
                    <SolicitudesListNombramiento />
                </>
            }
        />



        <Route index element={<Navigate to='/home/:convocatoriaId' />} />

    </Routes>
)

export default SolicitudesPage
