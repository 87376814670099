
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IAnnouncement } from './model';

interface AnnouncementModalProps {
    show: boolean;
    handleClose: () => void;
    announcement: IAnnouncement | null;
}

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({ show, handleClose, announcement }) => {
    const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL || '';

    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{announcement?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {announcement && announcement.image_url && (
                    <div>
                        <p>Imagen:</p>
                        <img src={`${imageBaseUrl}${announcement.image_url}`} alt="Announcement Image" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </div>
                )}

                <p>Descripción: {announcement?.description}</p>
                <p>Fecha de inicio: {announcement ? new Date(announcement.start_date).toLocaleDateString() : ''}</p>
                <p>Fecha de fin: {announcement ? new Date(announcement.end_date).toLocaleDateString() : ''}</p>
                <p>Ubicación: {announcement?.location}</p>
                <p>Referencia de contacto: {announcement?.contact_reference}</p>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>

            </Modal.Footer>
        </Modal>
    );
};

export default AnnouncementModal;