import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import * as Yup from 'yup';
import { ElementoFormValues, Estado, Municipio, Nacionalidad } from "./model";
import { FormikValues, useFormik } from "formik";
import Swal from "sweetalert2";
import { createElemento, getEstados, getMunicipios, getMunicipiosCorporaciones, getNacionalidades } from "./api";

const Create: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadMunicipioNacimiento, setMunicipioNacimiento] = useState(true);
    const [loadMunicipioActual, setMunicipioActual] = useState(true);
    const [estadoNacimiento, setEstadoNacimiento] = useState();
    const [estadoActual, setEstadoActual] = useState();
    const {currentUser} = useAuth();
    //Para los listados
    const [dataEstados, setEstados] = useState<Estado[]>([]);
    const [dataNacionalidades, setDataNacionalidad] = useState<Nacionalidad[]>([]);
    const [dataMunicipiosNacimiento, setDataMunicipiosNacimiento] = useState<Municipio[]>([]);
    const [dataMunicipiosActual, setDataMunicipiosActual] = useState<Municipio[]>([]);
    const [dataMunicipioCorporacion, setDataMunicipioCorporacion] = useState<Municipio[]>([]);// Municipios
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
  const elementoSchema = Yup.object().shape({
    nombre: Yup.string().required('Es necesario ingresar un nombre').max(150,'Solamente se aceptan 150 caracteres'),
    email: Yup.string().required('Es necesario ingresar un email').email('El email ingresado no es valido'),
    primer_apellido: Yup.string().required('Es necesario ingresar el primer apellido').max(150,'Solamente se aceptan 150 caracteres'),
    segundo_apellido: Yup.string().required('Es necesario ingresar el segundo apellido').max(150,'Solamente se aceptan 150 caracteres'),
    telefono: Yup.number().max(9999999999,'Número no valido').min(1111111111,'Número no valido').required('Es necesario ingresar el número de teléfono'),
    celular: Yup.number().max(9999999999,'Número no valido').min(1111111111,'Número no valido').required('Es necesario ingresar el número de celular'),
    estatura: Yup.number().max(250,'Estatura no valida').min(120,'Estatura no valida').required('Es necesario ingresar la estatura del elemento'),
    peso: Yup.number().max(150,'Peso no valido').min(30,'Peso no valido').required('Es necesario ingresar el peso'),
    codigo_postal: Yup.number().max(99999,'Código postal no valido').min(10000,'Código postal no valido').required('Es necesario ingresar el código postal'),
    fecha_nacimiento: Yup.date().max('2006-12-31', 'El elemento debe ser mayor de edad').required('Por favor ingrese la fecha de nacimiento'),
    domicilio: Yup.string().required('Es necesario ingresar el domicilio').max(250,'Solamente se aceptan 250 caracteres'),
    ciudad: Yup.string().required('Es necesario ingresar la ciudad').max(250,'Solamente se aceptan 250 caracteres'),
    estado_civil: Yup.string().required('Es necesario ingresar el estado civil').max(30,'Solamente se aceptan 30 caracteres'),
    sexo: Yup.string().required('Es necesario ingresar el sexo').max(10,'Solamente se aceptan 10 caracteres'),
    colonia: Yup.string().required('Es necesario ingresar la colonia').max(100,'Solamente se aceptan 100 caracteres'),
    pais_nacimiento: Yup.string().required('Es necesario ingresar un pais de nacimiento').max(100,'Solamente se aceptan 100 caracteres'),
    idEstado: Yup.number().typeError('El estado no es valido').integer('El estado no es valido').required('Es necesario ingresar un estado').test('is-number', 'El estado no es valido', value => {
      if (!value) return true; 
      return !isNaN(value); 
    }),
    idEstadoNacimiento: Yup.number().typeError('El estado de nacimiento no es valido').integer('El estado de nacimiento no es valido').required('Es necesario ingresar un estado').test('is-number', 'El estado de nacimiento no es valido', value => {
      if (!value) return true; 
      return !isNaN(value); 
    }),
    idMunicipio: Yup.number().typeError('El municipio no es valido').integer('El municipio no es valido').required('Es necesario ingresar un municipio').test('is-number', 'El municipio no es valido', value => {
      if (!value) return true; 
      return !isNaN(value); 
    }),
    idMunicipioNacimiento: Yup.number().typeError('El municipio de nacimiento no es valido').integer('El municipio de nacimiento no es valido').required('Es necesario ingresar un municipio').test('is-number', 'El municipio de nacimiento no es valido', value => {
      if (!value) return true; 
      return !isNaN(value); 
    }),
    idMunicipioCorporacion: Yup.number().typeError('La nacionalidad no es valido').integer('El municipio de corporación no es valido').required('Es necesario ingresar un municipio').test('is-number', 'El municipio de corporación no es valido', value => {
      if (!value) return true; 
      return !isNaN(value); 
    }),
    idNacionalidad: Yup.number().typeError('La nacionalidad no es valida').integer('La nacionalidad no es valida').required('Es necesario ingresar una nacionalidad').test('is-number', 'La nacionalidad no es valida', value => {
      if (!value) return true; 
      return !isNaN(value); 
    }),
    curp: Yup.string()
    .required('La CURP es obligatoria')
    .matches(
      /^[A-Z]{4}[0-9]{6}[H,M][A-Z]{5}[A-Z0-9]{2}$/,
      'La CURP no es válida'
    ),
    
  });
  const setMunicipiosNacimiento = (estado: any)=>{
    setEstadoNacimiento(estado);
    setMunicipioNacimiento(false);
  }
  const setMunicipiosActual = (estado: any)=>{
    setEstadoActual(estado);
    setMunicipioActual(false);
  }
  //Para mandar llamar el listado de estados
  useEffect(() => {
    const fetchEstados = async () => {
      setIsLoading(true);
      setError('');
      try {
        const  {estados}  = await getEstados();
        setEstados(estados);
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message); 
        } else {
          setError('Ocurrio un error inesperado'); 
        }
        setIsLoading(false);
      }
    };
    const fetchNacionalidades = async () => {
      setIsLoading(true);
      setError('');
      try {
        const  {nacionalidades}  = await getNacionalidades();
        setDataNacionalidad(nacionalidades);
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message); 
        } else {
          setError('Ocurrio un error inesperado'); 
        }
        setIsLoading(false);
      }
    };
    const fetchMunicipiosNacimiento = async () => {
      setIsLoading(true);
      setError('');
      try {
        const  {municipios}  = await getMunicipios(estadoNacimiento);
        setDataMunicipiosNacimiento(municipios);
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message); 
        } else {
          setError('Ocurrio un error inesperado'); 
        }
        setIsLoading(false);
      }
    };
    const fetchMunicipiosActual = async () => {
      setIsLoading(true);
      setError('');
      try {
        const  {municipiosActuales}  = await getMunicipios(estadoActual);
        setDataMunicipiosActual(municipiosActuales);
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message); 
        } else {
          setError('Ocurrio un error inesperado'); 
        }
        setIsLoading(false);
      }
    };

    const fetchMunicipioCorporacion = async () => {
      setIsLoading(true);
      setError('');
      try {
        const  {municipios}  = await getMunicipiosCorporaciones(currentUser!.id);
        setDataMunicipioCorporacion(municipios);
        setIsLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message); 
        } else {
          setError('Ocurrio un error inesperado'); 
        }
        setIsLoading(false);
      }
    };

    fetchEstados();
    fetchNacionalidades();
    fetchMunicipioCorporacion();
    // Solo llamamos a fetchMunicipiosNacimiento si estadoNacimiento tiene un valor
    if (estadoNacimiento) {
      fetchMunicipiosNacimiento();
    }
    if (estadoActual) {
      fetchMunicipiosActual();
    }
    
  },[estadoNacimiento,estadoActual]);


  
 
  const formik = useFormik<ElementoFormValues>({
    initialValues: {
      nombre: '',
      primer_apellido: '',
      segundo_apellido: '',
      domicilio: '',
      colonia: '',
      ciudad: '',
      fecha_nacimiento: new Date(),
    },
    validationSchema: elementoSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
         const nuevoElemento = await createElemento(formData);
        
        Swal.fire({
          title: 'Guardado',
          text: 'Elemento creado exitosamente',
          icon: 'success',
        });
        
        //console.log(data);
         navigate('/activos/index');
      } catch (error) {
        console.error('Error creando un elemento:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error creando un elemento',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
return (
<div className="card">
    <div className="card-body">
    <form onSubmit={formik.handleSubmit}>
    <div className="row">
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="nombre" className="form-label">Nombre:</label>
        <input type="text" className="form-control" id="nombre" {...formik.getFieldProps('nombre')} />
        {formik.touched.nombre && formik.errors.nombre && <div className="text-danger">{formik.errors.nombre}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="primer_apellido" className="form-label">Primer apellido:</label>
        <input type="text" className="form-control" id="primer_apellido" {...formik.getFieldProps('primer_apellido')} />
        {formik.touched.primer_apellido && formik.errors.primer_apellido && <div className="text-danger">{formik.errors.primer_apellido}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="segundo_apellido" className="form-label">Segundo apellido:</label>
        <input type="text" className="form-control" id="segundo_apellido" {...formik.getFieldProps('segundo_apellido')} />
        {formik.touched.segundo_apellido && formik.errors.segundo_apellido && <div className="text-danger">{formik.errors.segundo_apellido}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="fecha_nacimiento" className="form-label">Fecha de nacimiento:</label>
        <input type="date" className="form-control" id="fecha_nacimiento" {...formik.getFieldProps('fecha_nacimiento')} />
        {formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento && <div className="text-danger">{formik.errors.fecha_nacimiento}</div>}
      </div>
      <div className="mb-3 col-md-4 mt-3">
        <label htmlFor="telefono" className="form-label">Teléfono:</label>
        <input type="number" className="form-control" id="telefono" {...formik.getFieldProps('telefono')} />
        {formik.touched.telefono && formik.errors.telefono && <div className="text-danger">{formik.errors.telefono}</div>}
      </div>
      <div className="mb-3 col-md-4 mt-3">
        <label htmlFor="celular" className="form-label">Celular:</label>
        <input type="number" className="form-control" id="celular" {...formik.getFieldProps('celular')} />
        {formik.touched.celular && formik.errors.celular && <div className="text-danger">{formik.errors.celular}</div>}
      </div>
      <div className="mb-3 col-md-4 mt-3">
        <label htmlFor="email" className="form-label">Email:</label>
        <input type="email" className="form-control" id="email" {...formik.getFieldProps('email')} />
        {formik.touched.email && formik.errors.email && <div className="text-danger">{formik.errors.email}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="estatura" className="form-label">Estatura:</label>
        <input type="number" className="form-control" id="estatura" {...formik.getFieldProps('estatura')} />
        {formik.touched.estatura && formik.errors.estatura && <div className="text-danger">{formik.errors.estatura}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="peso" className="form-label">Peso:</label>
        <input type="number" className="form-control" id="peso" {...formik.getFieldProps('peso')} />
        {formik.touched.peso && formik.errors.peso && <div className="text-danger">{formik.errors.peso}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="sexo" className="form-label">Sexo:</label>
        <select className="form-control" id="sexo" {...formik.getFieldProps('sexo')}>
          <option value="">Seleccione una opción</option>
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
        </select>
        {formik.touched.sexo && formik.errors.sexo && <div className="text-danger">{formik.errors.sexo}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="estado_civil" className="form-label">Estado civil:</label>
        <select className="form-control" id="estado_civil" {...formik.getFieldProps('estado_civil')}>
        <option value="">Seleccione una opción</option>
          <option value="Soltero/a">Soltero/a</option>
          <option value="Casado/a">Casado/a</option>
          <option value="Divorciado/a">Divorciado/a</option>
          <option value="Separado/a en proceso judicial">Separado/a en proceso judicial</option>
          <option value="Viudo/a">Viudo/a</option>
          <option value="Concubinato">Concubinato</option>

        </select>
        {formik.touched.estado_civil && formik.errors.estado_civil && <div className="text-danger">{formik.errors.estado_civil}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="ciudad" className="form-label">Ciudad:</label>
        <input type="text" className="form-control" id="ciudad" {...formik.getFieldProps('ciudad')} />
        {formik.touched.ciudad && formik.errors.ciudad && <div className="text-danger">{formik.errors.ciudad}</div>}
      </div>
      
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="codigo_postal" className="form-label">Código postal:</label>
        <input type="number" className="form-control" id="codigo_postal" {...formik.getFieldProps('codigo_postal')} />
        {formik.touched.codigo_postal && formik.errors.codigo_postal && <div className="text-danger">{formik.errors.codigo_postal}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="domicilio" className="form-label">Domicilio:</label>
        <textarea className="form-control" id="domicilio" {...formik.getFieldProps('domicilio')}></textarea>
        {formik.touched.domicilio && formik.errors.domicilio && <div className="text-danger">{formik.errors.domicilio}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="colonia" className="form-label">Colonia:</label>
        <input type="text" className="form-control" id="colonia" {...formik.getFieldProps('colonia')} />
        {formik.touched.colonia && formik.errors.colonia && <div className="text-danger">{formik.errors.colonia}</div>}
      </div>
      <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="idEstadoNacimiento" className="form-label">Estado de Nacimiento</label>
                  <select  className="form-control" id="idEstadoNacimiento" {...formik.getFieldProps('idEstadoNacimiento')} onChange={(e) => {
                    formik.handleChange(e); 
                    setMunicipiosNacimiento(e.target.value); // Aquí pasas el valor seleccionado al estado o función que desees
                  }}>
                <option value="">Por favor seleccione una opción</option>
                {dataEstados.map((estado) => (
                    <option value={estado.id}>{estado.nombre}</option>
                ))}

                </select>
                {formik.touched.idEstadoNacimiento && formik.errors.idEstadoNacimiento && <div className="text-danger">{formik.errors.idEstadoNacimiento}</div>}
        </div>
        <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="idMunicipioNacimiento" className="form-label">Municipio de Nacimiento</label>
                <select disabled={loadMunicipioNacimiento} className="form-control" id="idMunicipioNacimiento" {...formik.getFieldProps('idMunicipioNacimiento')}>
                <option value="">Por favor seleccione un estado</option>
                {dataMunicipiosNacimiento.map((municipio) => (
                    <option value={municipio.id}>{municipio.nombre}</option>
                ))}

                </select>
                {formik.touched.idMunicipioNacimiento && formik.errors.idMunicipioNacimiento && <div className="text-danger">{formik.errors.idMunicipioNacimiento}</div>}
        </div>

        <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="idEstado" className="form-label">Estado en que reside</label>
                  <select  className="form-control" id="idEstado" {...formik.getFieldProps('idEstado')} onChange={(e) => {
                    formik.handleChange(e); 
                    setMunicipiosActual(e.target.value); // Aquí pasas el valor seleccionado al estado o función que desees
                  }}>
                <option value="">Por favor seleccione una opción</option>
                {dataEstados.map((estado) => (
                    <option value={estado.id}>{estado.nombre}</option>
                ))}

                </select>
                {formik.touched.idEstado && formik.errors.idEstado && <div className="text-danger">{formik.errors.idEstado}</div>}
        </div>

        <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="idMunicipio" className="form-label">Municipio en que reside</label>
                <select disabled={loadMunicipioActual} className="form-control" id="idMunicipio" {...formik.getFieldProps('idMunicipio')}>
                <option value="">Por favor seleccione un estado</option>
                {dataMunicipiosActual.map((municipioActual) => (
                    <option value={municipioActual.id}>{municipioActual.nombre}</option>
                ))}

                </select>
                {formik.touched.idMunicipio && formik.errors.idMunicipio && <div className="text-danger">{formik.errors.idMunicipio}</div>}
        </div>
        <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="idNacionalidad" className="form-label">Nacionalidad</label>
                <select  className="form-control" id="idNacionalidad" {...formik.getFieldProps('idNacionalidad')}>
                <option value="">Por favor seleccione una opción</option>
                {dataNacionalidades.map((nacionalidad) => (
                    <option value={nacionalidad.id}>{nacionalidad.pais}</option>
                ))}

                </select>
                {formik.touched.idNacionalidad && formik.errors.idNacionalidad && <div className="text-danger">{formik.errors.idNacionalidad}</div>}
        </div>
        <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="pais_nacimiento" className="form-label">Pais de Nacimiento</label>
                <select  className="form-control" id="pais_nacimiento" {...formik.getFieldProps('pais_nacimiento')}>
                <option value="">Por favor seleccione una opción</option>
                {dataNacionalidades.map((nacionalidad) => (
                    <option value={nacionalidad.pais}>{nacionalidad.pais}</option>
                ))}

                </select>
                {formik.touched.pais_nacimiento && formik.errors.pais_nacimiento && <div className="text-danger">{formik.errors.pais_nacimiento}</div>}
        </div>

        <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="idMunicipioCorporacion" className="form-label">Corporación</label>
                <select  className="form-control" id="idMunicipioCorporacion" {...formik.getFieldProps('idMunicipioCorporacion')}>
                <option value="">Por favor seleccione la corporación</option>
                {dataMunicipioCorporacion.map((municipioCorporacion) => (
                    <option value={municipioCorporacion.id}>{municipioCorporacion.nombre}</option>
                ))}

                </select>
                {formik.touched.idMunicipioCorporacion && formik.errors.idMunicipioCorporacion && <div className="text-danger">{formik.errors.idMunicipioCorporacion}</div>}
        </div>
        <div className="mb-3 col-md-6 mt-3">
        <label htmlFor="curp" className="form-label">CURP:</label>
        <input type="text" className="form-control" id="curp" {...formik.getFieldProps('curp')} />
        {formik.touched.curp && formik.errors.curp && <div className="text-danger">{formik.errors.curp}</div>}
      </div>

       
                
    </div>
    <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'CREATING' }) : intl.formatMessage({ id: 'CREATE' })}
    </button>

    </form>
    </div>
</div>
);
}
export {Create}
