
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { EstimuloModel, EstimuloFormValues } from './model';
import * as Yup from 'yup';
import { useAuth } from '../auth';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import Swal from 'sweetalert2';
import { updateEstimulo } from './api';
import { Curso, Instancia, MotivoEstimulo } from '../catalogos/model';
import { getCursos } from '../catalogos/apiCursos';
import { getInstancias } from '../catalogos/apiInstancias';
import { getMotivoEstimulos } from '../catalogos/apiEstimulos';

interface EditEstimuloModalProps {
    show: boolean;
    handleClose: () => void;
    estimulo:EstimuloModel | null;
    modificarEstimulo : any;
    solicitud:any;
}

const EditEstimulo: React.FC<EditEstimuloModalProps> = ({ show, handleClose,estimulo,modificarEstimulo,solicitud }) => {  
    const {currentUser} = useAuth()
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    //Para los select de catalogos
    const [dataCursos, setDataCursos] = useState<Curso[]>([]);
    const [dataInstancia, setDataInstancia] = useState<Instancia[]>([]);
    const [error, setError] = useState('');
    const [dataEstimulos, setDataEstimulos] = useState<MotivoEstimulo[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    

    const estimuloSchema = Yup.object().shape({
        estimulo: Yup.string().max(250,'No se permiten mas de 250 caracteres').required('Es necesario ingresar el estimulo'),
    id_motivo_estimulo: Yup.number().typeError('El motivo de estimulo no es valido').integer('El motivo de estimulo no es valido').required('Es necesario ingresar un motivo de estimulo').test('is-number', 'El motivo de estimulo no es valido', value => {
        if (!value) return true; 
        return !isNaN(value); 
      }),
    fecha: Yup.date().required('Es necesario ingresar la fecha'),
  });
  
  //console.log(curso);

  let formik = useFormik<EstimuloFormValues>({
    initialValues: {
        estimulo: '',
      },
    validationSchema: estimuloSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        const formValues = values as FormikValues;

        // Agregar los valores del formulario al objeto FormData
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value as string); // Asegurar que el valor sea de tipo string
        });
        formData.append('id_solicitud', solicitud);

        const nuevoEstimulo = await updateEstimulo(estimulo?.id || 1,formData);
        console.log(nuevoEstimulo);
        Swal.fire({
          title: 'Actualizado',
          text: 'Estimulo actualizado exitosamente',
          icon: 'success',
        });
        
        //Lo agregamos a la lista de estimulos
        modificarEstimulo(nuevoEstimulo);
        //Cerramos el modal
        handleClose();
        navigate('/estimulos/'+ solicitud);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error actualizando una Estimulo',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });
  //Mandar los valores actuales
  useEffect(() => {
    const fetchMotivoEstimulos = async () => {
        setIsLoading(true);
        setError('');
        try {
          const  {motivoEstimulos}  = await getMotivoEstimulos();
          setDataEstimulos(motivoEstimulos);
          setIsLoading(false);
        } catch (error) {
          if (error instanceof Error) {
            setError(error.message); 
          } else {
            setError('Ocurrio un error inesperado'); 
          }
          setIsLoading(false);
        }
      };
  
      fetchMotivoEstimulos();
  },[]);

  useEffect(() => {
    if (estimulo) {
        // Actualizar los valores del formulario cuando cambie la Estimulo
        formik.setValues({
            estimulo: estimulo.estimulo,
            id_motivo_estimulo: estimulo.id_motivo_estimulo,
            fecha: estimulo.fecha,
        });
    }
}, [estimulo]); 

  
    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='bg-primary'>
                <Modal.Title className='text-white'>Actualizar Estimulo</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
            <div className="row">
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="id_motivo_estimulo" className="form-label">Motivo de Estimulo</label>
                <select className="form-control" id="id_motivo_estimulo" {...formik.getFieldProps('id_motivo_estimulo')}>
                <option value="">Por favor seleccione una opción</option>
                {dataEstimulos.map((estimulo) => (
                    <option value={estimulo.id}>{estimulo.nombre}</option>
                ))}

                </select>
                {formik.touched.id_motivo_estimulo && formik.errors.id_motivo_estimulo && <div className="text-danger">{formik.errors.id_motivo_estimulo}</div>}
                </div>
            
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="estimulo" className="form-label">Estimulo</label>
                <input type="text" className="form-control" id="estimulo" {...formik.getFieldProps('estimulo')} />
                {formik.touched.estimulo && formik.errors.estimulo && <div className="text-danger">{formik.errors.estimulo}</div>}
                </div>
                
            <div className="mb-3 col-md-6 mt-3">
                <label htmlFor="fecha" className="form-label">Fecha</label>
                <input type="date" className="form-control" id="fecha" {...formik.getFieldProps('fecha')} />
                {formik.touched.fecha && formik.errors.fecha && <div className="text-danger">{formik.errors.fecha}</div>}
            </div>
               
        
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <button type="submit" className="btn btn-success ml-3" disabled={loading}>
                  {loading ? intl.formatMessage({ id: 'UPDATING' }) : intl.formatMessage({ id: 'UPDATE' })}
                </button>


            </Modal.Footer>
            </form>


        </Modal>
    );
};

export default EditEstimulo;