import React from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFileAlt, faCheckCircle, faCertificate, faShieldAlt, faGraduationCap, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

const Profile = () => {
  return (
    <Container fluid className="p-4">
      <Row className="mb-4">
        <Col>
          <Card className="profile-card mb-3">
            <Card.Body>
              <Row>
                <Col md={2} className="text-center">
                  <FontAwesomeIcon icon={faUser} size="4x" className="icon-style" />
                </Col>
                <Col md={10}>
                  <h3>Luis Perez Perez</h3>
                  <p><strong>CUIP:</strong> 197AKE46763176</p>
                  <p><strong>CUP:</strong> 45697274625765</p>
                  <p><strong>Número de Empleado:</strong> 13509547</p>
                  <p><strong>Cargo/Puesto:</strong> Puesto de Luis Perez</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* Secciones del expediente */}

          <Card className="profile-card mb-3 p-10">
            <Row className='mt-12'>
              <Col md={4} className="mb-3">
                <Card className="child-card">
                  <Card.Body className="text-center">
                    <FontAwesomeIcon icon={faFileAlt} className="icon-style" />
                    <Card.Title>RECLUTAMIENTO</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-3">
                <Card className="child-card">
                  <Card.Body className="text-center">
                    <FontAwesomeIcon icon={faCheckCircle} className="icon-style" />
                    <Card.Title>SELECCIÓN</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="child-card">
                  <Card.Body className="text-center">
                    <FontAwesomeIcon icon={faCertificate} className="icon-style" />
                    <Card.Title>NOMBRAMIENTO Y CERTIFICACIÓN</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4} className="mb-3">
                <Card className="child-card">
                  <Card.Body className="text-center">
                    <FontAwesomeIcon icon={faShieldAlt} className="icon-style" />
                    <Card.Title>EVALUACIONES DEL CERTIFICADO ÚNICO POLICIAL CUP</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              {/* Repite para más secciones según sea necesario */}
            </Row>

            <Row className='mt-10'>
              <Col>
                <Table striped bordered hover className="evaluaciones-table">
                  <thead>
                    <tr>
                      <th>Formación Inicial</th>
                      <th>Evaluación de Control de Confianza</th>
                      <th>Evaluación del Desempeño</th>
                      <th>Evaluación de Competencias Básicas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><FontAwesomeIcon icon={faGraduationCap} /> Información</td>
                      <td><FontAwesomeIcon icon={faClipboardCheck} /> Información</td>
                      <td><FontAwesomeIcon icon={faClipboardCheck} /> Información</td>
                      <td><FontAwesomeIcon icon={faClipboardCheck} /> Información</td>
                    </tr>
                    {/* Añade más filas según sea necesario */}
                  </tbody>
                </Table>
              </Col>
            </Row>

          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
