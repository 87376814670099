import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaUserEdit  } from "react-icons/fa";
import { FaHandshake } from "react-icons/fa";
import { MdOutlineWorkHistory } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { GiWhiteBook } from "react-icons/gi";
import { FaPencil } from "react-icons/fa6";

import Swal from 'sweetalert2';
import { ISolicitud } from '../solicitudes/model';

interface SolicitudesListBodyProps {
  data: ISolicitud[];
  isLoading: boolean;
  handleDetail: (solicitudId: number) => void; // Agrega handleDetail como prop
  onRemoveSolicitud: (solicitudId: number) => void; // Nueva prop
}
interface StatusMessages {
  [key: number]: string;
}

  const SolicitudesListBody: React.FC<SolicitudesListBodyProps> = ({ data, isLoading, handleDetail, onRemoveSolicitud}) => {

  return (
    <>

      <tbody>
        {isLoading ? ( // Mostrar mensaje de carga mientras se realiza la búsqueda
          <tr>
            <td colSpan={5}>Cargando...</td>
          </tr>
        ) : (
          data.map((solicitud) => (
            <tr key={solicitud.id}>
              
              <td>{solicitud.nombre} {solicitud.primer_apellido} {solicitud.segundo_apellido}</td>
              <td>{solicitud?.curp}</td>
              <td>{solicitud?.municipio_corporacion?.nombre}</td>
              <td>{solicitud?.funcionSNSP}</td>
              <td>{solicitud?.nombramientos_certificaciones?.comision}</td>
              <td>{solicitud?.nombramientos_certificaciones?.cargo}</td>
              <td>{solicitud?.cat_tabular}</td>
              <td>{solicitud?.cat_CNCA}</td>
              <td>{solicitud?.puesto_CNCA}</td>
              <td>{solicitud?.jer_puesto}</td>
              <td>{solicitud?.documentos?.rfc}</td>
              <td>{solicitud?.nombramientos_certificaciones?.cuip}</td>
              <td>{solicitud?.nombramientos_certificaciones?.no_empleado}</td>
              <td>{solicitud?.sueldo_mensual}</td>
              <td>{solicitud?.nivel_estudio?.nombre}</td>

              <td>
                <ButtonGroup aria-label="Actions">
                  <Button variant="info" onClick={() => handleDetail(solicitud.id)}> {/* Agrega onClick para llamar a handleDetail */}
                    <FaUserEdit   /> 
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </>
  );
};

export default SolicitudesListBody;
