import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import ModelList from './SolicitudesList'

const crudBreadCrumbs: Array<PageLink> = [
    {
        title: 'Vencidos Competencias Basicas',
        path: '/vencidos-competencias-basicas',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const VencidosCompetenciasBasicasPage = () => (
    <Routes>
        <Route
            path='index'
            element={
                <>
                    <PageTitle breadcrumbs={crudBreadCrumbs}>Vencidos Competencias Basicas</PageTitle>
                    <ModelList />
                </>
            }
        />

        <Route index element={<Navigate to='/vencidos-competencias-basicas/index' />} />

    </Routes>
)

export default VencidosCompetenciasBasicasPage