import * as Yup from 'yup'

//Ejemplo temporal para que no marque error
export interface Reclutamiento {
    id?: number;
    fecha_investigacion?: Date;
    sintesis?: String;
    informacion_plataforma?: String;
    informacion_base_datos?: String;
    informacion_fuentes_abiertas?: String;
    resultado?: 'Recomendable' | 'Recomendable con reservas' | 'No recomendable';
    fecha_emision_resultado?: Date;
    fecha_valoracion?: Date;
    fecha_examen?: Date;
    pruebas_psicologicas?: String;
    sustancias?: String;
    created_at?: Date ;
    updated_at?: Date;
    deleted_at?: Date | null;
}

export interface InvestigacionAntecedente {
  fecha_investigacion?: Date;
  sintesis?: String;
  id?: number;
  id_solicitud?: number;
  informacion_plataforma?: String;
  informacion_base_datos?: String;
  informacion_fuentes_abiertas?: String;
  resultado?: 'Recomendable' | 'Recomendable con reservas' | 'No recomendable';
  fecha_emision_resultado?: Date;
  created_at?: Date ;
  updated_at?: Date;
  deleted_at?: Date | null;
}


export interface ValoracionPsicologica {
  fecha_valoracion?: Date;
  sintesis?: String;
  id?: number;
  id_solicitud?: number;
  pruebas_psicologicas?: String;
  resultado?: 'Recomendable' | 'Recomendable con reservas' | 'No recomendable';
  fecha_emision_resultado?: Date;
  created_at?: Date ;
  updated_at?: Date;
  deleted_at?: Date | null;
}
export interface AnalisisHabitos {
  fecha_valoracion?: Date;
  sintesis?: String;
  id?: number;
  id_solicitud?: number;
  resultado?: 'Recomendable' | 'Recomendable con reservas' | 'No recomendable';
  fecha_emision_resultado?: Date;
  created_at?: Date ;
  updated_at?: Date;
  deleted_at?: Date | null;
}

export interface ExamenToxicologico {
  fecha_examen?: Date;
  sintesis?: String;
  sustancias?: String;
  id?: number;
  id_solicitud?: number;
  resultado?: 'Recomendable' | 'Recomendable con reservas' | 'No recomendable';
  fecha_emision_resultado?: Date;
  created_at?: Date ;
  updated_at?: Date;
  deleted_at?: Date | null;
}


export const reclutamientoInitial: Reclutamiento = {
    
  }
const createReclutamientoSchemas = [
    //Validaciones por cada paso
    Yup.object({
      fecha_investigacion: Yup.date().required('Por favor ingrese la fecha de investigación'),
      sintesis: Yup.string().max(255).required('Por favor ingrese la sintesis'),
      informacion_plataforma: Yup.string().max(255).required('Por favor ingrese la información de plataforma México'),
      informacion_base_datos: Yup.string().max(255).required('Por favor ingrese la información de base de datos'),
      informacion_fuentes_abiertas: Yup.string().max(255).required('Por favor ingrese la información de fuentes abiertas'),
      resultado: Yup.string().max(30).required('Por favor ingrese la información de resultado'),
      fecha_emision_resultado: Yup.date().required('Por favor ingrese la fecha de investigación'),

    }),
    Yup.object({
        fecha_valoracion: Yup.date().required('Por favor ingrese la fecha de valoración'),
        sintesis: Yup.string().max(255).required('Por favor ingrese la sintesis'),
        pruebas_psicologicas: Yup.string().max(255).required('Por favor ingrese las pruebas psicologicas aplicadas'),
        resultado: Yup.string().max(30).required('Por favor ingrese la información de resultado'),
        fecha_emision_resultado: Yup.date().required('Por favor ingrese la fecha de investigación'),
    }),
    Yup.object({
        fecha_valoracion: Yup.date().required('Por favor ingrese la fecha de valoración'),
        sintesis: Yup.string().max(255).required('Por favor ingrese la sintesis'),
        resultado: Yup.string().max(30).required('Por favor ingrese la información de resultado'),
        fecha_emision_resultado: Yup.date().required('Por favor ingrese la fecha de investigación'),
       
    }),
    Yup.object({
        fecha_examen: Yup.date().required('Por favor ingrese la fecha de examen'),
        sustancias: Yup.string().max(255).required('Por favor ingrese la sustancias'),
        sintesis: Yup.string().max(255).required('Por favor ingrese la sintesis'),
        resultado: Yup.string().max(30).required('Por favor ingrese la información de resultado'),
        fecha_emision_resultado: Yup.date().required('Por favor ingrese la fecha de investigación'),
    }),
    Yup.object({
      
    })
    
  ]
export {createReclutamientoSchemas}