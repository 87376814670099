import React from 'react';
import { Form, Button, Card, Container } from 'react-bootstrap';

const EvaluationForm = () => {
  return (
    <Container >
      <Card>
        <Card.Body>
          <Card.Title>Evaluación al Desempeño</Card.Title>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Tipo de Evaluación *</Form.Label>
              <Form.Select>
                <option>Seleccione...</option>
                {/* Añade más opciones aquí */}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Fecha de Evaluación *</Form.Label>
              <Form.Control type="date" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Instancia Evaluadora *</Form.Label>
              <Form.Select>
                <option>Seleccione...</option>
                {/* Añade más opciones aquí */}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Otra Instancia Evaluadora</Form.Label>
              <Form.Select>
                <option>Seleccione...</option>
                {/* Añade más opciones aquí */}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Resultado *</Form.Label>
              <Form.Select>
                <option>Seleccione...</option>
                {/* Añade más opciones aquí */}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Calificación Obtenida (con decimales) *</Form.Label>
              <Form.Control type="text" placeholder="Ingrese la calificación" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Vigencia *</Form.Label>
              <Form.Control type="date" />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Observaciones *</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>

            <Button variant="primary" type="submit">
              Guardar
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EvaluationForm;
