import axios, { AxiosResponse } from "axios";
import { IAnnouncement, IAnnouncementFormValues } from "./model";

export const fetchAnnouncements = async (
  currentPage: number,
  limit: number,
  searchValue: string,
  searchStatus: string
): Promise<{ announcements: IAnnouncement[], totalPages: number }> => { // Notice the return type change
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/announcements?page=${currentPage}&per_page=${limit}&name=${searchValue}&status=${searchStatus}`;

    const response = await axios.get(queryString);

    // Assuming your API response format is the same as the sample JSON you provided:
    const announcements = response.data.data; // This is the list of announcements
    const totalPages = response.data.last_page; // This is the total number of pages

    return { announcements, totalPages }; // Return both the data and the page count
  } catch (error) {
    throw error;
  }
};
export const createAnnouncement = async (formData: FormData): Promise<IAnnouncement> => {
  try {


    console.log("crear", formData)
    const response: AxiosResponse<{ message: string; announcement: IAnnouncement }> = await axios.post(
      `${process.env.REACT_APP_API_DEV_URL}/announcements`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Especifica el tipo de contenido para el envío de archivos
        },
      }
    );
    if (response.status !== 201) {
      throw new Error('Error creating announcement');
    }
    return response.data.announcement;
  } catch (error) {
    throw error;
  }
};


export const deleteAnnouncement = async (announcementId: number) => {
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/announcements/${announcementId}`;
    const response = await axios.delete(queryString);
    return response.data; // Puedes ajustar esto según lo que necesites manejar en tu componente
  } catch (error) {
    throw error;
  }
};

export const getAnnouncement = async (announcementId: number): Promise<IAnnouncement> => {
  try {
    const queryString = `${process.env.REACT_APP_API_DEV_URL}/announcements/${announcementId}`;
    const response: AxiosResponse<IAnnouncement> = await axios.get<IAnnouncement>(queryString);

    if (response.status !== 200) {
      throw new Error('Error fetching announcement');
    }

    return response.data; // Devolver solo los datos de la respuesta
  } catch (error) {
    throw error;
  }
};

export const editAnnouncement = async (
  announcementId: number,
  data: IAnnouncementFormValues
): Promise<void> => {
  try {

    const formData = new FormData();
    formData.append("_method", "PUT");

    // Añadir otros datos al FormData
    Object.keys(data).forEach(key => {
      const value = data[key as keyof IAnnouncementFormValues];
      if (key !== 'image_url' && value != null) {
        formData.append(key, value.toString()); // Asegúrate de que el valor se convierta a string si es necesario
     
      }
    

    });
    if (data.image_url instanceof File ) {
      formData.append('image_url', data.image_url);
    }

    const queryString = `${process.env.REACT_APP_API_DEV_URL}/announcements/${announcementId}`;
  

    const response: AxiosResponse<void> = await axios.post(queryString, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });

    if (response.status !== 200) {
      throw new Error('Error editing announcement');
    }
  } catch (error) {
    throw error;
  }
};